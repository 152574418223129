import { useEffect, useMemo, useState } from 'react'
import CommonTable from '../../../../../components/patterns/CommonTable'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_INVESTOR_PORTFOLIO_ORGANIZATIONS } from '../../../../../queries/useInvestorPortfolioOrganizations'
import LogoNameUrlSection from '../../../../../components/patterns/LogoNameUrlSection'
import { formatCountryCode } from '../../../../../utils/format/formatCountryCode'
import moment from 'moment'
import { Div } from '../../../../../components/primitives/Div'

export default function Companies({ investorUuid }) {

  const [tableData, setTableData] = useState([])

  const { data: first25Data, loading: first25Loading, error: first25Error } = useQuery(GET_INVESTOR_PORTFOLIO_ORGANIZATIONS, {
    variables: { investorUuid, page: 1, pageSize: 25 }
  })
  // console.log(first25Data?.investorPortfolioOrganizations)

  const [getAllData, {data: allData, loading: allLoading, error: allError}] = useLazyQuery(GET_INVESTOR_PORTFOLIO_ORGANIZATIONS, {
    variables: { investorUuid, page: 1, pageSize: 9999 }
  })
  // console.log(allData?.investorPortfolioOrganizations)

  useEffect(() => {
    if (first25Data?.investorPortfolioOrganizations.objects) {
      setTableData(first25Data?.investorPortfolioOrganizations.objects.map((obj) => {
        return dataWithAccessor(obj)
      }))
      if (first25Data?.investorPortfolioOrganizations.totalCount > 25) getAllData()
    }
    if (allData?.investorPortfolioOrganizations.objects) {
      setTableData(allData?.investorPortfolioOrganizations.objects.map((obj) => {
        return dataWithAccessor(obj)
      }))
    }
  }, [first25Data, allData, getAllData])

  const dataWithAccessor = (obj) => ({
    name: obj.name,
    location: `${obj.city} ${formatCountryCode(obj.countryCode)}`,
    locationCity: obj.city,
    locationCountry: obj.countryCode,
    year: obj.foundedOn,
    team: obj.employeeCount,
    categories: obj.categoryList,
    shortDescription: obj.shortDescription,
    obj: obj,
  })

  const columns = useMemo(() => [
    {
      Header: 'Company', accessor: 'name', Cell: ({ row }) => {
        return <Div css={{w: '160px'}}><LogoNameUrlSection investor={row.original.obj} isPortfolioOrg={true} /></Div>
      }
    },
    {
      Header: 'Location', accessor: 'location', Cell: ({ row }) => {
        return <>{row.original.locationCity}<br />{formatCountryCode(row.original.locationCountry)}</>
      }
    },
    {
      Header: 'Year', accessor: 'year', Cell: ({ row }) => {
        return <>{row.original.year === '' ? '' : moment(row.original.year).format('YYYY')}</>
      }
    },
    { Header: 'Team', accessor: 'team' },
    { Header: 'Categories', accessor: 'categories' },
    { Header: 'Short\u00A0description', accessor: 'shortDescription' },
  ], [])

  return (
    <CommonTable
      columns={columns}
      data={tableData}
      loading={allLoading}
      error={first25Data?.investorPortfolioOrganizations.hasNext ? allError : first25Error}
      columnsWithNoSort={['Categories', 'Short\u00A0description']}
    />
  )
}
