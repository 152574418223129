import React, { useRef } from 'react'
import { ButtonsWrapper, SecondaryButton, PrimaryButton } from '../components/primitives/Button'
import { Header1 } from '../components/primitives/Text'
import { DEACTIVATE_USER } from '../mutations/deactivateUser'
import { GET_INVITATIONS } from '../queries/useInvitations'
import { useMutation } from '@apollo/client'
import { Div } from '../components/primitives/Div'

export default function CancelInvitation({ close, userId, setUserFeedback }) {

  const deactivateButtonRef = useRef(null)

  const [deactivateUser] = useMutation(DEACTIVATE_USER, {
    variables: {
      userId
    },
    refetchQueries: [
      { query: GET_INVITATIONS }
    ],
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      close()
      // console.log(data)
      setUserFeedback({ success: true, text: 'User deactivated' })
    }
  })

  return (
    <Div
      onKeyPress={(event) => {
        const { key } = event;
        if (key === "Enter") {
          deactivateButtonRef.current.click();
        }
      }}
    >
      <Header1 css={{ my: '$20', fontSize: '$20' }}>Do you want to deactivate this user?</Header1>
      <ButtonsWrapper css={{ mt: '$24' }}>
        <SecondaryButton onClick={() => close()}>No</SecondaryButton>
        <PrimaryButton
          type='button'
          onClick={() => deactivateUser()}
          ref={deactivateButtonRef}
        >
          Yes
        </PrimaryButton>
      </ButtonsWrapper>
    </Div>
  )
}
