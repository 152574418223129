import { styled } from '../../styles'

export const Table = styled('table', {
  width: '100%',
})

export const TableData = styled('td', {
  padding: '8px 12px',
  fontSize: '12px',
  color: '$grey500',
  verticalAlign: 'middle',
})

export const TableHeader = styled('th', {
  padding: '8px 12px 10px',
  fontSize: '13px',
  color: '#9d9d9d',
})

export const TableRow = styled('tr', {
  borderBottom: '1px solid $grey100',
  minHeight: 'fit-content',
  zIndex: '999'
})

