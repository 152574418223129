import { useRef } from 'react'
import { ButtonsWrapper, SecondaryButton, PrimaryButton } from '../components/primitives/Button'
import { RESEND_INVITATION } from '../mutations/resendInvitation'
import { Header2 } from '../components/primitives/Text'
import { useMutation } from '@apollo/client'
import { Div } from '../components/primitives/Div'

export default function ResendInvitation({ close, invitationUuid, setUserFeedback }) {

  // console.log(invitationUuid)
  const resendButtonRef = useRef(null)

  const [resendInvitation] = useMutation(RESEND_INVITATION, {
    variables: {
      uuid: invitationUuid
    },
    onError: (error) => {
      // console.log(error)
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      close()
      // console.log(data)
      setUserFeedback({ success: true, text: 'Invitation resent' })
    }
  })

  return (
    <Div
      onKeyPress={(event) => {
        const { key } = event;
        if (key === "Enter") {
          resendButtonRef.current.click();
        }
      }}
    >
      <Header2 css={{ my: '$20'}}>Do you want to resend the invitation?</Header2>
      <ButtonsWrapper css={{ mt: '$24' }}>
        <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
        <PrimaryButton
          type='button'
          onClick={() => resendInvitation()}
          ref={resendButtonRef}
        >Resend</PrimaryButton>
      </ButtonsWrapper>
    </Div>
  )
}
