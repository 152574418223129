import { useState, useEffect, useRef } from 'react'
import { Header2, Header4, Paragraph, RequiredText } from '../../../components/primitives/Text'
import { Box, BoxWrapper } from '../../../components/primitives/Box'
import { Flex } from '../../../components/primitives/Flex'
import { FlexSpaceBetween } from '../../../components/primitives/FlexSpaceBetween'
import moment from 'moment'
import { Modal } from '../../../modals'
import { PrimaryButton } from '../../../components/primitives/Button'
import { useMutation } from '@apollo/client'
import { ADD_COMMENT } from '../../../mutations/addComment'
import { GET_INVESTOR } from '../../../queries/useInvestor'
import DeleteComment from '../../../modals/DeleteComment'
import { MentionsInput, Mention } from 'react-mentions'
import { useUsers } from '../../../queries/useUsers'
import reactStringReplace from 'react-string-replace'
import { Span } from '../../../components/primitives/Span'
import { MentionsTextarea } from '../../../components/patterns/MentionsTextarea'

export default function Comments({ investor, investorUuid, setUserFeedback }) {

  const [commentFieldIsEmpty, setCommentFieldIsEmpty] = useState(false)
  const [commentText, setCommentText] = useState('')
  const [listOfNamesForMention, setListOfNamesForMention] = useState([])
  // console.log({ listOfNamesForMention })

  const localStorageUsername = localStorage.getItem('username')
  const addNoteButtonRef = useRef(null)

  const { data } = useUsers(true)

  useEffect(() => {
    data?.users.forEach((user) => {
      if (listOfNamesForMention.length !== data.users.length) {
        listOfNamesForMention.push({ id: user.id, display: user.name })
        setListOfNamesForMention(listOfNamesForMention.sort((a, b) => a.display.localeCompare(b.display)))
      }
    })
  })

  const getMentionedUserIds = (commentText) => {
    return Array.from([...commentText.matchAll(/@\[(.*?)]\((\d*?)\)/g)], m => m[2]).join(',')
  }

  const [addComment] = useMutation(ADD_COMMENT, {
    variables: {
      commentText,
      investorUuid,
      mentionedUserIds: getMentionedUserIds(commentText),
    },
    refetchQueries: [
      { query: GET_INVESTOR, variables: { uuid: investorUuid } }
    ],
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: () => {
      setCommentText('')
      setUserFeedback({ success: true, text: 'Comment added successfully' })
    }
  })

  const handleCheck = () => {
    if (commentText.trim().length === 0) {
      setCommentFieldIsEmpty(true)
    } else {
      addComment()
    }
  }

  useEffect(() => {
    setCommentFieldIsEmpty(false)
  }, [commentText])


  return (
    <>
      {investor.showComments &&
        <BoxWrapper>
          <Box>
            <div>
              <Header2 css={{ mb: '$12' }}>Comments and Activity History</Header2>
              {investor.comments.map((comment) => {
                return <div key={comment.uuid}>
                  <Flex>
                    <Header4 css={{ my: '$0', color: '$grey400', fontWeight: '$400' }}>{comment.addedBy.firstName}&nbsp;{comment.addedBy.lastName}</Header4>
                    <Header4 css={{ my: '$0', ml: '$12', fontSize: '$11' }}>{moment(comment.createdAt).format('lll')}</Header4>
                    {
                      localStorageUsername === comment.addedBy.username &&
                      <Modal
                        triggerButtonStyle={'DeleteComment'}
                        Component={DeleteComment}
                        investorUuid={investorUuid}
                        commentUuid={comment.uuid}
                        setUserFeedback={setUserFeedback}
                      />
                    }
                  </Flex>
                  <Paragraph css={{ fontWeight: '$400' }}>
                    {reactStringReplace(
                      comment.commentText,
                      /@\[(.*?)]\(\d*?\)/g,
                      (match) => <Span css={{ color: '$primary' }} key={match}>@{match}&nbsp;</Span>
                    )}
                  </Paragraph>

                </div>
              })}

              <FlexSpaceBetween>
                <MentionsTextarea
                  onKeyPress={(event) => {
                    const { key } = event;
                    if (key === "Enter") {
                      addNoteButtonRef.current.click();
                      event.target.blur()
                    }
                  }}
                  isRequired={commentFieldIsEmpty}
                  css={{ mt: '$12', mr: '$12' }}
                  inWhichPage={'investorProfile'}
                >
                  <MentionsInput
                    onChange={(e) => setCommentText(e.target.value)}
                    // autoComplete="off"
                    // className="mentions"
                    // id={name}
                    // maxLength={1500}
                    // name={name}
                    // onChange={onChange}
                    // onFocus={onFocus}
                    // onKeyDown={onKeyDown}
                    placeholder={'Add new comment...'}
                    value={commentText}
                    className='mentions'
                  >
                    <Mention
                      className="mentions__mention"
                      markup="@[__display__](__id__)"
                      trigger="@"
                      data={listOfNamesForMention}
                      displayTransform={(id, display) => `@${display} `}
                    // type="user"
                    />
                  </MentionsInput>
                </MentionsTextarea>
                <PrimaryButton
                  css={{ mt: '$12' }}
                  type='button'
                  onClick={() => handleCheck()}
                  ref={addNoteButtonRef}
                >Add&nbsp;comment</PrimaryButton>
              </FlexSpaceBetween>
              {commentFieldIsEmpty && <RequiredText>This field cannot be empty</RequiredText>}
            </div>
          </Box>
        </BoxWrapper >
      }
    </>
  )
}



