import React from 'react'
import Popup from 'reactjs-popup'
import { styled } from '../styles'
import { PrimaryButton, SecondaryButton, AskForIntroButton, SmallSecondaryButton } from '../components/primitives/Button'
import { FlexSpaceBetween } from '../components/primitives/FlexSpaceBetween';
import { Header2 } from '../components/primitives/Text';
import { Box } from '../components/primitives/Box';
import { ReactComponent as CloseIcon } from '../assets/icons/close.svg'
import { ReactComponent as AddIcon } from '../assets/icons/add.svg'
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg'
import { ReactComponent as StepOutIcon } from '../assets/icons/leave.svg'
import { Flex } from '../components/primitives/Flex';
import { IconInsideButton, SmallIcon } from '../components/primitives/IconWrapper';
import { Span } from '../components/primitives/Span';
import { Div } from '../components/primitives/Div';

export const Modal = ({
  triggerButtonStyle,
  triggerButtonLabel,
  title,
  Component,
  listName,
  listDescription,
  setListDescription,
  listUuid,
  investorName,
  investorUuid,
  selectedInvestors,
  commentUuid,
  contactUuid,
  collaborators,
  allChecked,
  selectedCounts,
  getAllInvestors,
  setAllChecked,
  setChecked,
  allData,
  countryCodes,
  fundingRound,
  hasContact,
  investmentCount,
  investorType,
  lowerBound,
  portfolioCategories,
  portfolioCountryCodes,
  searchbox,
  upperBound,
  resultsTotalCount,
  setListUuid,
  setContactUuid,
  setUserFeedback,
  downloadLink,
  listOrContact,
  rowIndex,
  columnId,
  updatePendingData,
  displayedTab,
  updateDataAfterAssign,
  updateDataAfterDelete,
  updateDataAfterAdd,
  invitationUuid,
  userId,
  personalNoteUuid,
  hasCollaborator,
  investmentUuids,
  requestUuid,
  done,
  deleted,
  tableData,
  noteUuid,
  updateDataAfterDeleteComment,
  commentIndex,
}) => {

  return (
    <StyledPopup
      open={allChecked}
      trigger={
        triggerButtonStyle === 'AskForIntroButton'
          ? <AskForIntroButton>{triggerButtonLabel}</AskForIntroButton>
          : triggerButtonStyle === 'Delete'
            ? <SmallIcon css={{ mr: '0' }}><DeleteIcon data-testid="single-delete" /></SmallIcon>
            : triggerButtonStyle === 'Leave'
              ? <SmallIcon css={{ mr: '0px' }}><StepOutIcon /></SmallIcon>
              : triggerButtonStyle === 'DeleteComment'
                ? <SmallIcon css={{ ml: '$6', color: '$grey300', w: '$12', mt: '-2px' }}><DeleteIcon /></SmallIcon>
                : triggerButtonStyle === 'secondary'
                  ? <SecondaryButton>{triggerButtonLabel}</SecondaryButton>
                  : triggerButtonStyle === 'smallSecondary'
                    ? <SmallSecondaryButton>{triggerButtonLabel}</SmallSecondaryButton>
                    : triggerButtonStyle === 'markAsDone'
                      ? <Span css={{ fontSize: '$9', w: 'fit-content',textDecoration: 'underline', '&:hover': { cursor: 'pointer', color: '$secondary' } }}>{triggerButtonLabel}</Span>
                      //button for "Add search result to a list" and "Add selected investors to a list"
                      : triggerButtonStyle === 'text with add icon'
                        ? <PrimaryButton
                          disabled={
                            selectedInvestors && selectedInvestors.length === 0 ||
                            contactUuid && contactUuid.length === 0 ||
                            listUuid && listUuid.length === 0 ||
                            investorUuid && investorUuid.length === 0
                          }
                        >
                          <Flex css={{ ai: 'center' }}>
                            {triggerButtonLabel}
                            <IconInsideButton>
                              <AddIcon />
                            </IconInsideButton>
                          </Flex>
                        </PrimaryButton>
                        : triggerButtonStyle === 'blue'
                          ? <BlueButton>{triggerButtonLabel}</BlueButton>
                          : <PrimaryButton
                            disabled={
                              selectedInvestors && selectedInvestors.length === 0 ||
                              contactUuid && contactUuid.length === 0 ||
                              listUuid && listUuid.length === 0 ||
                              investorUuid && investorUuid.length === 0
                            }
                          >
                            {triggerButtonLabel}
                          </PrimaryButton>
      }
      modal
      closeOnDocumentClick={false}
    >
      {close => (
        <Box>
          <FlexSpaceBetween>
            <Header2>{title}</Header2>
            <Icon size='20' color='grey200'>
              <CloseIcon onClick={() => close()} data-testid='close-modal-icon' />
            </Icon>
          </FlexSpaceBetween>

          <Component
            close={close}
            listName={listName}
            listDescription={listDescription}
            setListDescription={setListDescription}
            listUuid={listUuid}
            investorName={investorName}
            investorUuid={investorUuid}
            commentUuid={commentUuid}
            selectedInvestors={selectedInvestors}
            contactUuid={contactUuid}
            collaborators={collaborators}
            selectedCounts={selectedCounts}
            getAllInvestors={getAllInvestors}
            setAllChecked={setAllChecked}
            setChecked={setChecked}
            allData={allData}
            countryCodes={countryCodes}
            fundingRound={fundingRound}
            hasContact={hasContact}
            investmentCount={investmentCount}
            investorType={investorType}
            lowerBound={lowerBound}
            portfolioCategories={portfolioCategories}
            portfolioCountryCodes={portfolioCountryCodes}
            searchbox={searchbox}
            upperBound={upperBound}
            resultsTotalCount={resultsTotalCount}
            setListUuid={setListUuid}
            setContactUuid={setContactUuid}
            setUserFeedback={setUserFeedback}
            downloadLink={downloadLink}
            listOrContact={listOrContact}
            rowIndex={rowIndex}
            columnId={columnId}
            updatePendingData={updatePendingData}
            displayedTab={displayedTab}
            updateDataAfterAssign={updateDataAfterAssign}
            updateDataAfterDelete={updateDataAfterDelete}
            updateDataAfterAdd={updateDataAfterAdd}
            invitationUuid={invitationUuid}
            userId={userId}
            personalNoteUuid={personalNoteUuid}
            hasCollaborator={hasCollaborator}
            investmentUuids={investmentUuids}
            requestUuid={requestUuid}
            done={done}
            deleted={deleted}
            tableData={tableData}
            noteUuid={noteUuid}
            updateDataAfterDeleteComment={updateDataAfterDeleteComment}
            commentIndex={commentIndex}
          />
        </Box>
      )}
    </StyledPopup>
  )
}


const StyledPopup = styled(Popup, {
  '&-overlay': {
    background: 'rgba(0, 0, 0, 0.5)'
  },
  '&-content': {
    fontFamily: 'Inter',
  },
  mx: '$4',
})

const Icon = styled('div', {
  width: '24px',
  '& svg': {
    color: '$grey200',
    filter: 'grayscale(20%)',
    cursor: 'pointer',
    '&:hover': {
      color: '$primary'
    }
  }
})

const BlueButton = styled('button', {
  backgroundColor: '#0b63fa',
  color: 'white',
  borderRadius: '4px',
  padding: '5px $12',
  border: '1px solid #0b63fa',
  cursor: 'pointer',
  fontFamily: 'inherit',
  fontSize: '$14',
  '&:hover': {
    backgroundColor: 'white',
    color: '#0b63fa',
  },
})