import { gql } from "@apollo/client";

export const GET_EXPORT_CONTACTS_URL = gql`
mutation {
  getExportContactsUrl{
    exportContacts {
      url
    }
  }
}
`