import { gql } from '@apollo/client'

export const GET_CO_INVESTORS = gql`
query investorCoInvestors (
  $investorUuid: String!
  $page: Int
  $pageSize: Int
) {
  investorCoInvestors (
    investorUuid: $investorUuid
    page: $page
    pageSize: $pageSize
  ) {
    totalCount
    objects {
      uuid
      name
      homepageUrl
      logoUrl
      logoUrlSmall
    }
  }
}
`
