import { gql, useQuery } from '@apollo/client'

const GET_USERS = gql`
query users (
  $onlyNauta: Boolean
) {
  users (
    onlyNauta: $onlyNauta
  ) {
    id
    username
    email
    name
  }
}
`

export const useUsers = (bool) => {
  const { data, error, loading } = useQuery(GET_USERS, {
    variables: {
      onlyNauta: bool
    }
  })

  return {
    data,
    error,
    loading
  }
}