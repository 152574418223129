import { useEffect, useState, useMemo } from 'react'
import { Header2, Header4 } from '../../components/primitives/Text'
import { Box } from '../../components/primitives/Box'
import { PagePadding } from '../../components/primitives/PagePadding'
import { Flex } from '../../components/primitives/Flex'
import { Modal } from '../../modals'
import DeleteInvestorFromCustomList from '../../modals/DeleteInvestorFromCustomList'
// import { CheckBox } from '../../components/primitives/Input';
import { formatCountryCode } from '../../utils/format/formatCountryCode';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FlexSpaceBetween } from '../../components/primitives/FlexSpaceBetween';
import { useList } from '../../queries/useList'
// import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import { Loader } from '../../components/primitives/Loader';
import { GET_CUSTOM_LIST_DETAIL_PAGINATED } from '../../queries/useCustomListDetailPaginated';
import Status from './components/StatusColumn';
import Comments from './components/CommentsColumn';
import UserFeedback from '../../components/patterns/UserFeedback';
import TopSection from './components/TopSection';
import { Div } from '../../components/primitives/Div';
import { FilterInput } from '../../components/primitives/Input';
import { GET_CUSTOM_LIST_INVESTOR } from '../../queries/useCustomListInvestor';
import { Span } from '../../components/primitives/Span';
import LogoNameUrlSection from '../../components/patterns/LogoNameUrlSection';
import SelectColumnFilter from '../../components/table/filters/SelectColumnFilter'
import CommonTable from '../../components/patterns/CommonTable'

export default function List() {

  const [listName, setListName] = useState('')
  const [listDescription, setListDescription] = useState('')
  const [creator, setCreator] = useState('')
  const [collaborators, setCollaborators] = useState([])
  // const [checked, setChecked] = useState([])
  // const [allChecked, setAllChecked] = useState(false)
  const [tableData, setTableData] = useState([])
  const [userFeedback, setUserFeedback] = useState(false)
  const [listCount, setListCount] = useState(null)

  document.title = listName
  const urlParams = new URLSearchParams(window.location.search)
  const uuid = urlParams.get('uuid')
  const username = localStorage.getItem('username')

  //to get listName, listDescription and collaborators
  const { data: getListData /*, loading: getListLoading, error: getListError*/ } = useList(uuid)

  useEffect(() => {
    setListName(getListData?.customLists[0].name)
    setListDescription(getListData?.customLists[0].description)
    setCreator(getListData?.customLists[0].creator.username)
    setCollaborators(getListData?.customLists[0].collaborators.map((col) => {
      return { label: col.collaborator.email, value: col.collaborator.id, name: col.collaborator.name }
    }))
  }, [getListData])

  const { data: first25Data, loading: first25Loading, error: first25Error } = useQuery(GET_CUSTOM_LIST_DETAIL_PAGINATED, {
    variables: { uuid, page: 1, pageSize: 25 }
  })
  // console.log(first25Data?.customListDetailPaginated.objects)

  const [getAllData, { data: allData, loading: allDataLoading, error: allDataError }] = useLazyQuery(GET_CUSTOM_LIST_DETAIL_PAGINATED, {
    variables: { uuid, page: 1, pageSize: 9999 }
  })

  //-----Set and display the first 25 datas while obtaining the rest, and setting the data when all datas are retrieved / sorting -----

  useEffect(() => {
    if (first25Data?.customListDetailPaginated.objects) {
      setTableData(first25Data?.customListDetailPaginated.objects.map((obj) => {
        const inv = obj.investorUuid
        return (dataWithAccessor(inv, obj, collaborators))
      }))
      if (first25Data?.customListDetailPaginated.totalCount > 25) getAllData()
    }
    if (allData?.customListDetailPaginated.objects) {
      setTableData(allData?.customListDetailPaginated.objects.map((obj) => {
        const inv = obj.investorUuid
        return (dataWithAccessor(inv, obj, collaborators))
      }))
    }
  }, [first25Data, allData, getAllData])

  //-------------------------------For rendering react-table-------------------------------


  const columns = useMemo(() => [
    {
      Header: 'Name', accessor: 'name', Cell: ({ row }) => {
        return <Div /*css={{ w: '220px' }}*/>
          <LogoNameUrlSection investor={row.original.inv} />
        </Div>
      }
    },
    { Header: 'Invest\u00A0Count', accessor: 'investmentCount' },
    {
      Header: 'Min\u00A0Round', accessor: 'leadInvestmentsMin', Cell: ({ row }) => {
        return <>${new Intl.NumberFormat().format(row.original.leadInvestmentsMin)}</>
      }
    },
    {
      Header: 'Max\u00A0Round', accessor: 'leadInvestmentsMax', Cell: ({ row }) => {
        return <>${new Intl.NumberFormat().format(row.original.leadInvestmentsMax)}</>
      }
    },
    {
      Header: 'Location', accessor: 'location', Cell: ({ row }) => {
        return <Div css={{ w: 'fit-content' }}>
          {row.original.locationCity}<br />{row.original.locationCountry}
        </Div>
      }
    },
    {
      Header: 'Status', accessor: 'status', Filter: SelectColumnFilter, filter: 'equals', Cell: ({ row, column }) => {
        return <Status
          status={row.original.status}
          investorUuid={row.original.uuid}
          listUuid={uuid}
          setUserFeedback={setUserFeedback}
          updateData={updateData}
          rowIndex={row.index}
          columnId={column.id}
        />
      }
    },
    {
      Header: 'Comments', accessor: /*'notes'*/'comments', Cell: ({ row, column }) => {
        return <Div css={{ w: '380px' }}>
          <Comments
            notes={row.original.notes}
            comments={row.original.comments}
            investorUuid={row.original.uuid}
            listUuid={uuid}
            setUserFeedback={setUserFeedback}
            // updateData={updateData}
            updateDataAfterAddComment={updateDataAfterAddComment}
            updateDataAfterDeleteComment={updateDataAfterDeleteComment}
            updateDataAfterEditComment={updateDataAfterEditComment}
            rowIndex={row.index}
            columnId={column.id}
            collaborators={row.original.collaborators}
          />
        </Div>
      }
    },
    {
      Header: '', accessor: 'uuid', Cell: ({ row, rows }) => {
        return <Modal
          triggerButtonStyle={'Delete'}
          Component={DeleteInvestorFromCustomList}
          listName={listName}
          investorName={row.original.name}
          investorUuid={row.original.uuid}
          tableData={rows}
          setUserFeedback={setUserFeedback}
          updateDataAfterDelete={updateDataAfterDelete}
        />
      }
    }
  ], [tableData, setTableData])

  const dataWithAccessor = (inv, obj, collaborators) => ({
    uuid: inv.uuid,
    name: inv.extra !== null
      && inv.extra !== ''
      && JSON.parse(inv.extra).name !== undefined
      ? JSON.parse(inv.extra).name
      : inv.name,

    investmentCount: inv.investmentCount,

    leadInvestmentsMin:
      inv.extra !== null
        && inv.extra !== ''
        && JSON.parse(inv.extra).lead_investments_min !== undefined
        ? JSON.parse(inv.extra).lead_investments_min
        : inv.leadInvestmentsMin,

    leadInvestmentsMax: inv.extra !== null
      && inv.extra !== ''
      && JSON.parse(inv.extra).lead_investments_max !== undefined
      ? JSON.parse(inv.extra).lead_investments_max
      : inv.leadInvestmentsMax,

    location: `${inv.extra !== null
      && inv.extra !== ''
      && JSON.parse(inv.extra).city !== undefined
      ? JSON.parse(inv.extra).city
      : inv.city}
    ${inv.extra !== null
        && inv.extra !== ''
        && JSON.parse(inv.extra).country_code !== undefined
        ? formatCountryCode(JSON.parse(inv.extra).country_code)
        : formatCountryCode(inv.countryCode)}`,

    locationCity: `${inv.extra !== null
      && inv.extra !== ''
      && JSON.parse(inv.extra).city !== undefined
      ? JSON.parse(inv.extra).city
      : inv.city}`,

    locationCountry: `${inv.extra !== null
      && inv.extra !== ''
      && JSON.parse(inv.extra).country_code !== undefined
      ? formatCountryCode(JSON.parse(inv.extra).country_code)
      : formatCountryCode(inv.countryCode)}`,

    status: obj.status !== null ? obj.status.id : '',
    notes: [{ note: obj.note, noteAddedBy: obj.noteAddedBy, noteCreatedAt: obj.noteCreatedAt }],
    comments: obj.customlistinvestornoteSet,
    inv: inv,
    collaborators: collaborators,
  })

  const updateData = (rowIndex, columnId, value) => {
    setTableData((old) => (
      old.map((row, index) => {
        if (index === rowIndex) {
          return { ...old[rowIndex], [columnId]: value }
        }
        return row;
      })
    ))
  }

  const updateDataAfterDelete = (investorUuid, tableData) => {
    setTableData((old) => (
      old.filter((d => d.uuid !== investorUuid))
    ))
    let temp = tableData.filter(d => d.original.uuid !== investorUuid)
    setListCount(temp.length)
  }

  const updateDataAfterAdd = (data) => {
    let addedInv = dataWithAccessor(data.investorUuid, data, collaborators)
    tableData.push(addedInv)
    setTableData(tableData)
    setListCount(tableData.length)
  }

  const updateDataAfterAddComment = (data, rowIndex) => {
    let oldCommentsArray = tableData[rowIndex].comments
    let newCommentsArray = [...oldCommentsArray, data]
    setTableData((old) => (
      old.map((row, index) => {
        if (index === rowIndex) {
          return { ...old[rowIndex], comments: newCommentsArray }
        }
        return row;
      })
    ))
  }

  // const updateDataAfterDeleteComment = (rowIndex, commentIndex) => {
  //   let commentsArray = [...tableData[rowIndex].comments]
  //   commentsArray.splice(commentIndex, 1)
  //   setTableData((old) => (
  //     old.map((row, index) => {
  //       if (index === rowIndex) {
  //         return { ...old[rowIndex], comments: commentsArray }
  //       }
  //       return row;
  //     })
  //   ))
  // }

  const updateDataAfterDeleteComment = (rowIndex, noteUuid) => {
    let commentsArray = [...tableData[rowIndex].comments]
    setTableData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            comments: commentsArray.filter((comment) => comment.uuid !== noteUuid)
          }
        }
        return row
      })
    )
  }

  const updateDataAfterEditComment = (rowIndex, data) => {
    let commentsArray = [...tableData[rowIndex].comments]
    let newCommentsArray = commentsArray.map((comment) => {
      if (comment.uuid === data.uuid) {
        return { ...comment, note: data.note, updatedAt: data.updatedAt }
      }
      return comment
    })
    setTableData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            comments: newCommentsArray
          }
        }
        return row
      })
    )
  }


  const [getCustomListInvestor] = useLazyQuery(GET_CUSTOM_LIST_INVESTOR, {
    onCompleted: (data) => {
      updateDataAfterAdd(data.customListInvestor)
    }
  })

  //-------------------------------For handling Checkboxes-------------------------------

  // const handleCheck = (event) => {
  //   let updatedList = [...checked];
  //   if (event.target.checked) {
  //     updatedList = [...checked, event.target.value];
  //   } else {
  //     updatedList.splice(checked.indexOf(event.target.value), 1);
  //   }
  //   setChecked(updatedList);
  // }

  // const handleCheckAll = () => {
  //   setAllChecked(!allChecked)
  //   setChecked(rows?.slice(pageIndex * 25, (pageIndex * 25) + 25).map((data) => {
  //     return data.original.uuid
  //   }))
  //   if (allChecked) {
  //     setChecked([])
  //   }
  // }

  /////////////////////////////////////////////////////////////
  return (
    <>
      {userFeedback && <UserFeedback userFeedback={userFeedback} setUserFeedback={setUserFeedback} />}

      <PagePadding data-testid='list-page'>

        <TopSection
          uuid={uuid}
          setUserFeedback={setUserFeedback}
          listName={listName}
          listDescription={listDescription}
          setListDescription={setListDescription}
          creator={creator}
          collaborators={collaborators}
          updateDataAfterAdd={updateDataAfterAdd}
          getCustomListInvestor={getCustomListInvestor}
        />

        <Box>
          <FlexSpaceBetween css={{ mb: '$12', '@bp2': { display: 'block' } }}>
            <Div css={{ '@bp2': { mb: '$12' } }}>
              <Header2>
                {listName}
                &nbsp;&nbsp;
                ({listCount !== null
                  ? listCount
                  : first25Data?.customListDetailPaginated.hasNext
                    ? allData?.customListDetailPaginated.count
                      ? allData?.customListDetailPaginated.count
                      : <Loader size="14" css={{ d: 'inline-block' }} />
                    : first25Data?.customListDetailPaginated.count
                })
              </Header2>
              <Header4 css={{ color: '$grey500', mt: '$6', mb: '0px' }}>{listDescription}</Header4>
              <Span css={{ fontSize: '$12', color: '$grey400', fontWeight: '300' }} data-testid='list-collaborators'>
                Collaborators:&nbsp;
                {collaborators?.map((collaborator, i) => {
                  return (
                    <span key={collaborator.label}>
                      {collaborator.label}{i !== collaborators.length - 1 && ', '}&nbsp;
                    </span>
                  )
                })}
              </Span>
            </Div>
            {/* <Modal
              triggerButtonLabel={(
                <Flex>
                  Delete&nbsp;selected&nbsp;investors
                  <IconInsideButton><DeleteIcon /></IconInsideButton>
                </Flex>
              )}
              Component={DeleteInvestorFromCustomList}
              listName={listName}
              investorUuid={checked}
              setAllChecked={setAllChecked}
              setUserFeedback={setUserFeedback}
              updateDataAfterDelete={updateDataAfterDelete}
            /> */}
          </FlexSpaceBetween>

          <CommonTable
            data={tableData}
            columns={columns}
            loading={first25Loading}
            allDataLoading={allDataLoading}
            error={first25Data?.customListDetailPaginated.hasNext ? allDataError : first25Error}
            columnsWithNoSort={['Comments', '']}
            columnsWithNoFilter={['Invest\u00A0Count', 'Min\u00A0Round', 'Max\u00A0Round', 'Comments', '']}
          />
        </Box>

      </PagePadding>
    </>
  )
}






