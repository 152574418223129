import { Box } from '../../../components/primitives/Box'
import { Header2, Paragraph } from '../../../components/primitives/Text'
import { Modal } from '../../../modals'
import { Div } from '../../../components/primitives/Div'
import AskForIntro from '../../../modals/AskForIntro'
import { useInvestorContacts } from '../../../queries/useInvestorContacts'
import { Loader } from '../../../components/primitives/Loader'
import { Span } from '../../../components/primitives/Span'

export default function Connections({ setUserFeedback, uuid }) {

  const { data, loading, error } = useInvestorContacts(uuid)
  // console.log(data?.investorContacts.filter(con=> con.contactOwnerName === 'Borja Breña'
  // ).length)

  // function onlyUnique(data) {
  //   const contactOwnerOrganization = []
  //   const filteredArray = []
  //   data?.investorContacts.map((item) => {
  //     if (!contactOwnerOrganization.includes(item.contactOwnerOrganization)) {
  //       contactOwnerOrganization.push(item.contactOwnerOrganization)
  //       filteredArray.push(item)
  //     }
  //   })
  //   return filteredArray
  // }
  // const filteredArray = onlyUnique(data)

  const displayed = []
  const displayedContactOwner = []

  const AskForIntroButton = ({ contact }) => {
    // console.log(contact)
    return <Modal
      triggerButtonStyle={'AskForIntroButton'}
      triggerButtonLabel={`Ask ${contact.contactPointName} for intro`}
      title={'Ask for an intro'}
      Component={AskForIntro}
      contactUuid={contact.uuid}
      investorUuid={contact.investor.uuid}
      setUserFeedback={setUserFeedback}
    />
  }

  return (
    <Box css={{ fb: '49.5%', overflowX: 'scroll', '@bp3': { mb: '20px' } }} >
      <Header2 css={{ mb: '$12' }}>Connections</Header2>
      {loading && <Loader size="16" css={{ d: 'inline-block', mt: '$8', ml: '$8' }} />}
      {error && <Paragraph>An error occurred. Please try again or report this bug.</Paragraph>}
      <Div css={data?.investorContacts.length >= 1 && { whiteSpace: 'nowrap' }}>
        {data?.investorContacts.length === 0
          ? <Paragraph>No connection with this investor</Paragraph>
          : data?.investorContacts.slice().sort((a, b) => a.contactOwnerName.localeCompare(b.contactOwnerName)).map((contact) => {
            return (
              <Paragraph key={contact.uuid}>
                {contact.ownContact
                  ? <Span css={{ display: 'block', mb: '12px' }}>You know <b>{contact.contactName}</b> : {contact.email}</Span>
                  : contact.nautaUser
                    ? contact.contactOwnerNautaUser //the user and the contact owner are both from Nauta
                      ? !displayedContactOwner.includes(contact.contactOwnerName)
                        ? (displayedContactOwner.push(contact.contactOwnerName),
                          <span>
                            <b>{contact.contactOwnerName}</b> knows
                            {data?.investorContacts.filter(con => con.contactOwnerName === contact.contactOwnerName).length >= 2 ? <b>・</b> : <b>&nbsp;</b>}
                            <b>{contact.contactName}</b>
                            <AskForIntroButton contact={contact} />
                          </span>)
                        : <span>
                          <Span css={{ color: 'transparent' }}>{contact.contactOwnerName} knows </Span>
                          <b>・{contact.contactName}</b>
                          <AskForIntroButton contact={contact} />
                        </span>

                      : !displayedContactOwner.includes(contact.contactOwnerName)
                        ? (displayedContactOwner.push(contact.contactOwnerName),
                          <span>
                            <b>{contact.contactOwnerName}</b> from <b>{contact.contactOwnerOrganization}</b> knows
                            {data?.investorContacts.filter(con => con.contactOwnerName === contact.contactOwnerName).length >= 2 ? <b>・</b> : <b>&nbsp;</b>}
                            <b>{contact.contactName}</b>
                            <AskForIntroButton contact={contact} />
                          </span>)
                        : <span>
                          <Span css={{ color: 'transparent' }}>{contact.contactOwnerName} from {contact.contactOwnerOrganization} knows</Span>
                          <b>・{contact.contactName}</b>
                          <AskForIntroButton contact={contact} />
                        </span>

                    : !displayed.includes(contact.contactOwnerOrganization)
                      ? (displayed.push(contact.contactOwnerOrganization),
                        <span><b>{contact.contactOwnerOrganization}</b> has contact <AskForIntroButton contact={contact} /></span>)
                      : <></>
                }
              </Paragraph>
            )
          })}
      </Div>
    </Box >
  )
}


