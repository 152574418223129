import { useState, useRef } from 'react'
import { CHANGE_PASSWORD } from './mutation'
import { Header2, Header4 } from '../../components/primitives/Text'
import { Box } from '../../components/primitives/Box'
import { Input } from '../../components/primitives/Input'
import { PrimaryButton, ShowAndHideButton } from '../../components/primitives/Button'
import { Div } from '../../components/primitives/Div'
import { styled } from '../../styles'
import { Flex } from '../../components/primitives/Flex'
import { useMutation } from '@apollo/client'
import UserFeedback from '../../components/patterns/UserFeedback';
import { RequiredMark } from '../../components/primitives/RequiredMark'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as HidePasswordIcon } from '../../assets/icons/hide-password.svg'
import { ReactComponent as DisplayPasswordIcon } from '../../assets/icons/display-password.svg'
import { MediumIcon } from '../../components/primitives/IconWrapper'

export default function ChangePassword() {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [newPassword1, setNewPassword1] = useState('')
  const [newPassword2, setNewPassword2] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword1, setShowNewPassword1] = useState(false)
  const [showNewPassword2, setShowNewPassword2] = useState(false)
  const [userFeedback, setUserFeedback] = useState(null)

  const usernameInputRef = useRef(null)
  const passwordInputRef = useRef(null)
  const newPassword1InputRef = useRef(null)
  const newPassword2InputRef = useRef(null)
  const buttonForChangePasswordRef = useRef(null)

  const navigate = useNavigate()

  const [changePassword] = useMutation(CHANGE_PASSWORD, {
    variables: {
      username,
      password,
      newPassword1,
      newPassword2,
    },
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: () => {
      setUserFeedback({ success: true, text: 'Password updated' })
      setTimeout(() => {
        navigate('/search-investors')
      }, 3000)
    }
  })

  return (
    <Div css={{ h: 'calc(100vh - 55px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {userFeedback && <UserFeedback userFeedback={userFeedback} setUserFeedback={setUserFeedback} />}
      <Box css={{ w: '28rem', mx: '$12' }}>
        <Header2 css={{ textAlign: 'center', mb: '$24' }}>Change password</Header2>

        <Header4 css={{ mb: '$2' }}>Email <RequiredMark /></Header4>

        <Input
          value={username}
          type='email'
          onChange={(e) => setUsername(e.target.value)}
          ref={usernameInputRef}
          onKeyPress={(event) => {
            const { key } = event;
            if (key === "Enter") {
              passwordInputRef.current.focus();
            }
          }}
        />

        <Header4 css={{ mb: '$2' }}>Current password <RequiredMark /></Header4>
        <Flex css={{ position: 'relative' }}>
          <ShowAndHideButton onClick={() => setShowPassword(!showPassword)}>
            {showPassword
              ? <MediumIcon><HidePasswordIcon /></MediumIcon>
              : <MediumIcon><DisplayPasswordIcon /></MediumIcon>
            }
          </ShowAndHideButton>
          <Input
            value={password}
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            ref={passwordInputRef}
            onKeyPress={(event) => {
              const { key } = event;
              if (key === "Enter") {
                newPassword1InputRef.current.focus();
              }
            }}
          />
        </Flex>

        <Header4 css={{ mb: '$2' }}>New password <RequiredMark /></Header4>
        <Flex css={{ position: 'relative' }}>
          <ShowAndHideButton onClick={() => setShowNewPassword1(!showNewPassword1)}>
            {showNewPassword1
              ? <MediumIcon><HidePasswordIcon /></MediumIcon>
              : <MediumIcon><DisplayPasswordIcon /></MediumIcon>
            }
          </ShowAndHideButton>
          <Input
            value={newPassword1}
            type={showNewPassword1 ? 'text' : 'password'}
            onChange={(e) => setNewPassword1(e.target.value)}
            ref={newPassword1InputRef}
            onKeyPress={(event) => {
              const { key } = event;
              if (key === "Enter") {
                newPassword2InputRef.current.focus();
              }
            }}
          />
        </Flex>

        <Header4 css={{ mb: '$2' }}>Confirm new password<RequiredMark /></Header4>
        <Flex css={{ position: 'relative' }}>
          <ShowAndHideButton onClick={() => setShowNewPassword2(!showNewPassword2)}>
            {showNewPassword2
              ? <MediumIcon><HidePasswordIcon /></MediumIcon>
              : <MediumIcon><DisplayPasswordIcon /></MediumIcon>
            }
          </ShowAndHideButton>
          <Input
            value={newPassword2}
            type={showNewPassword2 ? 'text' : 'password'}
            onChange={(e) => setNewPassword2(e.target.value)}
            ref={newPassword2InputRef}
            onKeyPress={(event) => {
              const { key } = event;
              if (key === "Enter") {
                buttonForChangePasswordRef.current.focus();
              }
            }}
          />
        </Flex>

        <PrimaryButton
          css={{ mt: '$24', w: '100%' }}
          ref={buttonForChangePasswordRef}
          disabled={
            username.trim().length === 0
            || password.trim().length === 0
            || newPassword1.trim().length === 0
            || newPassword2.trim().length === 0
          }
          onClick={() => changePassword()}
        >Change password</PrimaryButton>
      </Box>
    </Div>
  )
}


