import { gql, useQuery } from "@apollo/client";

export const GET_MYFOUNDERS = gql`
query {
  myFounders {
    id
    name
    email
    organization
    isActive
  }
}
`

export const useMyFounders = () => {
  const { data, error, loading } = useQuery(GET_MYFOUNDERS)
  return { data, error, loading }
}