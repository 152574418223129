import { TableData, TableRow } from '../../../components/primitives/Table';
import moment from 'moment'
import LogoNameUrlSection from '../../../components/patterns/LogoNameUrlSection';
import { Modal } from '../../../modals'
import { ReactComponent as CheckIcon } from '../../../assets/icons/check-circle-outlined.svg'
import { SmallIcon } from '../../../components/primitives/IconWrapper';
import { Span } from '../../../components/primitives/Span';
import UpdateIntroRequest from '../../../modals/UpdateIntroRequest';
import { Flex } from '../../../components/primitives/Flex';


export default function RequestRow({ req, displayedTab, setUserFeedback }) {

  return (
    <TableRow>
      {/* <TableData>{req.uuid}</TableData> */}
      <TableData>
        <LogoNameUrlSection investor={req.investorUuid} />
      </TableData>
      <TableData>{req.contactUuid.name}</TableData>

      <TableData>
        {displayedTab === 'sent'
          ? <>{req.requestedTo.firstName} {req.requestedTo.lastName}</>
          : <>{req.requestedBy.firstName} {req.requestedBy.lastName}</>
        }
      </TableData>

      <TableData>{moment(req.createdAt).format('lll')}</TableData>
      <TableData>{req.commentText}</TableData>

      <TableData>
        {(displayedTab === 'received' && req.requestedToDone)
          ? <Flex css={{fd: 'column', alignItems:'center', w: 'fit-content'}}>
            <SmallIcon css={{ color: '$green', mr: '$0', mb: '$4' }}><CheckIcon /></SmallIcon>
            <Modal
              triggerButtonLabel={'Mark as undone'}
              triggerButtonStyle={'markAsDone'}
              Component={UpdateIntroRequest}
              requestUuid={req.uuid}
              setUserFeedback={setUserFeedback}
              done={false}
            />
          </Flex>
          : (displayedTab === 'received' && !req.requestedToDone)
          &&
          <Flex css={{fd: 'column', alignItems: 'center', w: 'fit-content'}}>
            {/* <Span css={{w: 'fit-content', mb: '$4' }}>undone</Span> */}
            <Modal
              triggerButtonLabel={'Mark as done'}
              // triggerButtonStyle={'markAsDone'}
              triggerButtonStyle={'smallSecondary'}
              Component={UpdateIntroRequest}
              requestUuid={req.uuid}
              setUserFeedback={setUserFeedback}
              done={true}
            />
          </Flex>
        }
      </TableData>


      <TableData>
        <Modal
          triggerButtonLabel={'Delete'}
          triggerButtonStyle={'smallSecondary'}
          Component={UpdateIntroRequest}
          requestUuid={req.uuid}
          setUserFeedback={setUserFeedback}
          deleted={true}
        />
      </TableData>



    </TableRow>
  )
}
