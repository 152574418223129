import { gql } from "@apollo/client";

export const GET_INVESTOR_PORTFOLIO_CATEGORIES = gql`
query(
  $investorUuid: String
  $category: String
  $page: Int
  $pageSize: Int
) {
  investorPortfolioCategories(
    investorUuid: $investorUuid
    category: $category
    page: $page
    pageSize: $pageSize
  ) {
    categories {
      category
      count
      organizations {
        uuid
        name
        countryCode
        categoryList
        logoUrl
        logoUrlSmall
        homepageUrl
      }
    }
  }
}`