import React, { useState } from 'react'
import { PrimaryButton, SecondaryButton, ButtonsWrapper } from '../components/primitives/Button'
import { Header4 } from '../components/primitives/Text'
import { Input, TextArea/*, CheckBox*/ } from '../components/primitives/Input'
import { useMutation } from '@apollo/client'
import { Flex } from '../components/primitives/Flex'
import { FlexSpaceBetween } from '../components/primitives/FlexSpaceBetween'
import { useInvestor } from '../queries/useInvestor'
import { CountryOptions } from '../options/CountryOptions'
import { InvestorTypeOptions } from '../options/InvestorTypeOptions'
import { RoundSizeWithoutBlankOption as RoundSizeOptions } from '../options/RoundSizeOptions'
import { CategoryOptions } from '../options/CategoryOptions'
import { MultiSelect } from "react-multi-select-component";
import { formatCountryCode } from '../utils/format/formatCountryCode'
import { formatInvestorType } from '../utils/format/formatInvestorType'
import { formatMinRoundSize } from '../utils/format/formatMinRoundSize'
import { formatMaxRoundSize } from '../utils/format/formatMaxRoundSize'
import { UPDATE_INVESTOR } from '../mutations/updateInvestor'
import { DropdownBox } from '../components/patterns/Dropdown'
import { styled } from '../styles'
import { GET_INVESTOR } from '../queries/useInvestor'
import { Div } from '../components/primitives/Div'

export default function EditInvesterProfile({ close, setUserFeedback }) {

  const urlParams = new URLSearchParams(window.location.search)
  const uuid = urlParams.get('uuid')

  const { data /*, loading, error*/ } = useInvestor(uuid)

  const investor = data.investor
  // console.log(investor)
  const extra = data.investor.extra.length !== 0 ? JSON.parse(data.investor.extra) : false
  // console.log(extra)

  const [name, setName] = useState(extra.name ? extra.name : investor.name)
  const [homepageUrl, setHomepageUrl] = useState(extra.homepage_url ? extra.homepage_url : investor.homepageUrl)
  const [twitterUrl, setTwitterUrl] = useState(extra.twitter_url ? extra.twitter_url : investor.twitterUrl)
  const [linkedinUrl, setLinkedinUrl] = useState(extra.linkedin_url ? extra.linkedin_url : investor.linkedinUrl)
  const [city, setCity] = useState(extra.city ? extra.city : investor.city)
  const [countryCode, setCountryCode] = useState({
    label: extra.country_code
      ? formatCountryCode(extra.country_code)
      : investor.countryCode
        ? formatCountryCode(investor.countryCode)
        : null,
    value: extra.country_code
      ? extra.country_code.toLowerCase()
      : investor.countryCode
        ? investor.countryCode.toLowerCase()
        : null
  })
  const [fundingRoundPreSeed, setFundingRoundPreSeed] = useState(
    extra.funding_round_pre_seed === undefined && investor.fundingRoundSeed
      ? investor.fundingRoundSeed
      : extra.funding_round_pre_seed
  )
  const [fundingRoundSeed, setFundingRoundSeed] = useState(
    extra.funding_round_seed === undefined && investor.fundingRoundSeed
      ? investor.fundingRoundSeed
      : extra.funding_round_seed
  )
  const [fundingRoundSeriesA, setFundingRoundSeriesA] = useState(
    extra.funding_round_series_a === undefined && investor.fundingRoundSeriesA
      ? investor.fundingRoundSeriesA
      : extra.funding_round_series_a
  )
  const [fundingRoundSeriesB, setFundingRoundSeriesB] = useState(
    extra.funding_round_series_b === undefined && investor.fundingRoundSeriesB
      ? investor.fundingRoundSeriesB
      : extra.funding_round_series_b
  )
  const [fundingRoundSeriesC, setFundingRoundSeriesC] = useState(
    extra.funding_round_series_c === undefined && investor.fundingRoundSeriesC
      ? investor.fundingRoundSeriesC
      : extra.funding_round_series_c
  )
  const [foundedOn, setFoundedOn] = useState(extra.founded_on ? extra.founded_on : investor.foundedOn)
  const [shortDescription, setShortDescription] = useState(extra.short_description ? extra.short_description : investor.shortDescription)

  const typesWithoutBrackets = investor.investorTypes.replace('{', '').replace('}', '')
  const typesInArray = typesWithoutBrackets.split(',').filter((type) => {
    return type === 'venture_capital' || type === 'corporate_venture_capital' || type === 'family_investment_office' || type === 'micro_vc' || type === 'private_equity_firm' || type === 'venture_debt'
  })

  const [investorTypes, setInvestorTypes] = useState(
    extra.investor_types
      //if there's info in extra
      ? extra.investor_types.includes(',')
        //more than two types 
        ? extra.investor_types.split(',').map((type) => {
          return ({
            label: formatInvestorType(type),
            value: type
          })
        })
        //only one type
        : [{
          label: formatInvestorType(extra.investor_types),
          value: extra.investor_types
        }]
      :
      //if there's no info in extra
      typesWithoutBrackets.includes(',')
        ?
        //more than two types
        typesInArray.map((type) => {
          return ({
            label: formatInvestorType(type),
            value: type
          })
        })
        :
        //only one type
        [{
          label: formatInvestorType(investor.investorTypes),
          value: typesWithoutBrackets
        }]
  )

  const [minRoundSize, setMinRoundSize] = useState(extra.lead_investments_min ? extra.lead_investments_min : investor.leadInvestmentsMin)
  const [maxRoundSize, setMaxRoundSize] = useState(extra.lead_investments_max ? extra.lead_investments_max : investor.leadInvestmentsMax)
  const [portfolioCountries, setPortfolioCountries] = useState(
    //if there is info in extra
    extra.portfolio_country_codes
      //check if there are two or more countries
      ? extra.portfolio_country_codes.includes(',')
        //map if there's more than two countries
        ? extra.portfolio_country_codes.split(',').map((country) => {
          return ({
            label: formatCountryCode(country),
            value: country.toLowerCase()
          })
        })
        :
        //put in an array if there"s only one country
        [{
          label: formatCountryCode(extra.portfolio_country_codes),
          value: extra.portfolio_country_codes.toLowerCase()
        }]
      //if there is no info in extra
      : investor.portfolioCountryCodes.includes(',')
        ?
        //map if there's more than two countries
        investor.portfolioCountryCodes.split(',').map((country) => {
          return ({
            label: formatCountryCode(country),
            value: country.toLowerCase()
          })
        })
        :
        //put in an array if there"s only one country
        [{
          label: formatCountryCode(investor.portfolioCountryCodes),
          value: investor.portfolioCountryCodes.toLowerCase()
        }]
  )
  const [portfolioCategories, setPortfolioCategories] = useState(
    extra.portfolio_categories
      //if there's extra info
      ? extra.portfolio_categories.includes(',')
        ? extra.portfolio_categories.split(',').map((category) => {
          return ({
            label: category,
            value: category
          })
        })
        :
        [{
          label: extra.portfolio_categories,
          value: extra.portfolio_categories
        }]
      :
      //if there's no extra info
      investor.portfolioCategories.includes(',')
        ?
        investor.portfolioCategories.split(',').map((category) => {
          return ({
            label: category,
            value: category
          })
        })
        :
        [{
          label: investor.portfolioCategories,
          value: investor.portfolioCategories
        }]
  )

  // console.log({
  //   name, homepageUrl, twitterUrl, linkedinUrl, city, countryCode,
  //   fundingRoundPreSeed, fundingRoundSeed, fundingRoundSeriesA, fundingRoundSeriesB, fundingRoundSeriesC,
  //   shortDescription, minRoundSize, maxRoundSize, portfolioCountries, portfolioCategories, investorTypes
  // })

  const [updateInvestor] = useMutation(UPDATE_INVESTOR, {
    variables: {
      city,
      countryCode: countryCode.value,
      foundedOn,
      fundingRoundPreSeed,
      fundingRoundSeed,
      fundingRoundSeriesA,
      fundingRoundSeriesB,
      fundingRoundSeriesC,
      homepageUrl,
      investorTypes: investorTypes.map((co) => {
        return co.value
      }).toString(),
      leadInvestmentsMax: maxRoundSize,
      leadInvestmentsMin: minRoundSize,
      linkedinUrl,
      name,
      portfolioCategories: portfolioCategories.map((co) => {
        return co.value
      }).toString(),
      portfolioCountryCodes: portfolioCountries.map((country) => {
        return country.value
      }).toString(),
      shortDescription,
      twitterUrl,
      uuid,
    },
    refetchQueries: [
      { query: GET_INVESTOR, variables: { uuid } }
    ],
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: () => {
      close()
      setUserFeedback({ success: true, text: 'Investor profile editted' })
    }
  })

  return (
    <FormWrapper>
      <FlexSpaceBetween css={{ '@bp3': { display: 'block' } }}>
        <Section
          css={{ mr: '$20', '@bp3': { mr: '0px' } }}
        >
          <Header4>Name</Header4>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Section>
        <Section>
          <Header4>Website</Header4>
          <Input
            value={homepageUrl}
            onChange={(e) => setHomepageUrl(e.target.value)}
          />
        </Section>
      </FlexSpaceBetween>

      <FlexSpaceBetween css={{ '@bp3': { display: 'block' } }}>
        <Section>
          <Header4>Linkedin URL</Header4>
          <Input
            value={linkedinUrl}
            onChange={(e) => setLinkedinUrl(e.target.value)}
          />
        </Section>
        <Section>
          <Header4>Twitter URL</Header4>
          <Input
            value={twitterUrl}
            onChange={(e) => setTwitterUrl(e.target.value)}
          />
        </Section>
      </FlexSpaceBetween>

      <FlexSpaceBetween css={{ '@bp3': { display: 'block' } }}>
        <Section>
          <Header4>City</Header4>
          <Input
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </Section>
        <Section>
          <Header4>Country</Header4>
          <MultiSelect
            options={CountryOptions}
            hasSelectAll={false}
            onChange={(e) => setCountryCode({
              label: formatCountryCode(e[e.length - 1].value),
              value: e[e.length - 1].value
            })}
            value={[countryCode]}
            closeOnChangedValue={true}
            ClearIcon={false}
            className='deleteIconInEditInvestorInformationMultiSelect hideCheckbox'
          />
        </Section>
      </FlexSpaceBetween>

      <FlexSpaceBetween css={{ '@bp3': { display: 'block' } }}>
        <Section>
          <Header4>Min round size</Header4>
          <DropdownBox
            options={RoundSizeOptions}
            item={formatMinRoundSize(minRoundSize)}
            setItem={setMinRoundSize}
          />
        </Section>

        <Section>
          <Header4>Max round size</Header4>
          <DropdownBox
            options={RoundSizeOptions}
            item={formatMaxRoundSize(maxRoundSize)}
            setItem={setMaxRoundSize}
          />
        </Section>
      </FlexSpaceBetween>

      <FlexSpaceBetween css={{ '@bp3': { display: 'block' } }}>
        <Section>
          <Header4>Portfolio countries</Header4>
          <MultiSelect
            options={CountryOptions}
            value={portfolioCountries}
            onChange={setPortfolioCountries}
          />
        </Section>

        <Section>
          <Header4>Portfolio categories</Header4>
          <MultiSelect
            options={CategoryOptions}
            value={portfolioCategories}
            onChange={setPortfolioCategories}
          />
        </Section>
      </FlexSpaceBetween>

      <FlexSpaceBetween css={{ '@bp3': { display: 'block' } }}>
        <Section>
          <Header4>Founded on</Header4>
          <Input
            type='date'
            onChange={(e) => setFoundedOn(e.target.value)}
            value={foundedOn}
          />
        </Section>

        <Section>
          <Header4>Investor types</Header4>
          <MultiSelect
            options={InvestorTypeOptions}
            hasSelectAll={false}
            disableSearch={true}
            value={investorTypes}
            onChange={setInvestorTypes}
          />
        </Section>
      </FlexSpaceBetween>

      <FlexSpaceBetween css={{ mt: '$4', '@bp0': {display: 'block'} }}>
        <Flex>
          <Header4>PreSeed</Header4>
          <CheckBoxForRoundType
            type='checkbox'
            checked={fundingRoundPreSeed}
            onChange={() => setFundingRoundPreSeed(!fundingRoundPreSeed)}
          />
        </Flex>

        <Flex>
          <Header4>Seed</Header4>
          <CheckBoxForRoundType
            type='checkbox'
            checked={fundingRoundSeed}
            onChange={() => setFundingRoundSeed(!fundingRoundSeed)} />
        </Flex>

        <Flex>
          <Header4>Series&nbsp;A</Header4>
          <CheckBoxForRoundType
            type='checkbox'
            checked={fundingRoundSeriesA}
            onChange={() => setFundingRoundSeriesA(!fundingRoundSeriesA)}
          />
        </Flex>

        <Flex>
          <Header4>Series&nbsp;B</Header4>
          <CheckBoxForRoundType
            type='checkbox'
            checked={fundingRoundSeriesB}
            onChange={() => setFundingRoundSeriesB(!fundingRoundSeriesB)}
          />
        </Flex>

        <Flex>
          <Header4>Series&nbsp;C</Header4>
          <CheckBoxForRoundType
            type='checkbox'
            checked={fundingRoundSeriesC}
            onChange={() => setFundingRoundSeriesC(!fundingRoundSeriesC)}
          />
        </Flex>
      </FlexSpaceBetween>

      <Div>
        <Header4 css={{ mt: '$8' }}>Short description</Header4>
        <TextArea
          value={shortDescription}
          onChange={(e) => setShortDescription(e.target.value)}
          rows='3'
          css={{ w: '100% !important' }}
        />
      </Div>

      <ButtonsWrapper css={{ mt: '$16' }}>
        <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
        <PrimaryButton
          type='button'
          onClick={() => updateInvestor()}
        >Edit</PrimaryButton>
      </ButtonsWrapper>
    </FormWrapper>
  )
}

const FormWrapper = styled('div', {
  overflowY: 'scroll',
  height: '86vh',
  '@bp0': {
    width: 'calc(100vw - 60px)'
  },
})

const Section = styled('div', {
  width: '380px',
  '@bp0': {
    width: '100%'
  },
})

const CheckBoxForRoundType = styled('input', {
  accentColor: '#f04e29',
  mt: '$6',
  ml: '$4',
  mr: '$6',
})

