import { useState } from 'react'
import { StatusOptions as options } from '../../../../options/StatusOptions'
import { ADD_STATUS_TO_INVESTOR } from '../../../../mutations/addStatusToInvestor'
import { useMutation } from '@apollo/client'
import { styled } from '../../../../styles'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Flex } from '../../../../components/primitives/Flex'

export default function Status({ status, investorUuid, listUuid, setUserFeedback, updateData, rowIndex, columnId }) {

  const [newStatus, setNewStatus] = useState(false)

  const [addStatus] = useMutation(ADD_STATUS_TO_INVESTOR, {
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      setUserFeedback({ success: true, text: 'Status changed' })
      setNewStatus(data?.addStatusToInvestorInCustomList.customListInvestor.status.id)
    }
  })

  return (
    <DropdownButton
      data-testid='status-dropdown-button'
      size='sm'
      id="dropdown-basic-button"
      title={
        newStatus
          ?
          newStatus === ''
            ? '-'
            : options?.map((option) => {
              return (
                newStatus === option.value &&
                <Flex css={{ alignItems: 'center' }} key={option.value}>
                  {option.value !== '' &&
                    <CircleForStatus css={{ color: option.color }}>●</CircleForStatus>
                  }
                  {option.value}
                </Flex>
              )
            })
          :
          status === ''
            ? '-'
            : options?.map((option) => {
              return (
                status === option.value &&
                <Flex css={{ alignItems: 'center' }} key={option.value}>
                  {option.value !== '' &&
                    <CircleForStatus css={{ color: option.color }}>●</CircleForStatus>
                  }
                  {option.value}
                </Flex>
              )
            })
      }
    >
      {options?.map((option) => {
        return (
          <Dropdown.Item
          // style={{width: 'fit-content'}}
            onClick={() => {
              addStatus({ variables: { listUuid, investorUuid, status: option.value } })
              updateData(rowIndex, columnId, option.value)
            }}
            key={option.value}
            value={option.value}
            data-testid='status-dropdown-items'
          >
            <Flex css={{ alignItems: 'center' }}>
              {option.value !== '' &&
                <CircleForStatus css={{ color: option.color }}>●</CircleForStatus>
              }
              {/* using option.label for '-' option */}
              {option.label}
            </Flex>
          </Dropdown.Item>
        )
      })}

    </DropdownButton>
  )
}


const CircleForStatus = styled('span', {
  marginRight: '12px',
})
