import { gql } from '@apollo/client';

export const ADD_INVESTORS_TO_CUSTOM_LIST = gql`
mutation addInvestorsToCustomList (
  $listUuid: String!,
  $investorUuids: String!
) {
addInvestorsToCustomList(
  listUuid: $listUuid,
  investorUuids: $investorUuids
) {
  customList {
    # these uuid and name are crucial for adding investors from investor profile page or from search-investors page
    uuid
    name
  }
}}
`


