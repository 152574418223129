import { gql } from '@apollo/client'

export const ASSIGN_CONTACT = gql`
mutation assignContact(
  $investorUuid: String!,
  $contactUuid: String!
) {
  assignContact (
    investorUuid: $investorUuid,
    contactUuid: $contactUuid
  ) {
    contact {
      uuid
      name
      email
      comment
      investor{
        uuid
        name
        logoUrl
        logoUrlSmall
        permalink
        cbUrl
        homepageUrl
        linkedinUrl
        twitterUrl
        extra
      }
    }
  }
}
`