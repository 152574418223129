import React, { useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { FlexSpaceBetween } from '../components/primitives/FlexSpaceBetween'
import { FilterInput } from '../components/primitives/Input'
import { Flex } from '../components/primitives/Flex'
import { Paragraph } from '../components/primitives/Text'
import { Loader } from '../components/primitives/Loader'
import { SmallCompanyLogo } from '../components/primitives/CompanyLogo'
import { GET_INVESTORS } from '../queries/useInvestors'
import { ASSIGN_CONTACT } from '../mutations/assignContact'
import { styled } from '../styles'
import { SmallSecondaryButton } from '../components/primitives/Button'
import { Div } from '../components/primitives/Div'

export default function AssignInvestor({ setUserFeedback, contactUuid, close, updatePendingData, rowIndex, columnId, displayedTab, updateDataAfterAssign }) {

  // console.log(rowIndex, columnId)

  const [investor, setInvestor] = useState('')
  const [investorUuid, setInvestorUuid] = useState('')
  const [assignInputBox, setAssignInputBox] = useState(false)

  //getting investor names for input for assigning investor
  const [getInvestors, { data, error, loading }] = useLazyQuery(GET_INVESTORS, {
    variables: {
      searchbox: investor,
      pageSize: 5,
    }
  })

  //for assigning investor
  const [assignContact, { error: assignContactError }] = useMutation(ASSIGN_CONTACT, {
    onError: () => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      close()
      // console.log(data.assignContact.contact.investor)
      setInvestor('')
      setUserFeedback({ success: true, text: 'Investor assigned' })
      displayedTab === 'pending' && updatePendingData(rowIndex, 'investor', data.assignContact.contact.investor)
      displayedTab === 'notAssigned' && updateDataAfterAssign(rowIndex, data.assignContact.contact)
    }
  })


  return (
    <Div css={{ minHeight: 'fit-content' }}>
      <FlexSpaceBetween css={{ marginTop: '24px', margin: '24px' }}>
        <FilterInput css={{ mr: '$6', '&>input': { height: '25px' } }}>
          <input
            css={{ mr: '$6' }}
            onChange={(e) => {
              setInvestor(e.target.value)
              getInvestors()
              setAssignInputBox(true)
            }}
            value={investor}
          />
        </FilterInput>
        <SmallSecondaryButton
          disabled={investor.trim().length === 0}
          onClick={() =>
            assignContact(
              { variables: { contactUuid, investorUuid } }
            )
          }
        >Assign</SmallSecondaryButton>
      </FlexSpaceBetween>
      {error &&
        <SearchResult>
          <Paragraph>An error occurred. Please try again or report this bug.</Paragraph>
        </SearchResult>
      }
      {loading &&
        <SearchResult>
          <Loader size="16" css={{ d: 'inline-block', mx: '$16', mt: '$4' }} />
        </SearchResult>
      }
      {data && investor !== '' && assignInputBox &&
        <SearchResult>
          {data.search.objects.map((investor) => {
            return (
              <Flex
                key={investor.uuid}
                onClick={() => {
                  setInvestorUuid(investor.uuid)
                  setInvestor(
                    investor.extra !== null
                      && investor.extra !== ''
                      && JSON.parse(investor.extra).name !== undefined
                      ? JSON.parse(investor.extra).name
                      : investor.name
                  )
                  setAssignInputBox(false)
                }}
                css={{ borderBottom: '1px solid $grey100' }}
              >
                <SmallCompanyLogo
                  src={investor.logoUrlSmall ? investor.logoUrlSmall : investor.logoUrl}
                  onError={(e) => (e.currentTarget.src = require('../assets/images/fallback-img-company-lg.png'))}
                />
                <Paragraph>
                  {investor.extra !== null
                    && investor.extra !== ''
                    && JSON.parse(investor.extra).name !== undefined
                    ? JSON.parse(investor.extra).name
                    : investor.name}
                </Paragraph>
              </Flex>
            )
          })}
        </SearchResult>
      }
    </Div>
  )
}


const SearchResult = styled('div', {
  position: 'absolute',
  top: '100px',
  left: '50px',
  backgroundColor: 'white',
  border: '1px solid $grey100',
  borderRadius: '4px',
  '&>div': {
    padding: '8px 10px',
    '&:hover': {
      backgroundColor: '$grey100',
      cursor: 'pointer'
    }
  }
}
)