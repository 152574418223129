import { gql } from '@apollo/client'

export const CREATE_CUSTOM_LIST = gql`
  mutation createCustomList($name: String!, $description: String) {
    createCustomList(name: $name, description: $description) {
      customList {
        uuid
        name
      }
    }
  }
`



