import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { styled } from '../../styles'
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg'
import { ReactComponent as ChangePasswordIcon } from '../../assets/icons/change-password.svg'
import { Flex } from '../primitives/Flex';
import ClickAwayListener from 'react-click-away-listener';
import { MediumIcon } from '../primitives/IconWrapper';
import client from '../../client';
import { Modal } from '../../modals';
import AddNewInvestor from '../../modals/AddNewInvestor';
import { MobileButtonBurger, MobileButtonStyled } from '../primitives/MobileButton'
import UserFeedback from '../patterns/UserFeedback';
import { Div } from '../primitives/Div';
import { Span } from '../primitives/Span';

export default function Navbar({ authState, setAuthState, setIsLoggedIn }) {

  const [openMenu, setOpenMenu] = useState(false)
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const [userFeedback, setUserFeedback] = useState(false)
  const navigate = useNavigate();

  const userType = localStorage.getItem('usertype')

  const onLogout = () => {
    document.cookie = `signedin=; path=/; domain=${document.domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
    document.cookie = `username=; path=/; domain=${document.domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
    document.cookie = `JWT=; path=/; domain=${document.domain}; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
    setAuthState({
      status: 'loggedout',
      error: null,
      username: null,
      fistName: null,
      lastName: null,
    })
    localStorage.removeItem('username')
    localStorage.removeItem('usertype')
    client.resetStore()
    // const cleanCookieOnServer = async () => {
    //   await api.get(`/logout`, { withCredentials: true })
    // }
    // cleanCookieOnServer()
    navigate('/')
  }

  const currentPage = window.location.pathname

  return (
    <Div data-testid='navbar'>
      {userFeedback && <UserFeedback userFeedback={userFeedback} setUserFeedback={setUserFeedback} />}

      {/* ↓↓↓↓↓↓↓↓less than 1023px↓↓↓↓↓↓↓↓ */}
      <Nav css={{ display: 'none', '@bp3': { display: 'flex' } }} data-testid='mobile-menu' aria-label='mobile-menu'>
        <VcrmLogo href='/'>VCRM</VcrmLogo>
        <MobileButtonStyled onClick={() => setOpenMobileMenu(!openMobileMenu)} data-testid='burger-menu'>
          <MobileButtonBurger
            open={openMobileMenu && 'yes'} />
        </MobileButtonStyled>
      </Nav>
      {openMobileMenu
        ?
        <Div
          css={{ position: 'fixed', background: 'rgba(0, 0, 0, 0.5)', width: '100%', height: '100vh', top: '0', left: '0', zIndex: '200', transitionDuration: '300ms' }}
        >
          <Div css={{ position: 'relative', width: '60%', height: '100vh', marginLeft: 'auto', backgroundColor: 'white', transform: 'translateX(0px)', transitionDuration: '300ms' }}>
            <section style={{ padding: '100px 10px' }}>
              <LinkWrapper>
                <Link href='/search-investors' displayedPage={currentPage.includes('/search-investors')} >Search Investors</Link>
              </LinkWrapper>
              <LinkWrapper>
                <Link href='/my-lists' displayedPage={currentPage.includes('/my-lists')} >My Lists</Link>
              </LinkWrapper>
              <LinkWrapper>
                <Link href='/my-contacts' displayedPage={currentPage.includes('/my-contacts')}>My Contacts</Link>
              </LinkWrapper>
              <LinkWrapper>
                <Link href='/intros' displayedPage={currentPage.includes('/intros')}>Intros</Link>
              </LinkWrapper>
              {(userType === '3' || userType === '4') &&
                <>
                  <LinkWrapper>
                    <Link href='/my-founders' displayedPage={currentPage.includes('/my-founders')}>My Founders</Link>
                  </LinkWrapper>
                  <LinkWrapper css={{ ml: '$12' }}>
                    <Modal
                      triggerButtonLabel={'Add new investor'}
                      triggerButtonStyle={'blue'}
                      title={'Add new investor'}
                      Component={AddNewInvestor}
                      setUserFeedback={setUserFeedback}
                    />
                  </LinkWrapper>
                </>
              }
              <LinkWrapper css={{ ml: '$12', mt: '60px' }}>
                <Flex
                  css={{ fontSize: '$14', color: '$primary', cursor: 'pointer' }}
                  onClick={() => { onLogout(); setIsLoggedIn(false) }}
                >
                  <MediumIcon css={{ mr: '$4' }}>
                    <LogoutIcon />
                  </MediumIcon>
                  Logout
                </Flex>

                <Link href="/change-password" style={{ textDecoration: 'none' }}>
                  <Flex>
                    <MediumIcon css={{ mr: '$6', ml: '-2px' }}>
                      <ChangePasswordIcon />
                    </MediumIcon>
                    Change password
                  </Flex>
                </Link>
              </LinkWrapper>
            </section>
          </Div>
        </Div>
        :
        <Div css={{ position: 'fixed', background: 'transparent', width: '100%', height: '100vh', top: '0', left: '0', zIndex: '-100', transitionDuration: '300ms' }}>
          <Div css={{ position: 'relative', width: '60%', height: '100vh', marginLeft: 'auto', backgroundColor: 'white', transform: 'translateX(100%)', transitionDuration: '300ms' }}>
          </Div>
        </Div>
      }



      {/* ↓↓↓↓↓↓↓↓more than 1024px↓↓↓↓↓↓↓↓ */}
      <Nav css={{ '@bp3': { display: 'none' } }} data-testid='pc-menu' aria-label='pc-menu'>
        <Flex>
          <VcrmLogo href='/'>VCRM</VcrmLogo>
          <Link href='/search-investors' displayedPage={currentPage.includes('/search-investors')} >Search Investors</Link>
          <Link href='/my-lists' displayedPage={currentPage.includes('/my-lists')} >My Lists</Link>
          <Link href='/my-contacts' displayedPage={currentPage.includes('/my-contacts')}>My Contacts</Link>
          <Link href='/intros' displayedPage={currentPage.includes('/intros')}>Intros</Link>
          {(userType === '3' || userType === '4') && <Link href='/my-founders' displayedPage={currentPage.includes('/my-founders')}>My Founders</Link>}
        </Flex>

        <Flex>
          {(userType === '3' || userType === '4') &&
            <Modal
              triggerButtonLabel={'Add\u00A0new\u00A0investor'}
              triggerButtonStyle={'blue'}
              title={'Add new investor'}
              Component={AddNewInvestor}
              setUserFeedback={setUserFeedback}
            />
          }

          <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
            <Div
              onClick={() => setOpenMenu(!openMenu)}
              css={{
                color: '$primary', margin: '$0 $14', fontSize: '$14',
                d: 'flex', ai: 'center', cursor: 'pointer', position: 'relative'
              }}
            >

              {`${authState.firstName} ${authState.lastName}`}
              <MediumIcon>
                <UserIcon />
              </MediumIcon>

              <Menu openMenu={openMenu}>
                <a href="/change-password" style={{ textDecoration: 'none' }}>
                  <MenuButton>
                    <MediumIcon css={{ mr: '$8' }}>
                      <ChangePasswordIcon />
                    </MediumIcon>
                    Change password
                  </MenuButton>
                </a>

                <MenuButton onClick={() => { onLogout(); setIsLoggedIn(false) }}>
                  <MediumIcon css={{ mr: '$8' }}>
                    <LogoutIcon />
                  </MediumIcon>
                  Logout
                </MenuButton>

              </Menu>
            </Div>
          </ClickAwayListener>

        </Flex>
      </Nav>

    </Div>
  )
}

const Nav = styled('nav', {
  borderBottom: '1px solid $grey100',
  padding: '6px 12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
})

const VcrmLogo = styled('a', {
  color: '$primary',
  fontWeight: '400',
  marginLeft: '$12',
  marginRight: '$24',
  marginTop: '-3px',
  textDecoration: 'none',
  cursor: 'pointer',
  fontSize: '30px',
  '&:hover': {
    color: '$primary'
  }
})

const Link = styled('a', {
  color: '$primary',
  textDecoration: 'none',
  margin: '$0 $14',
  fontSize: '$14',
  '&:hover': {
    color: '$primary',
  },
  variants: {
    displayedPage: {
      true: {
        fontWeight: '900',
      },
      false: {
        fontWeight: '400',
      }
    }
  }
})

const Menu = styled('div', {
  variants: {
    openMenu: {
      true: {
        position: 'absolute',
        right: '-2px',
        top: '36px',
        backgroundColor: 'white',
        boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
        borderRadius: '4px',
        minWidth: 'max-content',
        padding: '18px 16px',
        border: '1px solid $grey100',
        zIndex: '400',
      },
      false: {
        display: 'none'
      }
    }
  },
  '& button': {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }
})

const MenuButton = styled('button', {
  width: '100%',
  backgroundColor: 'transparent',
  border: 'none',
  color: '$grey300',
  padding: '12px 36px',
  borderRadius: '900px',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: '$primaryLight',
    color: '$primary',
  }
})

const LinkWrapper = styled('div', {
  marginTop: '20px',
})

