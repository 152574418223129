export const formatMaxRoundSize = (max) => {
  if (max === 0) {
    return 0
  }
  if (1 <= max && max <= 50000) {
    return 50000
  }
  if (50001 <= max && max <= 100000) {
    return 100000
  }
  if (100001 <= max && max <= 250000) {
    return 250000
  }
  if (250001 <= max && max <= 500000) {
    return 500000
  }
  if (500001 <= max && max <= 1000000) {
    return 1000000
  }
  if (1000001 <= max && max <= 2000000) {
    return 2000000
  }
  if (2000001 <= max && max <= 5000000) {
    return 5000000
  }
  if (5000001 <= max && max <= 10000000) {
    return 10000000
  }
  if (10000001 <= max && max <= 15000000) {
    return 15000000
  }
  if (15000001 <= max && max <= 20000000) {
    return 20000000
  }
  if (20000001 <= max && max <= 30000000) {
    return 30000000
  }
  if (30000001 <= max && max <= 40000000) {
    return 40000000
  }
  if (40000001 <= max && max <= 50000000) {
    return 50000000
  }
  if (50000001 <= max) {
    return 50000001
  }
}


