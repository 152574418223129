import { gql, useQuery } from '@apollo/client'

export const GET_INVESTOR = gql`
query investor ($uuid: String!) {
  investor(uuid: $uuid) {
    nautaCoInvestor
    uuid
    name
    shortDescription
    permalink
    cbUrl
    logoUrl
    logoUrlSmall
    twitterUrl
    homepageUrl
    linkedinUrl
    city
    countryCode
    foundedOn
    leadInvestmentsMedian
    fundingRoundPreSeed
    fundingRoundSeed
    fundingRoundSeriesA
    fundingRoundSeriesB
    fundingRoundSeriesC

    extra
    extraLog

    address 
    email
    phone
    status
    employeeCount
    description

    leadInvestmentsMin
    leadInvestmentsMax
    investorTypes 
    portfolioCountryCodes
    portfolioCategories
    
    comments {
      uuid
      investorUuid {
        name
      }
      addedBy {
        id
        username
        firstName
        lastName
      }
      createdAt
      commentText
    }

    contacts{
      uuid
      name
      email
      comment
      investor {
        uuid
        name
      }
    }

    showComments
    
    investments {
      uuid
      name
      logoUrl
      logoUrlSmall
      homepageUrl
    }
  }
  }
`

export const useInvestor = (uuid) => {
  const { data, error, loading } = useQuery(GET_INVESTOR, {
    variables: {
      uuid
    }
  })

  return {
    data,
    error,
    loading
  }
}