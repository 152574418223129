import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Cookies from 'js-cookie'

const Protected = ({ props, children }) => {
  const navigate= useNavigate()

  useEffect(()=> {
    if (!Cookies.get('signedin')) {
      return navigate("/")
    }
  })
  return children;
};

export default Protected