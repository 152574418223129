import { styled } from "../../styles"

export const Tab = styled('button', {
  background: 'transparent',
  border: 'none',
  color: '#9d9d9d',
  borderBottom: '2px solid #f9f2f5',
  padding: '10px 24px',
  '@bp1': {
    padding: '6px 10px',
    fontSize: '14px',
  },
  '@bp0': {
    padding: '4px'
  },
  variants: {
    displayedTab: {
      true: {
        color: '#8c003d',
        fontWeight: '500',
        borderBottom: '2px solid #8c003d',
      },
    }
  }
})

export const TabWrapper = styled('div', {
  '@bp2': {
    marginBottom: '$8'
  }
})