import { gql, useQuery } from '@apollo/client'

export const GET_INVESTOR_CONTACTS = gql`
query investorContacts ($investorUuid: String!) {
  investorContacts (investorUuid: $investorUuid) {
    uuid
    name
    email
    comment
    contactOwnerName
    contactOwnerOrganization
    contactName 
    contactOwnerOrganization
    contactPointId
    contactPointName
    investor {
      uuid
      name
    }
    ownContact
    nautaUser
    contactOwnerNautaUser
  }
}
`

export const useInvestorContacts = (uuid) => {
  const {data, error, loading} = useQuery(GET_INVESTOR_CONTACTS, {
    variables: {
      investorUuid: uuid
    }
  })

  return {data, error, loading}
}