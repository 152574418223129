import { gql } from '@apollo/client'

export const DELETE_COMMENT = gql`
mutation deleteComment (
  $commentUuid: String!,
  $investorUuid: String!
) {
  deleteComment (
    commentUuid: $commentUuid,
    investorUuid: $investorUuid
  ) {
    investorComment {
      uuid
    }
  }
}
`