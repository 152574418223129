import React, { useState, useRef } from 'react'
import { DELETE_COMMENT } from '../mutations/deleteComment'
import { useMutation } from '@apollo/client'
import { PrimaryButton, SecondaryButton, ButtonsWrapper } from '../components/primitives/Button'
import { Header2 } from '../components/primitives/Text'
import { GET_INVESTOR } from '../queries/useInvestor'
import { Div } from '../components/primitives/Div'

export default function DeleteComment({ close, investorUuid, commentUuid, setUserFeedback }) {

  const urlParams = new URLSearchParams(window.location.search)
  const uuid = urlParams.get('uuid')

  const deleteButtonRef = useRef(null)

  const [deleteComment] = useMutation(DELETE_COMMENT, {
    variables: {
      commentUuid: commentUuid,
      investorUuid: investorUuid
    },
    refetchQueries: [
      { query: GET_INVESTOR, variables: { uuid } }
    ],
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: () => {
      close()
      setUserFeedback({ success: true, text: 'Comment deleted' })
    }
  })


  return (
    <Div
      onKeyPress={(event) => {
        const { key } = event;
        if (key === "Enter") {
          deleteButtonRef.current.click();
        }
      }}>
      <form>
        <Header2 css={{ mt: '$16' }}>Are you sure you want to delete this comment?</Header2>
        <ButtonsWrapper css={{ mt: '$32' }}>
          <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
          <PrimaryButton
            type='button'
            onClick={() => deleteComment()}
            ref={deleteButtonRef}
          >Delete</PrimaryButton>
        </ButtonsWrapper>
      </form>
    </Div>
  )
}
