import { gql } from "@apollo/client";

export const ADD_CONTACT = gql`
mutation addContact ($name: String!, $email: String!, $comment: String) {
  addContact(name: $name, email: $email, comment: $comment) {
    contact {
      uuid
    }
  }
}
`


