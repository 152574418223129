import { useEffect, useState, useRef } from 'react'
import { RoundSizeOptions } from '../../../options/RoundSizeOptions'
import { CountryOptions } from '../../../options/CountryOptions'
import { CategoryOptions } from '../../../options/CategoryOptions'
import { InvestorTypeOptions } from '../../../options/InvestorTypeOptions'
import { InvestmentCountOptions } from '../../../options/InvestmentCountOptions'
import { RoundTypesOptions } from '../../../options/RoundTypesOptions'
import { HasContactOptions } from '../../../options/HasContactOptions'
import { DropdownBox } from '../../../components/patterns/Dropdown'
import { MultiSelect } from 'react-multi-select-component'
import { Box } from '../../../components/primitives/Box'
import { Header2, Header4, FilterTitle } from '../../../components/primitives/Text'
import { PrimaryButton, ClearButton } from '../../../components/primitives/Button'
import { Input } from '../../../components/primitives/Input'
import { FlexSpaceBetween } from '../../../components/primitives/FlexSpaceBetween'
import { useLazyQuery } from '@apollo/client'
import { GET_ORGANIZATIONS } from '../../../queries/useOrganizations'
import { Div } from '../../../components/primitives/Div'
import { Loader } from '../../../components/primitives/Loader'
import { Flex } from '../../../components/primitives/Flex'
import { Paragraph } from '../../../components/primitives/Text'
import { SmallCompanyLogo } from '../../../components/primitives/CompanyLogo'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { SmallIcon } from '../../../components/primitives/IconWrapper'
import { styled } from '../../../styles'
import { Span } from '../../../components/primitives/Span'

export default function Filters({
  setName,
  setInvestmentUuids,
  setLowerBound,
  setUpperBound,
  setCountryCodes,
  setPortfolioCountryCodes,
  setPortfolioCategories,
  setInvestorTypes,
  setInvestmentCount,
  setFundingRounds,
  setHasContact,
  windowSize,
  openFilterButton,
  setOpenFilterButton,
  lowerBound,
  upperBound,
  countryCodes,
  portfolioCountryCodes,
  portfolioCategories,
  investorTypes,
  investmentCount,
  fundingRounds,
  hasContact,
  name,
}) {

  const [organizationName, setOrganizationName] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useState([])
  // console.log(selectedOrganizations)

  const [getOrganizations, { error, data, loading }] = useLazyQuery(GET_ORGANIZATIONS, {
    variables: {
      searchbox: organizationName
    }
  })

  useEffect(() => {
    let temp = []
    selectedOrganizations.forEach((org) => {
      temp.push(org.uuid)
    })
    setInvestmentUuids(temp)
  }, [selectedOrganizations, setInvestmentUuids])


  return (
    <>
      {openFilterButton || windowSize > 1024
        ?
        <Box
          css={{ fb: '18%', mr: '$16', mb: '$24', mw: '0', p: '$14', '@bp3': { mr: '$0' } }}
          data-testid='searchinvestors-filters-bar'
        >
          <FlexSpaceBetween>
            <Header2>Filters</Header2>
            <ClearButton onClick={() => {
              setName('')
              setLowerBound(null)
              setUpperBound(null)
              setCountryCodes([])
              setPortfolioCountryCodes([])
              setPortfolioCategories([])
              setInvestorTypes([])
              setInvestmentCount(null)
              setFundingRounds([])
              setHasContact('')
              setSelectedOrganizations([])
              setOrganizationName('')
            }}>
              Clear
            </ClearButton>
          </FlexSpaceBetween>

          <FilterTitle>Firm Name</FilterTitle>
          <div>
            <Input
              autoFocus='autofocus'
              type='text'
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              css={{ py: '$2', fontSize: '$14' }}
              placeholder='Search...'
            />
          </div>

          <FilterTitle>Round Size (Lower bound)</FilterTitle>
          <DropdownBox
            options={RoundSizeOptions}
            item={lowerBound}
            setItem={setLowerBound}
            nameForTest={'lower-bound'}
          />

          <FilterTitle>Round Size (Upper bound)</FilterTitle>
          <DropdownBox
            options={RoundSizeOptions}
            item={upperBound}
            setItem={setUpperBound}
            nameForTest={'higher-bound'}
          />

          <FilterTitle>Investor Location</FilterTitle>
          <MultiSelect
            options={CountryOptions}
            value={countryCodes}
            onChange={setCountryCodes}
          />

          <FilterTitle>Portfolio Location</FilterTitle>
          <MultiSelect
            options={CountryOptions}
            value={portfolioCountryCodes}
            onChange={setPortfolioCountryCodes}
          />

          <FilterTitle>Portfolio Categories</FilterTitle>
          <MultiSelect
            options={CategoryOptions}
            value={portfolioCategories}
            onChange={setPortfolioCategories}
          />

          {/* <FilterTitle>Investor Type</FilterTitle>
          <DropdownBox
            options={InvestorTypeOptions}
            item={investorType}
            setItem={setInvestorType}
          /> */}
          <FilterTitle>Investor Types</FilterTitle>
          <MultiSelect
            options={InvestorTypeOptions}
            value={investorTypes}
            onChange={setInvestorTypes}
          />

          <FilterTitle>Invest Count</FilterTitle>
          <DropdownBox
            options={InvestmentCountOptions}
            item={investmentCount}
            setItem={setInvestmentCount}
          />

          <FilterTitle>Round Types</FilterTitle>
          <MultiSelect
            options={RoundTypesOptions}
            value={fundingRounds}
            onChange={setFundingRounds}
          />

          <FilterTitle>Has Contact</FilterTitle>
          <DropdownBox
            options={HasContactOptions}
            item={hasContact}
            setItem={setHasContact}
          />

          <FilterTitle>Has invested in...</FilterTitle>
          <Flex css={{ flexWrap: 'wrap' }}>
            {selectedOrganizations.map((org) => {
              return (
                <Flex
                  css={{ bgc: '$grey100', w: 'fit-content', mr: '$4', mb: '$4' }}
                  key={org.uuid}
                >
                  <Paragraph css={{ fontSize: '$11', ml: '$4' }}>{org.name}</Paragraph>
                  <SmallIcon
                    css={{ w: '$10', mx: '$4', mb: '$2' }}
                    onClick={() =>
                      setSelectedOrganizations(selectedOrganizations.filter(selectedOrg => selectedOrg.uuid !== org.uuid))
                    }
                  >
                    <CloseIcon />
                  </SmallIcon>
                </Flex>
              )
            })}
          </Flex>

          <Div css={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
            <Input
              type='text'
              value={organizationName}
              onChange={(e) => {
                setOrganizationName(e.target.value)
                getOrganizations()
              }}
              css={{ py: '$2', fontSize: '$14', flex: 1 }}
              placeholder='Search...'
            />
            {organizationName.length >= 1 &&
              <Span
                onClick={() => setOrganizationName('')}
                css={{ position: 'absolute', right: 10, color: '#aaa', w: '$24', cursor: 'pointer' }}
              >
                <CloseIcon />
              </Span>
            }
          </Div>

          <Div css={{ borderRight: '1px solid $grey100', borderLeft: '1px solid $grey100' }}>
            {loading &&
              <Div css={{ borderBottom: '1px solid $grey100', borderTop: '1px solid $grey100' }}>
                <Loader size='20' css={{ m: '$6' }} />
              </Div>
            }
            {data &&
              data.searchOrganization.objects.map((obj) => {
                return (
                  <Flex
                    key={obj.uuid}
                    onClick={() => {
                      selectedOrganizations.findIndex(selectedOrg => selectedOrg.uuid === obj.uuid) === -1
                        && setSelectedOrganizations(selectedOrganizations => [...selectedOrganizations, { name: obj.name, uuid: obj.uuid }])
                    }}
                    css={{ py: '$4', borderBottom: '1px solid $grey100', '&:hover': { cursor: 'pointer', bgc: '$grey100' } }}
                  >
                    <SmallCompanyLogo
                      src={obj.logoUrlSmall ? obj.logoUrlSmall : obj.logoUrl}
                      css={{ml: '$16' }}
                      onError={(e) => (e.currentTarget.src = require('../../../assets/images/fallback-img-company-lg.png'))}
                    />
                    <Paragraph>{obj.name}</Paragraph>
                  </Flex>
                )
              })
            }
            {data &&
              organizationName.length !== 0 &&
              data.searchOrganization.objects.length === 0 &&
              <Paragraph css={{ borderBottom: '1px solid $grey100', padding: '$4' }}>No results</Paragraph>
            }
            {error &&
              <Paragraph css={{ borderBottom: '1px solid $grey100', p: '$4' }}>An error occurred. Please try again or report this bug.</Paragraph>
            }
          </Div>

          <PrimaryButton
            onClick={() => setOpenFilterButton(false)}
            css={{ display: 'none', '@bp3': { display: 'block', mt: '$20', w: '100%' } }}
          >
            View results
          </PrimaryButton>

        </Box>
        :
        <></>
      }
    </>
  )
}

