export const formatInvestorType = (investorType) => {
  if (investorType === "{venture_capital}" || investorType === "venture_capital") {
    return "Venture Capital"
  }
  if (investorType === "{corporate_venture_capital}" || investorType === "corporate_venture_capital") {
    return "Corporate Venture Capital"
  }
  if (investorType === "{family_investment_office}" || investorType === "family_investment_office") {
    return "Family Investment Office"
  }
  if (investorType === "{micro_vc}" || investorType === "micro_vc") {
    return "Micro VC"
  }
  if (investorType === "{private_equity_firm}" || investorType === "private_equity_firm") {
    return "Private Equity Firm"
  }
  if (investorType === "{venture_debt}" || investorType === "venture_debt") {
    return "Venture Debt"
  }
}

