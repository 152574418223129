import { useEffect, useState, useMemo } from 'react'
import { useTable, usePagination, useFilters } from 'react-table'
import { Table, TableHeader, TableData } from '../../../components/primitives/Table'
// import { CheckBox } from '../../../components/primitives/Input'
import { FlexSpaceBetween } from '../../../components/primitives/FlexSpaceBetween'
import ContactRow from './ContactRow'
// import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg'
// import { Flex } from '../../../components/primitives/Flex'
// import { IconInsideButton } from '../../../components/primitives/IconWrapper'
// import { Modal } from '../../../modals'
import DeleteContact from '../../../modals/DeleteContact'
import { GET_ASSIGNED_CONTACT } from '../../../queries/useAssignedContacts'
import { GET_NOT_ASSIGNED_CONTACT } from '../../../queries/useNotAssignedContacts'
import { GET_PENDING_CONTACT } from '../../../queries/usePendingContacts'
import { useLazyQuery, useQuery } from '@apollo/client'
import Pagination from '../../../components/patterns/Pagination'
import { Tab, TabWrapper } from '../../../components/primitives/Tab'
import { Div } from '../../../components/primitives/Div'
import { FilterInput } from '../../../components/primitives/Input'
import DefaultColumnFilter from '../../../components/table/filters/DefaultColumnFilter'
import {filterTypes} from '../../../components/table/filters/filterTypes'

export default function Tabs({ setUserFeedback }) {

  const [displayedTab, setDisplayedTab] = useState('assigned')
  // const [checked, setChecked] = useState([])
  // const [allChecked, setAllChecked] = useState(false)
  const [assignedPreData, setAssignedPreData] = useState([])
  const [notAssignedPreData, setNotAssignedPreData] = useState([])
  const [pendingPreData, setPendingPreData] = useState([])
  const [assignedContactsCount, setAssignedContactsCount] = useState(0)
  const [notAssignedContactsCount, setNotAssignedContactsCount] = useState(0)
  const [pendingContactsCount, setPendingContactsCount] = useState(0)

  //-------------------------------Assigned Data-------------------------------
  const { data: first25AssignedData } = useQuery(GET_ASSIGNED_CONTACT, {
    variables: { page: 1, pageSize: 25, }
  })
  const [getAllAssignedData, { data: allAssignedData, loading: allAssignedDataLoading }] = useLazyQuery(GET_ASSIGNED_CONTACT, {
    variables: { page: 1, pageSize: 9999, }
  })

  useEffect(() => {
    setAssignedContactsCount(first25AssignedData?.assignedContacts.totalCount)
    if (first25AssignedData?.assignedContacts.objects) {
      setAssignedPreData(first25AssignedData?.assignedContacts.objects.map((obj) => {
        return dataWithAccessor(obj)
      }))
      if (first25AssignedData.assignedContacts.totalCount > 25) getAllAssignedData()
    }
    if (allAssignedData) {
      setAssignedPreData(allAssignedData?.assignedContacts.objects.map((obj) => {
        return (dataWithAccessor(obj))
      }))
    }
  }, [first25AssignedData, allAssignedData, getAllAssignedData])

  //-------------------------------NOT Assigned Data-------------------------------
  const { data: first25NotAssignedData } = useQuery(GET_NOT_ASSIGNED_CONTACT, {
    variables: { page: 1, pageSize: 25 }
  })
  const [getAllNotAssignedData, { data: allNotAssignedData, loading: allNotAssignedDataLoading }] = useLazyQuery(GET_NOT_ASSIGNED_CONTACT, {
    variables: { page: 1, pageSize: 9999 }
  })
  // console.log({allNotAssignedData})

  useEffect(() => {
    setNotAssignedContactsCount(first25NotAssignedData?.notAssignedContacts.totalCount)
    if (first25NotAssignedData?.notAssignedContacts.objects) {
      setNotAssignedPreData(first25NotAssignedData?.notAssignedContacts.objects.map((obj) => {
        return (dataWithAccessor(obj))
      }))
      if (first25NotAssignedData?.notAssignedContacts.totalCount > 25) getAllNotAssignedData()
    }
    if (allNotAssignedData) {
      setNotAssignedPreData(allNotAssignedData?.notAssignedContacts.objects.map((obj) => {
        return (dataWithAccessor(obj))
      }))
    }
  }, [first25NotAssignedData, allNotAssignedData, getAllNotAssignedData])

  //-------------------------------Pending Data----------------------------------------
  const { data: first25PendingData } = useQuery(GET_PENDING_CONTACT, {
    variables: { page: 1, pageSize: 25 }
  })
  const [getAllPendingData, { data: allPendingData, loading: allPendingDataLoading }] = useLazyQuery(GET_PENDING_CONTACT, {
    variables: { page: 1, pageSize: 9999 }
  })
  // console.log(first25PendingData?.notApprovedContacts.objects)

  useEffect(() => {
    setPendingContactsCount(first25PendingData?.notApprovedContacts.totalCount)
    if (first25PendingData?.notApprovedContacts.objects) {
      setPendingPreData(first25PendingData?.notApprovedContacts.objects.map((obj) => {
        return (dataWithAccessor(obj))
      }))
      if (first25PendingData?.notApprovedContacts.totalCount > 25) getAllPendingData()
    }
    if (allPendingData) {
      setPendingPreData(allPendingData?.notApprovedContacts.objects.map((obj) => {
        return (dataWithAccessor(obj))
      }))
    }
  }, [first25PendingData, allPendingData, getAllPendingData])

  //-------------------------------For rendering react-table-------------------------------
  
  const columns = useMemo(() => [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Investor', accessor: 'investorForFiltering' },
    { Header: 'Comment', accessor: 'comment' },
  ], [])

  const dataWithAccessor = (contact) => ({
    uuid: contact.uuid,
    name: contact.name,
    email: contact.email,
    investorForFiltering: contact.investor && contact.investor.name,
    investor: contact.investor === undefined || contact.investor === null
      ? undefined
      : {
        uuid: contact.investor.uuid,
        name: contact.investor.name,
        logoUrl: contact.investor.logoUrlSmall ? contact.investor.logoUrlSmall : contact.investor.logoUrl,
        permalink: contact.investor.permalink,
        extra: contact.investor.extra,
        homepageUrl: contact.investor.homepageUrl,
        cbUrl: contact.investor.cbUrl,
        linkedinUrl: contact.investor.linkedinUrl,
        twitterUrl: contact.investor.twitterUrl,
      },
    comment: contact.comment,
  })


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    prepareRow,
    state: { pageIndex }
  } = useTable({
    autoResetFilters: false,
    autoResetPage: false,
    autoResetSortBy: false,
    columns,
    data:
      displayedTab === 'assigned'
        ? assignedPreData
        : displayedTab === 'notAssigned'
          ? notAssignedPreData
          : displayedTab === 'pending'
          && pendingPreData,
    filterTypes,
    defaultColumn: useMemo(() => ({
      Filter: DefaultColumnFilter,
    }), []),
    initialState: {
      pageIndex: 0,
      pageSize: 25,
    },
    getSubRows: (row) => row.subRows
  },
    useFilters,
    usePagination,
  )

  const updateDataAfterUnassign = (rowIndex, value) => {
    assignedPreData.splice(rowIndex, 1)
    setAssignedPreData(assignedPreData)
    setAssignedContactsCount(assignedPreData.length)
    notAssignedPreData.push(value)
    notAssignedPreData.sort((a, b) => a.name.localeCompare(b.name))
    setNotAssignedPreData(notAssignedPreData)
    setNotAssignedContactsCount(notAssignedPreData.length)
  }

  const updateDataAfterAssign = (rowIndex, value) => {
    notAssignedPreData.splice(rowIndex, 1)
    setNotAssignedPreData(notAssignedPreData)
    setNotAssignedContactsCount(notAssignedPreData.length)
    assignedPreData.push(value)
    assignedPreData.sort((a, b) => a.name.localeCompare(b.name))
    setAssignedPreData(assignedPreData)
    setAssignedContactsCount(assignedPreData.length)
  }

  const updateDataAfterEdit = (rowIndex, value) => {
    let temp = null
    displayedTab === 'assigned'
      ? temp = setAssignedPreData
      : displayedTab === 'notAssigned'
        ? temp = setNotAssignedPreData
        : displayedTab === 'pending'
          ? temp = setPendingPreData
          : temp = null
    temp((old) => (
      old.map((row, index) => {
        if (index === rowIndex) {
          return { ...old[rowIndex], name: value.name, email: value.email, comment: value.comment }
        }
        return row
      })
    ))
  }

  const updateDataAfterApprove = (rowIndex, value) => {
    pendingPreData.splice(rowIndex, 1)
    setPendingPreData(pendingPreData)
    setPendingContactsCount(pendingPreData.length)
    if (value.investor === undefined || value.investor === null) {
      notAssignedPreData.push(value)
      notAssignedPreData.sort((a, b) => a.name.localeCompare(b.name))
      setNotAssignedPreData(notAssignedPreData)
      setNotAssignedContactsCount(notAssignedPreData.length)
    } else if (value.investor) {
      assignedPreData.push(value)
      assignedPreData.sort((a, b) => a.name.localeCompare(b.name))
      setAssignedPreData(assignedPreData)
      setAssignedContactsCount(assignedPreData.length)
    }
  }

  const updatePendingData = (rowIndex, columnId, value) => {
    setPendingPreData((old) => (
      old.map((row, index) => {
        if (index === rowIndex) {
          return { ...old[rowIndex], [columnId]: value }
        }
        return row;
      })
    ))
  }

  const updateDataAfterDelete = (contactUuid) => {
    let assigned = assignedPreData
    let notAssigned = notAssignedPreData
    let pending = pendingPreData
    contactUuid.forEach((contact) => {
      assigned = assigned.filter(data => data.uuid !== contact)
      notAssigned = notAssigned.filter(data => data.uuid !== contact)
      pending = pending.filter(data => data.uuid !== contact)
    })
    setAssignedPreData(assigned)
    setNotAssignedPreData(notAssigned)
    setPendingPreData(pending)
    setAssignedContactsCount(assigned.length)
    setNotAssignedContactsCount(notAssigned.length)
    setPendingContactsCount(pending.length)
  }

  //-------------------------------Handling check boxes-------------------------------

  // const handleCheck = (event) => {
  //   var updatedList = [...checked];
  //   if (event.target.checked) {
  //     updatedList = [...checked, event.target.value];
  //   } else {
  //     updatedList.splice(checked.indexOf(event.target.value), 1);
  //   }
  //   setChecked(updatedList);
  // }

  // const handleCheckAll = () => {
  //   setAllChecked(!allChecked)
  //   if (displayedTab === 'assigned') {
  //     setChecked(assignedPreData.slice(pageIndex * 25, (pageIndex * 25) + 25).map((contact) => {
  //       return contact.uuid
  //     }))
  //   } if (displayedTab === 'notAssigned') {
  //     setChecked(notAssignedPreData.slice(pageIndex * 25, (pageIndex * 25) + 25).map((contact) => {
  //       return contact.uuid
  //     }))
  //   } else if (displayedTab === 'pending') {
  //     setChecked(pendingPreData.slice(pageIndex * 25, (pageIndex * 25) + 25).map((contact) => {
  //       return contact.uuid
  //     }))
  //   }
  //   if (allChecked) {
  //     setChecked([])
  //   }
  // }

  ////////////////////////////////////////////////////////////
  return (
    <>
      <FlexSpaceBetween css={{ mb: '$24', '@bp2': { display: 'block' } }}>
        <TabWrapper>
          <Tab
            onClick={() => {
              setDisplayedTab('assigned')
              gotoPage(0)
            }}
            displayedTab={displayedTab === 'assigned'}>
            Assigned ({assignedContactsCount})
          </Tab>
          <Tab
            onClick={() => {
              setDisplayedTab('notAssigned')
              gotoPage(0)
            }}
            displayedTab={displayedTab === 'notAssigned'}>
            Not Assigned ({notAssignedContactsCount})
          </Tab>
          <Tab
            onClick={() => {
              setDisplayedTab('pending')
              gotoPage(0)
            }}
            displayedTab={displayedTab === 'pending'}>
            Pending ({pendingContactsCount})
          </Tab>
        </TabWrapper>
        {/* <Modal
          triggerButtonLabel={(
            <Flex>
              Delete&nbsp;selected&nbsp;contacts
              <IconInsideButton><DeleteIcon /></IconInsideButton>
            </Flex>
          )}
          Component={DeleteContact}
          contactUuid={checked}
          setContactUuid={setChecked}
          setAllChecked={setAllChecked}
          setUserFeedback={setUserFeedback}
          updateDataAfterDelete={updateDataAfterDelete}
        /> */}
      </FlexSpaceBetween>

      <Div css={{ overflowX: 'scroll' }}>
        <Table {...getTableProps()} css={{ minWidth: '1010px' }}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {/* <TableData>
                  <CheckBox
                    type='checkbox'
                    onChange={handleCheckAll}
                    checked={allChecked}
                  />
                </TableData> */}
                {headerGroup.headers.map(column => (
                  <TableHeader {...column.getHeaderProps()}
                    css={column.Header === 'Comment' && { w: '30%' }}
                  >

                    {
                      !(displayedTab === 'notAssigned' && column.Header === 'Investor')
                      && column.render('Header')
                    }

                    <FilterInput>{column.Header === 'Name' ? column.render('Filter') : null}</FilterInput>
                    <FilterInput>{column.Header === 'Email' ? column.render('Filter') : null}</FilterInput>
                    <FilterInput>{column.Header === 'Investor' && displayedTab !== 'notAssigned' ? column.render('Filter') : null}</FilterInput>

                  </TableHeader>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row)
              return (
                <ContactRow
                  // handleCheck={handleCheck}
                  displayedTab={displayedTab}
                  // checked={checked}
                  key={row.original.uuid} //this key is super important for updating datas, do not delete it!
                  row={row}
                  setUserFeedback={setUserFeedback}
                  updateDataAfterUnassign={updateDataAfterUnassign}
                  updateDataAfterAssign={updateDataAfterAssign}
                  updatePendingData={updatePendingData}
                  updateDataAfterApprove={updateDataAfterApprove}
                  updateDataAfterDelete={updateDataAfterDelete}
                  updateDataAfterEdit={updateDataAfterEdit}
                />
              )
            })}
          </tbody>
        </Table>
      </Div>

      <Pagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        allDataLoading={
          displayedTab === 'assigned'
            ? allAssignedDataLoading
            : displayedTab === 'notAssigned'
              ? allNotAssignedDataLoading
              : displayedTab === 'pending'
              && allPendingDataLoading
        }
      />
    </>
  )
}

