import { gql } from '@apollo/client'

export const RESEND_INVITATION = gql`
mutation reSendInvitation (
  $uuid: String!
) {
  reSendInvitation (
    uuid: $uuid
  ) {
    invitation {
      uuid
      name
      email
      organization
    }
  }
}
`