import { useState, useEffect } from 'react';
import { PrimaryButton, SecondaryButton, ButtonsWrapper } from '../components/primitives/Button';
import { Header4, RequiredText } from '../components/primitives/Text'
import { Input, TextArea } from '../components/primitives/Input'
import { ADD_CONTACT } from '../mutations/addContact';
import { useMutation } from '@apollo/client';
import { RequiredMark } from '../components/primitives/RequiredMark';
import { GET_CONTACTS } from '../queries/useContact';
import { GET_ASSIGNED_CONTACT } from '../queries/useAssignedContacts';
import { GET_NOT_ASSIGNED_CONTACT } from '../queries/useNotAssignedContacts';

export default function AddNewContact({ close, setUserFeedback }) {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [comment, setComment] = useState('')
  const [nameFieldIsEmpty, setNameFieldIsEmpty] = useState(false)
  const [emailFieldIsEmpty, setEmailFieldIsEmpty] = useState(false)

  const handleCheck = () => {
    if (name.trim().length === 0 && email.trim().length === 0) {
      setNameFieldIsEmpty(true)
      setEmailFieldIsEmpty(true)
    } else if (name.trim().length === 0) {
      setNameFieldIsEmpty(true)
    } else if (email.trim().length === 0) {
      setEmailFieldIsEmpty(true)
    }
    else {
      addContact()
    }
  }

  const [addContact] = useMutation(ADD_CONTACT, {
    variables: {
      name,
      email,
      comment
    },
    refetchQueries: [
      { query: GET_CONTACTS },
      { query: GET_ASSIGNED_CONTACT, variables: { page: 1, pageSize: 25 } },
      { query: GET_ASSIGNED_CONTACT, variables: { page: 1, pageSize: 9999 } },
      { query: GET_NOT_ASSIGNED_CONTACT, variables: { page: 1, pageSize: 25 } },
      { query: GET_NOT_ASSIGNED_CONTACT, variables: { page: 1, pageSize: 9999 } },
    ],
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: () => {
      close()
      setUserFeedback({ success: true, text: 'New contact added' })
    }
  })

  useEffect(() => {
    setNameFieldIsEmpty(false)
  }, [name])

  useEffect(() => {
    setEmailFieldIsEmpty(false)
  }, [email])

  return (
    <>
      <form>
        <Header4 isRequired={nameFieldIsEmpty}>
          Name
          <RequiredMark/>
        </Header4>
        <Input
          onChange={(e) => setName(e.target.value)}
          isRequired={nameFieldIsEmpty}
        />
        {nameFieldIsEmpty && <RequiredText>Required</RequiredText>}

        <Header4 isRequired={emailFieldIsEmpty}>
          Email
          <RequiredMark/>
        </Header4>
        <Input
          onChange={(e) => setEmail(e.target.value)}
          isRequired={emailFieldIsEmpty}
        />
        {emailFieldIsEmpty && <RequiredText>Required</RequiredText>}

        <Header4>Comment</Header4>
        <TextArea
          rows='4'
          onChange={(e) => setComment(e.target.value)}
        />
        <ButtonsWrapper css={{ mt: '$24' }}>
          <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
          <PrimaryButton
            type='button'
            onClick={() => handleCheck()}
          >Add</PrimaryButton>
        </ButtonsWrapper>
      </form>
    </>
  )
}
