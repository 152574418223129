import { styled } from "../../styles";
import { ReactComponent as CloseButton } from '../../assets/icons/close.svg'
import { ReactComponent as CheckIcon } from '../../assets/icons/check-circle.svg'
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg'
import { SmallIcon } from '../primitives/IconWrapper'
import { Flex } from "../primitives/Flex";
import { Div } from '../primitives/Div'

export default function UserFeedback({ userFeedback, setUserFeedback }) {

  setTimeout(() => {
    setUserFeedback(false)
  }, 4500)

  return (
    <UserFeedbackBox className="userfeedback-display" success={userFeedback.success}>
      <Div css={{ display: 'flex' }}>
        <Flex css={{ ml: '$16' }}>
          <SmallIcon css={{ color: '$white' }}>
            {userFeedback.success ? <CheckIcon /> : <WarningIcon />}
          </SmallIcon>
          <Text>
            {userFeedback.text}
          </Text>
        </Flex>
        <SmallIcon
          css={{ ml: '$12', mt: '-2px', color: 'white' }}
          onClick={() => setUserFeedback(false)}
        >
          <CloseButton />
        </SmallIcon>
      </Div>
      {userFeedback.link &&
        <Div css={{ mb: '$16', ml: '48px', mt: '-4px' }}>
          <Link href={userFeedback.link} target="_blank">{userFeedback.linkText}</Link>
        </Div>
      }
    </UserFeedbackBox>
  )
}

const UserFeedbackBox = styled('div', {
  variants: {
    success: {
      true: {
        backgroundColor: '$green',
      },
      false: {
        backgroundColor: '$red',
      }
    }
  },
  fontSize: '$18',
  position: 'fixed',
  top: '20px',
  right: '20px',
  zIndex: '9999',
  borderRadius: '4px',
  boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25);',
})

const Text = styled('p', {
  color: '$white',
  margin: '16px 46px 16px 4px'
})

const Link = styled('a', {
  color: 'white',
  '&:hover': {
    color: '$secondary'
  }
})
