import React, { useState, useLayoutEffect } from 'react'
import { Routes, Route, useLocation, useNavigate, useParams } from 'react-router-dom'
import Navbar from './components/Navbar'
import Login from './pages/Login'
import SearchInvestors from './pages/SearchInvestors'
import Mylists from './pages/MyLists'
import List from "./pages/List"
// import MyContacts from './pages/UnderConstructionMyContacts'
import MyContacts from './pages/MyContacts'
import Intros from './pages/Intros'
import Protected from "./pages/Protected"
import NotFound from './pages/NotFound'
import Cookies from 'js-cookie'
import InvestorProfile from './pages/InvestorProfile'
import MyFounders from './pages/MyFounders'
import 'moment/locale/en-gb'
import ChangePassword from './pages/ChangePassword'
import { Div } from './components/primitives/Div'
import ForgotPassword from './pages/ForgotPassword'

const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    let location = useLocation()
    let navigate = useNavigate()
    let params = useParams()
    return (
      <Component {...props} router={{ location, navigate, params }} />
    );
  }
  return ComponentWithRouterProp
}

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(Cookies.get('signedin'))
  const [windowSize, setWindowSize] = useState(0)

  const localStorageUsername = localStorage.getItem('username')
  const cookiesUsername = Cookies.get('username')
  const userType = localStorage.getItem('usertype')

  const [authState, setAuthState] = useState({
    status: 'pending',
    error: null,
    username: localStorageUsername || cookiesUsername || '',
    firstName:
      Cookies.get('firstName') === undefined ? '' : Cookies.get('firstName'),
    lastName:
      Cookies.get('lastName') === undefined ? '' : Cookies.get('lastName'),
  })

  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <Div className='App'>

      {(window.location.pathname !== '/' && window.location.pathname !== '/forgot-password')
        && <Navbar authState={authState} setAuthState={setAuthState} setIsLoggedIn={setIsLoggedIn} />
      }

      <Routes>
        <Route strict exact path='/' element={
          <Login authState={authState} setAuthState={setAuthState} isLoggedIn={isLoggedIn} />
        } />
        <Route strict exact path='/forgot-password' element={
          <ForgotPassword />
        } />

        <Route strict exact path='/search-investors' element={
          <Protected>
            <SearchInvestors windowSize={windowSize} />
          </Protected>
        } />
        <Route strict exact path='/search-investors/:name' element={
          <Protected>
            <InvestorProfile />
          </Protected>
        } />
        <Route strict exact path='/my-lists' element={
          <Protected>
            <Mylists />
          </Protected>
        } />
        <Route strict exact path='/my-lists/:listName' element={
          <Protected>
            <List />
          </Protected>
        } />
        <Route strict exact path='/my-contacts' element={
          <Protected>
            <MyContacts />
          </Protected>
        } />
        <Route strict exact path='/intros' element={
          <Protected>
            <Intros />
          </Protected>
        } />
        <Route strict exact path='/change-password' element={
          <Protected>
            <ChangePassword />
          </Protected>
        } />

        {(userType === '3' || userType === '4')
          ?
          <Route strict exact path='/my-founders' element={
            <Protected>
              <MyFounders />
            </Protected>
          } />
          : <Route path='*' element={<NotFound />} />
        }

        <Route path='*' element={<NotFound />} />
      </Routes>
    </Div>
  );
}

export default (withRouter(App))

