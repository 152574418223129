import { useState, useRef } from 'react'
import { PrimaryButton, SecondaryButton, ButtonsWrapper } from '../components/primitives/Button'
import { Header2 } from '../components/primitives/Text'
import { useMutation } from '@apollo/client'
import { DELETE_CONTACTS } from '../mutations/deleteContacts'
import { GET_CONTACTS } from '../queries/useContact'
import { Div } from '../components/primitives/Div'

export default function DeleteContact({ close, contactUuid, setContactUuid, setAllChecked, setUserFeedback, updateDataAfterDelete }) {

  const deleteButtonRef = useRef(null)

  const [deleteContact] = useMutation(DELETE_CONTACTS, {
    variables: {
      uuids: contactUuid.toString()
    },
    refetchQueries: [
      { query: GET_CONTACTS },
    ],
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: () => {
      updateDataAfterDelete(typeof (contactUuid) === 'string' ? [contactUuid] : contactUuid)
      typeof (contactUuid) !== 'string' && setContactUuid([]) 
      setAllChecked && setAllChecked(false)
      close()
      setUserFeedback({ success: true, text: 'Contact deleted' })
    }
  })

  return (
    <Div
      onKeyPress={(event) => {
        const { key } = event;
        if (key === "Enter") {
          deleteButtonRef.current.click();
        }
      }}
    >
      <Header2 css={{ my: '$24' }}>Are you sure you want to delete the {typeof (contactUuid) === 'object' && contactUuid.length > 1 ? 'selected contacts' : 'contact'}?</Header2>
      <ButtonsWrapper>
        <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
        <PrimaryButton
          type='button'
          onClick={() => deleteContact()}
          ref={deleteButtonRef}
        >Delete</PrimaryButton>
      </ButtonsWrapper>
    </Div>
  )
}
