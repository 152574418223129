import { styled } from "../../styles";

export const MobileButtonBurger = styled('div', {
  w: '$24',
  h: '2px',
  bgc: '$grey200',
  br: '$small',
  transition: 'all 0.3s ease-in-out',
  '&::before': {
    transform: 'translateY(-8px)',
    content: `""`,
    position: 'absolute',
    w: '$24',
    h: '2px',
    bgc: '$grey200',
    br: '$small',
    transition: 'all 0.3s ease-in-out',
  },
  '&::after': {
    transform: 'translateY(8px)',
    content: `""`,
    position: 'absolute',
    w: '$24',
    h: '2px',
    bgc: '$grey200',
    br: '$small',
    transition: 'all 0.3s ease-in-out',
  },
  variants: {
    open: {
      yes: {
        bgc: 'transparent',
        '&::before': {
          transform: 'rotate(45deg)',
        },
        '&::after': {
          transform: 'rotate(-45deg)',
        },
      },
    },
  },
})

export const MobileButtonStyled = styled('div', {
  position: 'relative',
  d: 'flex',
  jc: 'center',
  ai: 'center',
  w: '$24',
  h: '$24',
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  zIndex: '$z300',
  mr: '$14'
})
