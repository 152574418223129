import { gql } from '@apollo/client'

export const DELETE_PERSONAL_NOTE = gql`
mutation deletePersonalNote (
  $investorUuid: String!,
  $personalNoteUuid: String!,
) {
  deletePersonalNote (
    investorUuid: $investorUuid,
    personalNoteUuid: $personalNoteUuid,
  ) {
    personalNoteUuid {
      uuid
    }
  }
}
`