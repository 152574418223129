import { useState, useEffect, useCallback, useRef } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_INVESTORS } from '../../queries/useInvestors'
import { Header1, Header2, Paragraph } from '../../components/primitives/Text'
import { PagePadding } from '../../components/primitives/PagePadding'
import { Box } from '../../components/primitives/Box'
import { CheckBox } from '../../components/primitives/Input'
import { FlexSpaceBetween } from '../../components/primitives/FlexSpaceBetween'
import { Flex } from '../../components/primitives/Flex'
import { Modal } from '../../modals'
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg'
import AddSelectedInvestorsToList from '../../modals/AddSelectedInvestorsToList'
import { SecondaryButton } from '../../components/primitives/Button'
import { Table, TableHeader, TableData, TableRow } from '../../components/primitives/Table'
import { SmallCompanyLogo } from '../../components/primitives/CompanyLogo'
import { formatCountryCode } from '../../utils/format/formatCountryCode'
import { PrimaryButton } from '../../components/primitives/Button'
import { Loader } from '../../components/primitives/Loader'
import UserFeedback from '../../components/patterns/UserFeedback'
import Filters from './components/Filters'
import { Div } from '../../components/primitives/Div'
import LogoNameUrlSection from '../../components/patterns/LogoNameUrlSection'

export default function SearchInvestors({ windowSize }) {

  const [name, setName] = useState('')
  const [investmentUuids, setInvestmentUuids] = useState([])
  const [lowerBound, setLowerBound] = useState(null)
  const [upperBound, setUpperBound] = useState(null)
  const [countryCodes, setCountryCodes] = useState([])
  const [portfolioCountryCodes, setPortfolioCountryCodes] = useState([])
  const [portfolioCategories, setPortfolioCategories] = useState([])
  const [investorTypes, setInvestorTypes] = useState([])
  const [investmentCount, setInvestmentCount] = useState(null)
  const [fundingRounds, setFundingRounds] = useState([])
  const [hasContact, setHasContact] = useState('')
  const [page, setPage] = useState(1)
  // const [pageSize, setPageSize] = useState(10)
  const [checked, setChecked] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const [openFilterButton, setOpenFilterButton] = useState(false)
  const [userFeedback, setUserFeedback] = useState(false)


  const handleCheck = (event) => {
    let updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  }

  const handleCheckAll = () => {
    setAllChecked(!allChecked)
    setChecked(data.search.objects.map((object) => {
      return object.uuid
    }))
    if (allChecked) {
      setChecked([])
    }
  }

  const [getInvestors, { error, data, loading, fetchMore, networkStatus }] = useLazyQuery(GET_INVESTORS, {
    variables: {
      searchbox: name,
      investmentUuids: investmentUuids.toString(),
      lowerBound,
      upperBound,
      countryCodes: countryCodes.map((countryCode) => countryCode.value).toString(),
      portfolioCountryCodes: portfolioCountryCodes.map((portfolioCountryCode) => portfolioCountryCode.value).toString(),
      portfolioCategories: portfolioCategories.map((portfolioCategory) => portfolioCategory.value).toString(),
      investorType: investorTypes.map((investorType) => investorType.value).toString(),
      investmentCount,
      fundingRound: fundingRounds.map((fundingRound) => fundingRound.value).toString(),
      hasContact,
      page: 1,
      pageSize: 10,
    },
    notifyOnNetworkStatusChange: true
  })
  // console.log(data?.search.objects)

  useEffect(() => {
    getInvestors()
  }, [data, name, investmentUuids, lowerBound, upperBound, countryCodes, portfolioCountryCodes, portfolioCategories, investorTypes, investmentCount, fundingRounds, hasContact, getInvestors])
  // console.log({name, investmentUuids, lowerBound, upperBound, countryCodes, portfolioCountryCodes, portfolioCategories, investorTypes, investmentCount, fundingRounds, hasContact})
  // console.log({data, error, loading})

  useEffect(() => {
    setPage(1)
  }, [data?.search.totalCount])

  return (
    <PagePadding>

      {userFeedback &&
        < UserFeedback
          userFeedback={userFeedback}
          setUserFeedback={setUserFeedback}
        />
      }

      <FlexSpaceBetween>
        <Header1 css={{ mb: '$14' }} data-testid='searchinvestors-title'>Search Investors</Header1>
        <SecondaryButton
          onClick={() => setOpenFilterButton(!openFilterButton)}
          css={{ display: 'none', '@bp3': { display: 'block', mb: '$16' } }}
        >
          <Flex>
            <Div css={{ width: '16px', marginRight: '8px' }}>
              <FilterIcon />
            </Div>
            {openFilterButton ? 'Close filter' : 'Filters'}
          </Flex>
        </SecondaryButton>
      </FlexSpaceBetween>

      <Flex css={{ ai: 'flex-start', '@bp3': { display: 'block' } }}>

        {/* ↓↓↓↓↓↓↓↓↓↓↓↓Filter Boxes↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ */}
        <Filters
          setName={setName}
          setInvestmentUuids={setInvestmentUuids}
          setLowerBound={setLowerBound}
          setUpperBound={setUpperBound}
          setCountryCodes={setCountryCodes}
          setPortfolioCountryCodes={setPortfolioCountryCodes}
          setPortfolioCategories={setPortfolioCategories}
          setInvestorTypes={setInvestorTypes}
          setInvestmentCount={setInvestmentCount}
          setFundingRounds={setFundingRounds}
          setHasContact={setHasContact}
          windowSize={windowSize}
          openFilterButton={openFilterButton}
          setOpenFilterButton={setOpenFilterButton}
          lowerBound={lowerBound}
          upperBound={upperBound}
          countryCodes={countryCodes}
          portfolioCountryCodes={portfolioCountryCodes}
          portfolioCategories={portfolioCategories}
          investorTypes={investorTypes}
          investmentCount={investmentCount}
          fundingRounds={fundingRounds}
          hasContact={hasContact}
          name={name}
          // debounce={debounce}
          // debouncedSearch={debouncedSearch}
          // handleChange={handleChange}
        />
        {/* ↑↑↑↑↑↑↑↑↑↑↑↑Filter boxes↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ */}

        {!openFilterButton || windowSize > 1024
          ?
          <Div css={{ flexBasis: '82%', height: 'fit-content', marginBottom: '24px' }}>

            {/* ↓↓↓↓↓↓↓↓↓↓↓↓ Results ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓*/}
            <Box
              data-testid='searchinvestors-search-results'
              css={{ flexBasis: '82%', height: 'fit-content', marginBottom: '24px' }}
            >

              <FlexSpaceBetween css={{ '@bp2': { display: 'block' } }}>
                <Flex css={{ '@bp2': { display: 'block', '@bp2': { mb: '$12' } } }}>
                  <Header2 css={{ mr: '$12', whiteSpace: 'nowrap', '@bp2': { mb: '$12' } }}>
                    Results {data && `(${new Intl.NumberFormat().format(data.search.totalCount)})`}
                  </Header2>

                  <Modal
                    triggerButtonStyle={'text with add icon'}
                    triggerButtonLabel={'Add search result to list'}
                    title={'Save this search result to a list'}
                    Component={AddSelectedInvestorsToList}
                    countryCodes={countryCodes.map((countryCode) => countryCode.value).toString()}
                    fundingRound={fundingRounds.map((fundingRound) => fundingRound.value).toString()}
                    hasContact={hasContact}
                    investmentCount={investmentCount}
                    investmentUuids={investmentUuids.toString()}
                    investorType={investorTypes.map((investorType) => investorType.value).toString()}
                    lowerBound={lowerBound}
                    portfolioCategories={portfolioCategories.map((portfolioCategory) => portfolioCategory.value).toString()}
                    portfolioCountryCodes={portfolioCountryCodes.map((portfolioCountryCode) => portfolioCountryCode.value).toString()}
                    searchbox={name}
                    upperBound={upperBound}
                    resultsTotalCount={data?.search.totalCount}
                    setUserFeedback={setUserFeedback}
                  />
                </Flex>

                <Modal
                  triggerButtonStyle={'text with add icon'}
                  triggerButtonLabel={'Add selected investors to list'}
                  title={'Select list'}
                  Component={AddSelectedInvestorsToList}
                  selectedInvestors={checked}
                  setUserFeedback={setUserFeedback}
                />
              </FlexSpaceBetween>

              {loading && <Loader size="20" css={{ d: 'inline-block', ml: '$8', mt: '$16' }} data-testid="loader" />}
              {error && <Paragraph>An error occurred. Please try refreshing the page or report this bug.</Paragraph>}
              {data && (
                <>
                  <Div css={{ overflowX: 'scroll' }}>
                    <Table css={{ my: '$40', minWidth: '950px', marginBottom: '0px' }}>
                      <thead >
                        <tr >
                          <TableHeader>
                            <CheckBox
                              type='checkbox'
                              onChange={handleCheckAll}
                              value={data.search.objects.map((object) => {
                                return object.uuid
                              })}
                              checked={allChecked}
                            />
                          </TableHeader>
                          <TableHeader>Name</TableHeader>
                          <TableHeader>Invest Count</TableHeader>
                          <TableHeader>Min Round</TableHeader>
                          <TableHeader>Max Round</TableHeader>
                          <TableHeader>Location</TableHeader>
                          <TableHeader>Investments</TableHeader>
                        </tr>
                      </thead>

                      <tbody>
                        {data?.search.objects.map((search, index) => {
                          return ((
                            <TableRow key={search.uuid}>
                              <TableData>
                                <CheckBox
                                  type="checkbox"
                                  onChange={handleCheck}
                                  value={search.uuid}
                                  checked={checked?.includes(search.uuid)}
                                />
                              </TableData>

                              <TableData>
                                <LogoNameUrlSection investor={search} />
                              </TableData>

                              <TableData>{search.investmentCount}</TableData>

                              <TableData>
                                ${search.extra !== null
                                  && search.extra !== ''
                                  && JSON.parse(search.extra).lead_investments_min !== undefined
                                  ? new Intl.NumberFormat().format(JSON.parse(search.extra).lead_investments_min)
                                  : new Intl.NumberFormat().format(search.leadInvestmentsMin)}
                              </TableData>

                              <TableData>
                                ${search.extra !== null
                                  && search.extra !== ''
                                  && JSON.parse(search.extra).lead_investments_min !== undefined
                                  ? new Intl.NumberFormat().format(JSON.parse(search.extra).lead_investments_max)
                                  : new Intl.NumberFormat().format(search.leadInvestmentsMax)}
                              </TableData>

                              <TableData>
                                {search.extra !== null
                                  && search.extra !== ''
                                  && JSON.parse(search.extra).city !== undefined
                                  ? JSON.parse(search.extra).city
                                  : search.city.replace(' ', '\u00A0')}
                                <br />
                                {search.extra !== null
                                  && search.extra !== ''
                                  && JSON.parse(search.extra).country_code !== undefined
                                  ? formatCountryCode(JSON.parse(search.extra).country_code)
                                  : formatCountryCode(search.countryCode).replace(' ', '\u00A0')}
                              </TableData>
                              <TableData css={{ w: '25%' }}>
                                {/* {console.log(search.investments)} */}
                                {search.investments && search.investments.slice(0, 10).map((investment, index) => {
                                  return (
                                    <span key={`${investment.uuid}_${index}`}>
                                      <a
                                        href={investment.homepageUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                        title={investment.name}
                                      >
                                        <SmallCompanyLogo
                                          src={investment.logoUrlSmall ? investment.logoUrlSmall : investment.logoUrl}
                                          css={{ '@bp3': { mr: '4px' } }}
                                          onError={(e) => (e.currentTarget.src = require('../../assets/images/fallback-img-company-lg.png'))}
                                        />
                                      </a>
                                    </span>
                                  )
                                })}
                              </TableData>
                            </TableRow>
                          ))
                        }
                        )}
                      </tbody>
                    </Table>
                  </Div>

                  {!(data?.search.totalCount - ((page - 1) * 10) <= 10) &&
                    <Flex css={{ mt: '24px' }}>
                      <PrimaryButton
                        css={{ margin: '0 auto', mb: '$24' }}
                        onClick={() => {
                          fetchMore({
                            variables: {
                              pageSize: 10,
                              page: page + 1,
                            },
                            updateQuery: (previousValue, { fetchMoreResult }) => {
                              if (!fetchMoreResult) {
                                return previousValue
                              }
                              return {
                                search: {
                                  objects: [
                                    ...previousValue.search.objects,
                                    ...fetchMoreResult.search.objects
                                  ],
                                  totalCount: previousValue.search.totalCount,
                                }
                              }
                            }
                          });
                          setPage(page + 1)
                        }}
                      >
                        {
                          networkStatus === 7
                            ? 'Load more results'
                            : networkStatus === 3
                            && <Loader size="16" css={{ mx: '51.5px', my: '2.5px' }} />
                        }
                      </PrimaryButton>
                    </Flex>
                  }
                </>
              )}
            </Box>
            {/* ↑↑↑↑↑↑↑↑↑↑↑↑Results↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ */}
          </Div>
          : <></>
        }

      </Flex>

    </PagePadding >
  )
}










