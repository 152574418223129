import { gql } from '@apollo/client';

export const GET_INVESTORS = gql`
query search (
  $searchbox: String,
  $investmentUuids: String,
  $lowerBound: Int,
  $upperBound: Int,
  $countryCodes: String,
  $portfolioCountryCodes: String,
  $portfolioCategories: String,
  $investorType: String,
  $investmentCount: Int,
  $fundingRound: String,
  $hasContact: String,
  $page: Int,
  $pageSize: Int,
  ) {
    search (
      searchbox: $searchbox,
      investmentUuids: $investmentUuids,
      lowerBound: $lowerBound,
      upperBound: $upperBound,
      countryCodes: $countryCodes,
      portfolioCountryCodes: $portfolioCountryCodes,
      portfolioCategories: $portfolioCategories,
      investorType: $investorType,
      investmentCount: $investmentCount,
      fundingRound: $fundingRound,
      hasContact: $hasContact,
      page: $page,
      pageSize: $pageSize,
      ) {
        totalCount
        objects {
          uuid
          name
          cbUrl
          city
          countryCode
          linkedinUrl
          logoUrl
          logoUrlSmall
          twitterUrl
          homepageUrl
          leadInvestmentsMin
          leadInvestmentsMax
          investmentCount
          permalink
          extra
          contacts {
            uuid
            name
          }
          investments {
              uuid
              name
              logoUrl
              logoUrlSmall
              homepageUrl
          }
          nautaCoInvestor
      }
    }
}
`