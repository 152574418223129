import { gql } from "@apollo/client"

export const DELETE_CUSTOM_LISTS = gql`
mutation deleteCustomLists($uuids: String!) {
  deleteCustomLists(uuids: $uuids) {
    customList {
      uuid
    }
  }
}
`