import { gql } from "@apollo/client";

export const GET_EXPORT_LIST_URL = gql`
mutation getExportListUrl (
  $listUuid: String!
) {
  getExportListUrl(
    listUuid: $listUuid
  ) {
    exportList {
      url
    }
  }
}
`