import { useState } from 'react'
import { Box } from '../primitives/Box'
import { Paragraph } from '../primitives/Text'
import { Badge } from '../primitives/Badge'
import { Span } from '../primitives/Span'

export function CoInvestorLabel({ nautaCoInvestor }) {

  const [boxDisplayed, setBoxDisplayed] = useState(false)

  const inInvestorProfilePage = document.URL.includes("uuid")

  return (
    <Span css={{ position: 'relative', w: 'fit-content', }}>
      <Badge
        onMouseEnter={() => setBoxDisplayed(true)}
        onMouseLeave={() => setBoxDisplayed(false)}
        backgroundColor={'yellow'}
        css={{ whiteSpace: 'nowrap' }}
      >
        Co-investor
      </Badge>
      {
        boxDisplayed &&
        <Box
          className='box-display'
          css={inInvestorProfilePage
            ? { position: 'absolute', background: '$grey50', color: 'black', borderRadius: '4px', left: 74, top: 0 }
            : { position: 'absolute', background: '$grey50', color: 'black', borderRadius: '4px', bottom: 24, right: 0 }
          }
        >
          <Paragraph css={{ whiteSpace: 'nowrap' }}>
            {nautaCoInvestor}
          </Paragraph>
        </Box>
      }
    </Span>
  )
}
