import { gql, useQuery } from "@apollo/client"

export const GET_PERSONAL_NOTES = gql`
query investorPersonalNotes (
  $investorUuid: String
) {
  investorPersonalNotes (
    investorUuid: $investorUuid
  ) {
    uuid
    investorUuid {
      uuid
      name
    }
    noteOwner {
      username
    }
    createdAt
    noteText
  }
}
`

export const usePersonalNotes = (uuid) => {
  const {data, loading, error} = useQuery(GET_PERSONAL_NOTES, {
    variables: {
      investorUuid: uuid
    }
  })
  return {data, loading, error}
}