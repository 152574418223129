import { useMemo, useEffect, useState } from 'react'
import CommonTable from '../../../../../components/patterns/CommonTable'
import { GET_INVESTOR_PORTFOLIO_CATEGORIES } from '../../../../../queries/useInvestorPortfolioCategories'
import { useLazyQuery, useQuery } from '@apollo/client'
import { SmallCompanyLogo } from '../../../../../components/primitives/CompanyLogo'
import { Span } from '../../../../../components/primitives/Span'
import { Loader } from '../../../../../components/primitives/Loader'

export default function Categories({ investorUuid }) {

  const [tableData, setTableData] = useState([])
  const [category, setCategory] = useState(null)

  const { data, error, loading } = useQuery(GET_INVESTOR_PORTFOLIO_CATEGORIES, {
    variables: {
      investorUuid,
    }
  })

  const [loadMoreOrganizations, { loading: loadingMoreOrganizations }] = useLazyQuery(GET_INVESTOR_PORTFOLIO_CATEGORIES, {
    variables: {
      investorUuid,
      category,
      page: 1,
      pageSize: 9999,
    },
    onCompleted: (data) => {
      let temp = data.investorPortfolioCategories.categories[0]
      setTableData((old) => (
        old.map((row) => {
          if (row.category === category) {
            return dataWithAccessor(temp)
          }
          return row
        })
      ))
    }
  })

  useEffect(() => {
    data &&
      setTableData(data?.investorPortfolioCategories.categories.map((cat) => {
        return dataWithAccessor(cat)
      }))
  }, [data])



  const dataWithAccessor = (cat) => ({
    category: cat.category,
    count: cat.count,
    companies: cat.organizations.map((org) => { return org.name }),
    organizations: cat.organizations
  })

  const columns = useMemo(() => [
    { Header: 'Category', accessor: 'category', },
    { Header: 'Count', accessor: 'count', },
    {
      Header: 'Companies', accessor: 'companies',
      Cell: ({ row }) => {
        return <>
          {row.original.organizations.map((org) => {
            return <Span key={org.uuid}>
              <a href={org.homepageUrl} target='_blank' rel="noreferrer">
                <SmallCompanyLogo
                  src={org.logoUrlSmall ? org.logoUrlSmall : org.logoUrl}
                  css={{ w: '18px', h: '18px', mr: '$2', my: '$4' }}
                  onError={(e) => (e.currentTarget.src = require('../../../../../assets/images/fallback-img-company-lg.png'))}
                />
              </a>
              <Span css={{ mr: '$16' }}>{org.name}</Span>
            </Span >
          })
          }
          {(loadingMoreOrganizations && category === row.original.category)
            ?
            <Loader size='16' css={{ d: 'inline-block' }} />
            :
            row.original.count !== row.original.companies.length &&
            <Span
              css={{ fontWeight: '500',textDecoration: 'underline', cursor: 'pointer', '&:hover': { color: '$secondary' } }}
              onClick={() => {
                setCategory(row.original.category)
                loadMoreOrganizations()
              }}
            >
              Display more
            </Span>
          }
        </>
      }
    },
  ], [loadingMoreOrganizations])

  return (
    <CommonTable
      data={tableData}
      columns={columns}
      loading={loading}
      error={error}
      columnsWithNoSort={['Companies']}
    />
  )
}
