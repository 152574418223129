import { gql } from '@apollo/client'


export const DELETE_NOTE = gql`
mutation deleteCustomListInvestorNote(
  $noteUuid: String!,
) {
  deleteCustomListInvestorNote (
    noteUuid: $noteUuid
  ) {
    customListInvestorNote {
      uuid
    }
  }
}

`