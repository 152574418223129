import React, { useRef } from 'react'
import { Header2 } from '../../components/primitives/Text'
import { PrimaryButton, SecondaryButton, ButtonsWrapper } from '../../components/primitives/Button'
import { useMutation } from '@apollo/client'
import { DELETE_INVESTORS_FROM_CUSTOM_LIST } from '../../mutations/deleteInvestorsFromCustomList'
import { Div } from '../../components/primitives/Div'
import { Span } from '../../components/primitives/Span'

export default function DeleteInvestorFromCustomList({ close, investorName, investorUuid, setAllChecked, setUserFeedback, updateDataAfterDelete, tableData }) {
  // console.log({ investorUuid })
  // console.log('delete===>',pageIndex)

  const urlParams = new URLSearchParams(window.location.search)
  const listUuid = urlParams.get('uuid')
  // console.log({ listUuid })

  const deleteButtonRef = useRef(null)

  const [deleteInvestorsFromCustomList] = useMutation(DELETE_INVESTORS_FROM_CUSTOM_LIST, {
    variables: {
      listUuid,
      investorUuids: investorUuid
      // investorUuids: investorUuid.toString()
    },
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: () => {
      updateDataAfterDelete(investorUuid, tableData)
      // updateDataAfterDelete(typeof (investorUuid) === 'string' ? [investorUuid] : investorUuid)
      // setAllChecked && setAllChecked(false)
      close()
      setUserFeedback({ success: true, text: 'Investor deleted from list' })
    }
  })

  return (
    <Div
      data-testid="deleteinvestor-modal"
      onKeyPress={(event) => {
        const { key } = event;
        if (key === "Enter") {
          deleteButtonRef.current.click();
        }
      }}>
      <Header2 css={{ my: '$24' }}>
        Are you sure you want to delete
        {investorName
          ? <Span css={{fontWeight: '600'}}>&nbsp;{investorName} </Span>
          : '\u00A0the selected investors '
        }
        from this list?
      </Header2>
      <ButtonsWrapper>
        <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
        <PrimaryButton
          type='button'
          onClick={() => deleteInvestorsFromCustomList()}
          ref={deleteButtonRef}
        >Delete</PrimaryButton>
      </ButtonsWrapper>
    </Div>
  )
}
