import { useEffect, useState } from "react"
import { ButtonsWrapper } from "../components/primitives/Button"
import { PrimaryButton, SecondaryButton } from "../components/primitives/Button"
import { useMutation } from "@apollo/client"
import { CREATE_INVESTOR } from "../mutations/createInvestor"
import { formatCountryCode } from "../utils/format/formatCountryCode"
import { Header4, RequiredText } from "../components/primitives/Text"
import { Input, TextArea } from "../components/primitives/Input"
import { RequiredMark } from "../components/primitives/RequiredMark"
import { MultiSelect } from "react-multi-select-component";
import { CountryOptionsWithBlankOption } from "../options/CountryOptions"

export default function AddNewInvestor({ close, setUserFeedback }) {

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState(null)
  const [website, setWebsite] = useState('')
  const [linkedin, setLinkedin] = useState('')
  const [nameFieldIsEmpty, setNameFieldIsEmpty] = useState(false)
  const [websiteFieldIsEmpty, setWebsiteFieldIsEmpty] = useState(false)

  const handleCheck = () => {
    if (name.trim().length === 0 && website.trim().length === 0) {
      setNameFieldIsEmpty(true)
      setWebsiteFieldIsEmpty(true)
    } else if (name.trim().length === 0) {
      setNameFieldIsEmpty(true)
    } else if (website.trim().length === 0) {
      setWebsiteFieldIsEmpty(true)
    }
    else {
      createInvestor()
    }
  }

  useEffect(() => {
    setNameFieldIsEmpty(false)
  }, [name])

  useEffect(() => {
    setWebsiteFieldIsEmpty(false)
  }, [website])

  const [createInvestor] = useMutation(CREATE_INVESTOR, {
    variables: {
      name,
      shortDescription: description,
      city,
      countryCode: country,
      homepageUrl: website,
      linkedinUrl: linkedin,
    },
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      close()
      setUserFeedback(
        data.createInvestor.investor.error === null
          ? {
            success: true,
            text: 'New investor added',
            link: `/search-investors/${data.createInvestor.investor.permalink}?uuid=${data.createInvestor.investor.uuid}`,
            linkText: 'See investor profile'
          }
          : {
            success: false,
            text: `${data.createInvestor.investor.error}`,
            link: `/search-investors/${data.createInvestor.investor.permalink}?uuid=${data.createInvestor.investor.uuid}`,
            linkText: 'See investor profile'
          }
      )
    }
  })

  return (
    <div>
      <Header4 isRequired={nameFieldIsEmpty}>
        Name
        <RequiredMark/>
      </Header4>
      <Input
        onChange={(e) => setName(e.target.value)}
        isRequired={nameFieldIsEmpty}
      />
      {nameFieldIsEmpty && <RequiredText>Required</RequiredText>}

      <Header4>Description</Header4>
      <TextArea
        rows='3'
        onChange={(e) => setDescription(e.target.value)}
      />

      <Header4>City</Header4>
      <Input onChange={(e) => setCity(e.target.value)} />

      <Header4>Country</Header4>
      <MultiSelect
        options={CountryOptionsWithBlankOption}
        hasSelectAll={false}
        onChange={(e) => setCountry(e[e.length - 1].value)}
        value={[{ label: formatCountryCode(country), value: country }]}
        closeOnChangedValue={true}
        ClearIcon={false}
        className='deleteIconInEditInvestorInformationMultiSelect hideCheckbox'
      />

      <Header4 isRequired={websiteFieldIsEmpty}>
        Website
        <RequiredMark/>
      </Header4>
      <Input
        onChange={(e) => setWebsite(e.target.value)}
        isRequired={websiteFieldIsEmpty}
      />
      {websiteFieldIsEmpty && <RequiredText>Required</RequiredText>}

      <Header4>Linkedin</Header4>
      <Input onChange={(e) => setLinkedin(e.target.value)} />

      <form>
        <ButtonsWrapper css={{ mt: '$24' }}>
          <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
          <PrimaryButton
            type='button'
            onClick={() => handleCheck()}
          >Add</PrimaryButton>
        </ButtonsWrapper>
      </form>
    </div>
  )
}
