import React, { useState, useEffect } from 'react'
import { ButtonsWrapper, SecondaryButton, PrimaryButton } from '../components/primitives/Button'
import { Header4 } from '../components/primitives/Text'
import { Input, TextArea } from '../components/primitives/Input'
import { RequiredMark } from '../components/primitives/RequiredMark'
import { SEND_INVITATION } from '../mutations/sendInvitation'
import { GET_INVITATIONS } from '../queries/useInvitations'
import { useMutation } from '@apollo/client'
import { RequiredText } from '../components/primitives/Text'

export default function InviteNewUser({ close, setUserFeedback }) {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [organization, setOrganization] = useState('')
  const [nameFieldIsEmpty, setNameFieldIsEmpty] = useState(false)
  const [emailFieldIsEmpty, setEmailFieldIsEmpty] = useState(false)
  const [organizationFieldIsEmpty, setOrganizationFieldIsEmpty] = useState(false)

  const handleCheck = () => {
    if (name.trim().length === 0) {
      setNameFieldIsEmpty(true)
    }
    if (email.trim().length === 0) {
      setEmailFieldIsEmpty(true)
    }
    if (organization.trim().length === 0) {
      setOrganizationFieldIsEmpty(true)
    }
    if (name.trim().length !== 0 && email.trim().length !== 0 && organization.trim().length !== 0) {
      sendInvitation()
    }
  }

  useEffect(() => {
    setNameFieldIsEmpty(false)
  }, [name])

  useEffect(() => {
    setEmailFieldIsEmpty(false)
  }, [email])

  useEffect(() => {
    setOrganizationFieldIsEmpty(false)
  }, [organization])

  const [sendInvitation] = useMutation(SEND_INVITATION, {
    variables: {
      name,
      email,
      organization
    },
    refetchQueries: [
      { query: GET_INVITATIONS }
    ],
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: () => {
      close()
      setUserFeedback({ success: true, text: 'Invitation sent' })
    }
  })


  return (
    <>
      <Header4 isRequired={nameFieldIsEmpty}>
        Name
        <RequiredMark/>
      </Header4>
      <Input
        onChange={(e) => setName(e.target.value)}
        isRequired={nameFieldIsEmpty}
      />
      {nameFieldIsEmpty && <RequiredText>Required</RequiredText>}

      <Header4 isRequired={emailFieldIsEmpty}>
        Email
        <RequiredMark/>
      </Header4>
      <Input
        onChange={(e) => setEmail(e.target.value)}
        isRequired={emailFieldIsEmpty}
      />
      {emailFieldIsEmpty && <RequiredText>Required</RequiredText>}

      <Header4 isRequired={organizationFieldIsEmpty}>
        Organization
        <RequiredMark/>
      </Header4>
      <TextArea
        rows={1}
        onChange={(e) => setOrganization(e.target.value)}
        isRequired={organizationFieldIsEmpty}
      />
      {organizationFieldIsEmpty && <RequiredText>Required</RequiredText>}


      <ButtonsWrapper css={{ mt: '$24' }}>
        <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
        <PrimaryButton
          type='button'
          onClick={() => handleCheck()}
        >Invite</PrimaryButton>
      </ButtonsWrapper>
    </>
  )
}
