import { gql, useQuery } from "@apollo/client";

export const GET_INVITATIONS = gql`
query  {
  invitations {
    uuid
    name
    email
    organization
  }
}`

export const useInvitations = () => {
  const { data, error, loading } = useQuery(GET_INVITATIONS)
  return { data, error, loading }
}