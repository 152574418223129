import { gql } from "@apollo/client";

export const FORGOTTEN_PASSWORD = gql`
mutation forgottenPassword (
  $username: String!
) {
  forgottenPassword (
    username: $username
  ) {
    username {
      id
    }
  }
}
`