import { styled } from '../../styles'

export const CheckBox = styled('input', {
  accentColor: '#f04e29',
})

export const Input = styled('input', {
  color: '$grey600',
  border: '1px solid $grey100',
  borderRadius: '4px',
  padding: '8px',
  outlineColor: '$grey100',
  fontSize: '$13',
  width: '100%',
  '&::placeholder': {
    color: '$grey100',
  },
  variants: {
    isRequired: {
      true: {
        border: '1px solid $red'
      }
    }
  }
})

export const TextArea = styled('textarea', {
  color: '$grey600',
  border: '1px solid $grey100',
  borderRadius: '4px',
  padding: '8px',
  outlineColor: '$grey100',
  resize: 'none',
  width: '500px !important',
  fontSize: '$13',
  '&::placeholder': {
    color: '$grey100'
  },
  '@bp1': {
    width: '356px !important',
  },
  '@bp0': {
    width: 'calc(100vw - 60px) !important'
  },
  variants: {
    isRequired: {
      true: {
        border: '1px solid $red'
      }
    }
  }
})

export const FilterInput = styled('div', {
  '&>input, &>select': {
    width: '100%',
    height: '22px',
    border: '1px solid $grey100',
    borderRadius: '4px',
    padding: '3px',
    outlineColor: '$grey100',
    fontSize: '$12',
    '&::placeholder': {
      color: '$grey100'
    },
  },
  '& button': {
    height: '22px',
    fontSize: '$12 !important',
  }
})