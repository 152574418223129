
import { useState, useRef, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { styled } from '../../styles';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import { Header1, Header2, Header5, Paragraph, RequiredText } from '../../components/primitives/Text';
import { PrimaryButton, ShowAndHideButton } from '../../components/primitives/Button';
import { Input } from '../../components/primitives/Input';
import UserFeedback from '../../components/patterns/UserFeedback';
import { Div } from '../../components/primitives/Div';
import { SEND_CODE } from './mutation';
import { TOKEN_AUTH } from './mutation';
import { ReactComponent as TwoFactorAuthIcon } from '../../assets/icons/two-factor-authentication.svg'
import { Flex } from '../../components/primitives/Flex';
import { ReactComponent as HidePasswordIcon } from '../../assets/icons/hide-password.svg'
import { ReactComponent as DisplayPasswordIcon } from '../../assets/icons/display-password.svg'
import { MediumIcon } from '../../components/primitives/IconWrapper';

export default function Login({ setAuthState, isLoggedIn }) {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [usernameFieldIsEmpty, setUsernameFieldIsEmpty] = useState(false)
  const [passwordFieldIsEmpty, setPasswordFieldIsEmpty] = useState(false)
  const [userFeedback, setUserFeedback] = useState(false)
  const [verifyCodePage, setVerifyCodePage] = useState(false)
  const [secretCode, setSecretCode] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const usernameInputRef = useRef(null)
  const passwordInputRef = useRef(null)
  const buttonForContinueRef = useRef(null)
  const buttonForSigninRef = useRef(null)

  const handleCheck = () => {
    if (username.trim().length === 0 && password.trim().length === 0) {
      setUsernameFieldIsEmpty(true)
      setPasswordFieldIsEmpty(true)
    } else if (username.trim().length === 0) {
      setUsernameFieldIsEmpty(true)
    } else if (password.trim().length === 0) {
      setPasswordFieldIsEmpty(true)
    } else {
      sendCode({ variables: { password, username } })
    }
  }

  useEffect(() => {
    setUsernameFieldIsEmpty(false)
  }, [username])

  useEffect(() => {
    setPasswordFieldIsEmpty(false)
  }, [password])

  const [sendCode] = useMutation(SEND_CODE, {
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      // console.log(data.sendCode.user.username === username)
      setVerifyCodePage(true)
    }
  })

  const [tokenAuth] = useMutation(TOKEN_AUTH, {
    variables: {
      password,
      username,
      secretCode: secretCode.trim(),
    },
    onError: (error) => {
      // console.error(error)
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      // console.log(data)
      onLogin({ payload: data.tokenAuth2fa.payload, user: data.tokenAuth2fa.user })
    },
  })

  const onLogin = ({ payload, user }) => {
    const expireDate = new Date(+`${payload.exp}000`)
    document.cookie = `signedin=true; path=/; domain=${document.domain
      }; expires=${expireDate.toUTCString()};`
    document.cookie = `username=${user.username}; path=/; domain=${document.domain
      }; expires=${expireDate.toUTCString()};`
    // document.cookie= 'JWT=' + data.tokenAuth.token
    document.cookie = `firstName=${user.firstName}; path=/; domain=${document.domain
      }; expires=${expireDate.toUTCString()};`
    document.cookie = `lastName=${user.lastName}; path=/; domain=${document.domain
      }; expires=${expireDate.toUTCString()};`
    setAuthState({
      status: 'success',
      error: null,
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
    })
    localStorage.setItem('username', username)
    localStorage.setItem('usertype', user.userType)
  }

  if (Cookies.get('signedin') || isLoggedIn) return <Navigate to="/search-investors" />

  return (
    <Page data-testid="login-page">
      {userFeedback && <UserFeedback userFeedback={userFeedback} setUserFeedback={setUserFeedback} />}
      <LoginBox>
        {verifyCodePage
          ?
          <>
            <Div css={{ textAlign: 'center' }}>
              <Header2 css={{ mb: '$16' }}>Verify that it's you</Header2>
              <Paragraph css={{ mb: '28px' }}>We have sent a verification code to the email attached to your account.</Paragraph>
            </Div>
            <Header5>Verification code</Header5>
            <Input
              value={secretCode}
              onChange={(e) => setSecretCode(e.target.value)}
              onKeyPress={(event) => {
                const { key } = event;
                if (key === "Enter") {
                  buttonForSigninRef.current.click();
                }
              }}
            />
            <PrimaryButton
              css={{ w: '100%', mt: '$24' }}
              disabled={secretCode.trim().length === 0}
              onClick={() => tokenAuth()}
              ref={buttonForSigninRef}
            >
              Verify and sign in
            </PrimaryButton>
          </>

          :
          <>
            <Div css={{ textAlign: 'center' }}>
              <Header1 css={{ mb: '$12', fontSize: '$32' }}>VCRM</Header1>
            </Div>
            <Div css={{ display: 'flex', flexDirection: 'column' }}>
              <Input
                placeholder='Username'
                type='email'
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                ref={usernameInputRef}
                onKeyPress={(event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    passwordInputRef.current.focus();
                  }
                }}
                isRequired={usernameFieldIsEmpty}
                css={{ mt: '$24' }}
              />
              {usernameFieldIsEmpty && <RequiredText>Required</RequiredText>}

              <Flex css={{ position: 'relative', mt: '$24' }}>
                <ShowAndHideButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword
                    ?  <MediumIcon><HidePasswordIcon/></MediumIcon>
                     : <MediumIcon><DisplayPasswordIcon/></MediumIcon>
                  }
                </ShowAndHideButton>
                <Input
                  placeholder='Password'
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  ref={passwordInputRef}
                  onKeyPress={(event) => {
                    const { key } = event;
                    if (key === "Enter") {
                      buttonForContinueRef.current.click();
                    }
                  }}
                  isRequired={passwordFieldIsEmpty}
                />
              </Flex>
              {passwordFieldIsEmpty && <RequiredText>Required</RequiredText>}

              <a href='/forgot-password'>
                <Header5 css={{ textAlign: 'center', mt: '$12', textDecoration: 'underline' }}>
                  forgot password?
                </Header5>
              </a>

              <PrimaryButton
                type='button'
                onClick={() => handleCheck()}
                ref={buttonForContinueRef}
                css={{ mt: '$16' }}
              >Continue</PrimaryButton>
            </Div>
          </>
        }
      </LoginBox>
    </Page>
  )
}



const Page = styled('div', {
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const LoginBox = styled('div', {
  border: '1px solid $grey100',
  borderRadius: '4px',
  width: '28rem',
  padding: '36px 48px 48px',
  margin: '0px 12px',
  boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);',
  '@bp1': {
    padding: '12px 16px 48px',
  }
})


