import { useState, useMemo, useEffect } from 'react'
import { Header1 } from '../../components/primitives/Text'
import { Table, TableHeader, TableData, TableRow } from '../../components/primitives/Table'
import { FlexSpaceBetween } from '../../components/primitives/FlexSpaceBetween'
import { Flex } from '../../components/primitives/Flex'
import { Box } from '../../components/primitives/Box'
import CreateNewList from '../../modals/CreateList'
import { Modal } from '../../modals';
import { PagePadding } from '../../components/primitives/PagePadding'
import { SmallLink } from '../../components/primitives/Link'
import { ReactComponent as AddIcon } from '../../assets/icons/add-filled.svg'
// import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import DeleteList from '../../modals/DeleteList'
import moment from 'moment'
import 'moment/locale/en-gb'
import { IconInsideButton } from '../../components/primitives/IconWrapper'
// import { CheckBox } from '../../components/primitives/Input'
import { Loader } from '../../components/primitives/Loader'
import { useQuery } from '@apollo/client'
import { GET_CUSTOM_LISTS_PAGINATED } from '../../queries/useCustomListsPaginated'
import UserFeedback from '../../components/patterns/UserFeedback'
import { Div } from '../../components/primitives/Div'
import StepOutCustomList from '../../modals/StepOutCustomList'
import { useTable, usePagination, useSortBy, useFilters } from 'react-table'
import Pagination from '../../components/patterns/Pagination'
import { Span } from '../../components/primitives/Span'
import CommonTable from '../../components/patterns/CommonTable'

export default function MyLists() {
  // const [checked, setChecked] = useState([])
  // const [allChecked, setAllChecked] = useState(false)
  const [userFeedback, setUserFeedback] = useState(false)
  const [tableData, setTableData] = useState([])

  document.title = 'My Lists'
  const localStorageUsername = localStorage.getItem('username')

  const { data, loading, error } = useQuery(GET_CUSTOM_LISTS_PAGINATED)

  useEffect(() => {
    data &&
      setTableData(data?.customListPaginated.objects.map((obj) => {
        return (dataWithAccessor(obj))
      }))
  }, [data])

  // const handleCheck = (event) => {
  //   let updatedList = [...checked];
  //   if (event.target.checked) {
  //     updatedList = [...checked, event.target.value];
  //   } else {
  //     updatedList.splice(checked.indexOf(event.target.value), 1);
  //   }
  //   setChecked(updatedList);
  // }

  // const handleCheckAll = () => {
  //   setAllChecked(!allChecked)
  //   setChecked(data.customListPaginated.objects.map((list) => {
  //     return list.uuid
  //   }))
  //   if (allChecked) {
  //     setChecked([])
  //   }
  // }

  const dataWithAccessor = (obj) => ({
    collaborators: obj.collaborators,
    count: obj.investorsCount,
    creator: obj.creator.name,
    creatorEmail: obj.creator.username,
    description: obj.description,
    name: obj.name,
    updatedAt: moment(obj.updatedAt).format('lll'),
    uuid: obj.uuid,
  })

  const columns = useMemo(() => [
    {
      Header: 'Name', accessor: 'nameDisplay', Cell: ({ row }) => {
        return <SmallLink href={`my-lists/${row.original.name}?uuid=${row.original.uuid}`} css={{ color: '$primary' }}>
          {row.original.name}
        </SmallLink>
      }
    },
    { Header: 'Description', accessor: 'description' },
    { Header: 'Creator', accessor: 'creator' },
    { Header: 'Updated\u00A0At', accessor: 'updatedAt' },
    { Header: 'Count', accessor: 'count' },
    {
      Header: 'Collaborators', accessor: 'collaborators', Cell: ({ row }) => {
        return row.original.collaborators.map((collaborator) => {
          return <Span key={collaborator.collaborator.email}>
            {collaborator.collaborator.email}<br />
          </Span>
        })
      }
    },
    {
      Header: '', accessor: 'uuid', Cell: ({ row }) => {
        return row.original.creatorEmail === localStorageUsername
          ? <Modal
            triggerButtonStyle={'Delete'}
            Component={DeleteList}
            listName={row.original.name}
            listUuid={row.original.uuid}
            // setListUuid={setChecked}
            setUserFeedback={setUserFeedback}
            hasCollaborator={row.original.collaborators.length >= 2}
          />
          : <Modal
            triggerButtonStyle={'Leave'}
            listName={row.original.name}
            listUuid={row.original.uuid}
            Component={StepOutCustomList}
            setUserFeedback={setUserFeedback}
          />
      }
    }
  ], [])


  return (
    <PagePadding>
      {userFeedback && <UserFeedback userFeedback={userFeedback} setUserFeedback={setUserFeedback} />}

      <FlexSpaceBetween css={{ mb: '$16' }}>
        <Header1>My Lists</Header1>
        <Modal
          triggerButtonLabel={(
            <Flex css={{ ai: 'center' }}>
              Create new list
              <IconInsideButton><AddIcon /></IconInsideButton>
            </Flex>
          )}
          title={'Create New List'}
          Component={CreateNewList}
          setUserFeedback={setUserFeedback}
        />
      </FlexSpaceBetween>

      <Box>
        {/* <FlexSpaceBetween css={{ mb: '$12' }}>
          <div></div>
          <Modal
            triggerButtonLabel={(
              <Flex>
                Delete selected lists
                <IconInsideButton><DeleteIcon /></IconInsideButton>
              </Flex>
            )}
            Component={DeleteList}
            listUuid={checked}
            setAllChecked={setAllChecked}
            setListUuid={setChecked}
            setUserFeedback={setUserFeedback}
          />
        </FlexSpaceBetween> */}

        <CommonTable
          data={tableData}
          columns={columns}
          loading={loading}
          error={error}
          columnsWithNoFilter={['Name', 'Description', 'Creator', 'Updated\u00A0At', 'Count', 'Collaborators', '']}
          columnsWithNoSort={['Name', 'Description', 'Creator', 'Updated\u00A0At', 'Count', 'Collaborators', '']}
        />

      </Box>
    </PagePadding>
  )
}



