import { gql } from "@apollo/client"

export const DELETE_INVESTORS_FROM_CUSTOM_LIST = gql`
mutation deleteInvestorsFromCustomList (
  $listUuid: String!,
  $investorUuids: String!
) {
  deleteInvestorsFromCustomList(
    listUuid: $listUuid,
    investorUuids: $investorUuids
  ) {
    customList {
      uuid
    }
  }
}
`