import { styled } from '../../styles'

export const Badge = styled('span', {
  color: 'white',
  padding: '2px 4px',
  borderRadius: '4px',
  marginLeft: '6px',
  fontSize: '10px',
  variants: {
    backgroundColor: {
      green: {
        backgroundColor: '$green',
      },
      yellow: {
        backgroundColor: '$yellow100',
      }
    }
  }
})
