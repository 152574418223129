import { gql, useQuery } from "@apollo/client"

export const GET_LISTS = gql`
query custom_list{
  customLists{
    uuid
    name
    description
    creator {
      username
      firstName
      lastName
    }
    createdAt
    updatedAt
    collaborators {
      collaborator {
        email
      }
    }
    investors{
      id
    }
  }
}
`

export const useLists = () => {
  const { data, error, loading } = useQuery(GET_LISTS)

  return {
    data,
    error,
    loading
  }
}