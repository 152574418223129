import { gql } from "@apollo/client";

export const UPDATE_INVESTOR = gql`
  mutation updateInvestor (
    $city: String
    $countryCode: String
    $foundedOn: String
    $fundingRoundPreSeed: Boolean
    $fundingRoundSeed: Boolean
    $fundingRoundSeriesA: Boolean
    $fundingRoundSeriesB: Boolean
    $fundingRoundSeriesC: Boolean
    $homepageUrl: String
    $investorTypes: String
    $leadInvestmentsMax: Int
    $leadInvestmentsMin: Int
    $linkedinUrl: String
    $name: String
    $portfolioCategories: String
    $portfolioCountryCodes: String
    $shortDescription: String
    $twitterUrl: String
    $uuid: String!
      ) {
    updateInvestor (
      city: $city
      countryCode: $countryCode
      foundedOn: $foundedOn
      fundingRoundPreSeed: $fundingRoundPreSeed
      fundingRoundSeed: $fundingRoundSeed
      fundingRoundSeriesA: $fundingRoundSeriesA
      fundingRoundSeriesB: $fundingRoundSeriesB
      fundingRoundSeriesC: $fundingRoundSeriesC
      homepageUrl: $homepageUrl
      investorTypes: $investorTypes
      leadInvestmentsMax: $leadInvestmentsMax
      leadInvestmentsMin: $leadInvestmentsMin
      linkedinUrl: $linkedinUrl
      name: $name
      portfolioCategories: $portfolioCategories
      portfolioCountryCodes: $portfolioCountryCodes
      shortDescription: $shortDescription
      twitterUrl: $twitterUrl
      uuid: $uuid
    ) {
      updateInvestor {
        extra
        extraLog
      }
    }
  }
`