import { gql } from '@apollo/client'

export const DEACTIVATE_USER = gql`
mutation deactivateUser(
  $userId: String!
) {
  deactivateUser(
    userId: $userId
  ) {
    user {
      id
      firstName
      lastName
      isActive
    }
  }
}
`