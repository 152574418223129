import { Header5, Paragraph, Header2 } from '../../../components/primitives/Text'
import { Table, TableRow, TableHeader, TableData } from '../../../components/primitives/Table'
import moment from 'moment'
import { GET_RELATED_LISTS } from '../../../queries/useRelatedLists'
import { Loader } from '../../../components/primitives/Loader'
import { Box, BoxWrapper } from '../../../components/primitives/Box'
import { SmallLink } from '../../../components/primitives/Link'
import { useQuery } from '@apollo/client'

export default function RelatedLists({ investorUuid }) {

  const { data, loading, error } = useQuery(GET_RELATED_LISTS, {
    variables: {
      uuid: investorUuid
    }
  })

  return (
    <BoxWrapper>
      <Box css={{ overflowX: 'scroll' }}>
        <Header2 css={{ mb: '$12' }}>Related Lists</Header2>
        {loading && <Loader size="20" css={{ d: 'inline-block', ml: '$8', mt: '$16' }} data-testid="loader" />}
        {error && <Paragraph>An error occurred</Paragraph>}
        {data && data.relatedLists.length === 0
          ? <Paragraph>No related lists</Paragraph>
          : <Table css={{ minWidth: '950px' }}>
            <thead>
              {data && <TableRow>
                <TableHeader>Name</TableHeader>
                <TableHeader>Description</TableHeader>
                <TableHeader>Creator</TableHeader>
                <TableHeader>Created at</TableHeader>
                <TableHeader>Updated at</TableHeader>
                <TableHeader>Count</TableHeader>
                <TableHeader>Collaborators</TableHeader>
              </TableRow>
              }
            </thead>
            <tbody>
              {data?.relatedLists.map((list) => {
                return (
                  <TableRow key={list.uuid}>
                    <TableData>
                      <SmallLink href={`/my-lists/${list.name}?uuid=${list.uuid}`}>
                        {list.name}
                      </SmallLink>
                    </TableData>
                    <TableData>{list.description}</TableData>
                    <TableData>{list.creator.firstName} {list.creator.lastName}</TableData>
                    <TableData>{moment(list.createdAt).format('lll')}</TableData>
                    <TableData>{moment(list.updatedAt).format('lll')}</TableData>
                    <TableData>{list.investorsCount}</TableData>
                    <TableData>
                      {list.collaborators.map((collaborator) => {
                        return <Header5 key={collaborator.collaborator.username}>
                          {collaborator.collaborator.username}
                        </Header5>
                      })}
                    </TableData>
                  </TableRow>
                )
              })}
            </tbody>
          </Table>
        }
      </Box>
    </BoxWrapper>
  )
}
