import { useState } from 'react'
import { PrimaryButton, SecondaryButton, ButtonsWrapper } from '../components/primitives/Button'
import { Header4, Paragraph } from '../components/primitives/Text'
import axios from 'axios'
import { styled } from '../styles'
import { ReactComponent as WarningIcon } from '../assets/icons/warning.svg'
import { Flex } from '../components/primitives/Flex'
import { Span } from '../components/primitives/Span'

export default function UploadContacts({ close, setUserFeedback }) {

  const [selectedFile, setSelectedFile] = useState(null)
  const [isFilePicked, setIsFilePicked] = useState(false)

  const changeHandler = (e) => {
    setSelectedFile(e.target.files[0])
    setIsFilePicked(true)
  }

  const uploadContacts = () => {
    const url = process.env.REACT_APP_VCRM_GRAPHQL_URI
    const formData = new FormData()
    formData.append('file', selectedFile)
    formData.append(
      'query',
      `mutation{
        uploadContacts(
          input:{clientMutationId:"${selectedFile.name}"}
        ) {
          success
          clientMutationId
        }
      }`,
    )
    // console.log({ formData })
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
      },
      withCredentials: true,
    }
    // console.log({ url, formData, config })
    return axios.post(url, formData, config)
  }

  const handleUploadContacts = () => {
    uploadContacts(selectedFile, selectedFile.name)
      .then((response) => {
        // console.log({ response })
        if (response.data.errors) {
          setUserFeedback({ success: false, text: `${response.data.errors[0].message}` })
        } else if (response.data.data.uploadContacts.success) {
          close()
          setUserFeedback({ success: true, text: 'File received. It will be processed in a few moments' })
        }
      })
      .catch((error) => {
        console.error({ error })
        setUserFeedback({ success: false, text: `${error}` })
      })
  }

  return (
    <FormWrapper>
      <form>
        <Flex css={{ my: '30px', mx: '$14' }}>
          <Span css={{ w: '$16', mr: '$8', mb: '$20', color: '$red' }}>
            <WarningIcon />
          </Span>
          <Paragraph>
            The Excel or CSV file must contain
            <Span css={{ fontWeight: '600' }}> "name"</Span> and
            <Span css={{ fontWeight: '600' }}> "email"</Span> columns. <br />
            <Span css={{ fontWeight: '600' }}> "comment"</Span> column could be added optionally.
          </Paragraph>
        </Flex>
        <Header4>Contacts file</Header4>
        <input
          type='file'
          onChange={changeHandler}
        />

        <ButtonsWrapper css={{ mt: '$36' }}>
          <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
          <PrimaryButton
            type='button'
            onClick={() => handleUploadContacts()}
            disabled={isFilePicked === false}
          >Upload</PrimaryButton>
        </ButtonsWrapper>
      </form>
    </FormWrapper>
  )
}

const FormWrapper = styled('div', {
  '@bp0': {
    width: 'calc(100vw - 60px)'
  },
})

