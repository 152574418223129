import { gql } from '@apollo/client'

export const UNASSIGN_CONTACT = gql`
mutation unassignContact (
  $contactUuid: String!
) {
  unassignContact (
    contactUuid: $contactUuid
  ) {
    contact {
      uuid
      name
      email
      comment
      # do not add 'investor' because it has to be 'undefined'. 'null' would cause an error 
    }
  }
}
`