import { useRef } from 'react'
import { PrimaryButton, SecondaryButton, ButtonsWrapper } from '../components/primitives/Button';
import { Div } from '../components/primitives/Div';
import { Header2 } from '../components/primitives/Text';
import { GET_CUSTOM_LISTS_PAGINATED } from '../queries/useCustomListsPaginated';
import { STEP_OUT_CUSTOM_LIST } from '../mutations/stepOutCustomList';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

export default function StepOutCustomList({ close, listUuid, listName, setUserFeedback }) {

  const navigate = useNavigate()
  const stepOutButtonRef = useRef(null)

  const [stepOutList] = useMutation(STEP_OUT_CUSTOM_LIST, {
    variables: {
      uuid: listUuid
    },
    refetchQueries: [
      { query: GET_CUSTOM_LISTS_PAGINATED }
      // { query: GET_CUSTOM_LISTS_PAGINATED, variables: { page: 1, pageSize: 25 } },
      // { query: GET_CUSTOM_LISTS_PAGINATED, variables: { page: 1, pageSize: 9999 } },
    ],
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: () => {
      close()
      setUserFeedback({ success: true, text: 'Stepped out of list' })
      setTimeout(() => {
        { new URLSearchParams(window.location.search).get('uuid') !== null && navigate('/my-lists') }
      }, 1500)
    }
  })

  return (
    <Div
      onKeyPress={(event) => {
        const { key } = event;
        if (key === "Enter") {
          stepOutButtonRef.current.click();
        }
      }}>
      <Header2 css={{ my: '$24' }} >
        Do you want to stop collaborating on
        <b>&nbsp;{listName}</b>?
      </Header2>
      <ButtonsWrapper>
        <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
        <PrimaryButton
          type='button'
          onClick={() => stepOutList()}
          ref={stepOutButtonRef}
        >
          Step out
        </PrimaryButton>
      </ButtonsWrapper>
    </Div>
  )
}
