import { gql, useQuery } from "@apollo/client"

export const GET_CONTACTS = gql`
query contacts{
  contacts{
    totalCount
    assignedContactsCount
    notAssignedContactsCount
    notApprovedContactsCount
  }
}
`

export const useContact = () => {
  const { data, error, loading } = useQuery(GET_CONTACTS)

  return {
    data,
    error,
    loading
  }
}