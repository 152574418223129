import { gql } from "@apollo/client";

export const DELETE_CONTACTS = gql`
mutation deleteContacts ($uuids: String!) {
  deleteContacts(uuids: $uuids) {
    contact {
      uuid
      name
      email
    }
  }
}
`




