import { useEffect, useState } from 'react'
import { PrimaryButton, SecondaryButton, ButtonsWrapper } from '../../components/primitives/Button';
import { Header4, RequiredText } from '../../components/primitives/Text';
import { Input, TextArea } from '../../components/primitives/Input';
import { useMutation } from '@apollo/client'
import { CREATE_CUSTOM_LIST } from '../../mutations/createCustomList';
import { RequiredMark } from '../../components/primitives/RequiredMark';
import { GET_CUSTOM_LISTS_PAGINATED } from '../../queries/useCustomListsPaginated';

export default function CreateNewList({ close, setUserFeedback }) {

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [nameFieldIsEmpty, setNameFieldIsEmpty] = useState(false)

  const handleCheck = () => {
    if (name.trim().length === 0) {
      setNameFieldIsEmpty(true)
    } else {
      createList()
    }
  }

  const [createList] = useMutation(CREATE_CUSTOM_LIST, {
    variables: {
      name,
      description,
    },
    refetchQueries: [
      { query: GET_CUSTOM_LISTS_PAGINATED, variables: { page: 1, pageSize: 25 } },
      { query: GET_CUSTOM_LISTS_PAGINATED, variables: { page: 1, pageSize: 9999 } },
    ],
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: () => {
      close()
      setUserFeedback({ success: true, text: 'New list created' })
    }
  })

  useEffect(() => {
    setNameFieldIsEmpty(false)
  }, [name])

  return (
    <>
      <Header4 isRequired={nameFieldIsEmpty} data-testid='field-required'>
        Name
        <RequiredMark/>
      </Header4>
      <Input
        type='text'
        value={name}
        onChange={(e) => setName(e.target.value)}
        isRequired={nameFieldIsEmpty}
        // aria-label='name'
        title='name'
      />
      {nameFieldIsEmpty && <RequiredText>Required</RequiredText>}
      <Header4>Description</Header4>
      <TextArea
        rows='5'
        type='text'
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        // aria-label='description'
        title='description'
      />
      <ButtonsWrapper css={{ mt: '$24' }}>
        <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
        <PrimaryButton
          type='button'
          onClick={() => handleCheck()}
        >Create</PrimaryButton>
      </ButtonsWrapper>
    </>
  )
}

