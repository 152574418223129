import { useMemo } from 'react'
import { useTable, usePagination, useSortBy, useFilters, useBlockLayout } from 'react-table'
import { Table, TableHeader, TableData, TableRow } from '../primitives/Table'
import { Div } from '../primitives/Div'
import DefaultColumnFilter from '../table/filters/DefaultColumnFilter'
import { filterTypes } from '../table/filters/filterTypes'
import { FilterInput } from '../primitives/Input'
import { ReactComponent as Sort } from '../../assets/icons/sort.svg'
import { ReactComponent as SortUp } from '../../assets/icons/sort-up.svg'
import { ReactComponent as SortDown } from '../../assets/icons/sort-down.svg'
import { SortIcon } from '../primitives/IconWrapper'
import { Flex } from '../primitives/Flex'
import Pagination from './Pagination'
import { Loader } from '../primitives/Loader'

export default function CommonTable({ columns, data, loading, error, columnsWithNoFilter, columnsWithNoSort, allDataLoading }) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    prepareRow,
    state: { pageIndex }
  } = useTable({
    autoResetFilters: false,
    autoResetPage: false,
    autoResetSortBy: false,
    columns,
    data,
    filterTypes,
    defaultColumn: useMemo(() => ({
      Filter: DefaultColumnFilter,
    }), [data]),
    initialState: {
      pageIndex: 0,
      pageSize: 25,
    },
    getSubRows: (row) => row.subRowsd
  },
    useFilters,
    useSortBy,
    usePagination,
    // useBlockLayout,
  )

  return (
    <>
      <Div css={{ overflowX: 'scroll' }}>
        <Table {...getTableProps()} css={{ minWidth: '1080px' }} >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableHeader {...column.getHeaderProps()}>
                    {(columnsWithNoSort === undefined || !columnsWithNoSort.includes(column.Header))
                      ?
                      <Flex {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <SortIcon data-testid='sort-icon'><SortUp /></SortIcon>
                            : <SortIcon data-testid='sort-icon'><SortDown /></SortIcon>
                          : <SortIcon data-testid='sort-icon'><Sort /></SortIcon>
                        }
                      </Flex>
                      :
                      <>{column.render('Header')}</>
                    }

                    {(columnsWithNoFilter === undefined || !columnsWithNoFilter.includes(column.Header))
                      && <FilterInput>{column.canFilter ? column.render('Filter') : null}</FilterInput>
                    }
                  </TableHeader>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {loading &&
              <TableRow>
                <TableData><Loader size="20" css={{ d: 'inline-block' }} data-testid='loader' /></TableData>
              </TableRow>
            }
            {error &&
              <TableRow>
                <TableData>An error occurred. Please try again or report this bug.</TableData>
              </TableRow>
            }
            {page.map(row => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <TableData {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableData>
                    )
                  })}
                </TableRow>
              )
            })}
          </tbody>
        </Table>
      </Div>

      <Pagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        allDataLoading={allDataLoading ? allDataLoading : loading}
      />

    </>
  )
}
