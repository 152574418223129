import { gql } from '@apollo/client'

export const ADD_PERSONAL_NOTE = gql`
mutation addPersonalNote(
  $investorUuid: String!,
  $noteText: String,
) {
  addPersonalNote(
    investorUuid: $investorUuid,
    noteText: $noteText
  ) {
    investorPersonalNote {
      uuid
      investorUuid {
        name
      }
      noteOwner {
        username
      }
      createdAt
      noteText
    }
  }
}
`