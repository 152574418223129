import { useState, useEffect, useRef } from 'react'
import { styled } from '../../../../styles'
import { Header4, Paragraph, RequiredText } from '../../../../components/primitives/Text'
import moment from 'moment'
import 'moment/locale/en-gb'
import { EDIT_NOTE, ADD_NOTE } from './mutation'
// import { ADD_NOTE_TO_INVESTOR } from '../../../../mutations/addNoteToInvestorInCustomList'
import { useMutation } from '@apollo/client'
import ClickAwayListener from 'react-click-away-listener'
// import TextareaAutosize from 'react-textarea-autosize'
// import { EditableInput } from '../../../../components/primitives/EditableInput'
import { Div } from '../../../../components/primitives/Div'
import { MentionsInput, Mention } from 'react-mentions'
import { MentionsTextarea } from '../../../../components/patterns/MentionsTextarea'
// import { useUsers } from '../../../../queries/useUsers'
import { Span } from '../../../../components/primitives/Span'
import { Flex } from '../../../../components/primitives/Flex'
import { TextAreaWrapper } from '../../../InvestorProfile/components/Notes'
import TextareaAutosize from 'react-textarea-autosize'
import { SmallSecondaryButton } from '../../../../components/primitives/Button'
import { Modal } from '../../../../modals'
import DeleteNote from '../../../../modals/DeleteNote'
import { GET_CUSTOM_LIST_DETAIL_PAGINATED } from '../../../../queries/useCustomListDetailPaginated'
import reactStringReplace from 'react-string-replace'
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg'
import { SmallIcon } from '../../../../components/primitives/IconWrapper'

export default function Comments({ notes, listUuid, investorUuid, setUserFeedback, /*updateData,*/ rowIndex, columnId, collaborators, comments, updateDataAfterAddComment, updateDataAfterDeleteComment, updateDataAfterEditComment }) {

  // console.log(rowIndex, columnId)

  // const [displayedNote, setDisplayedNote] = useState('')
  // const [editMode, setEditMode] = useState(false)
  const [listOfNamesForMention, setListOfNamesForMention] = useState([])
  const [newComment, setNewComment] = useState('')
  const [editModeUuid, setEditModeUuid] = useState('')
  // console.log(editModeUuid)
  const [edittedComment, setEdittedComment] = useState('')
  // console.log(edittedComment)
  const [isRequiredNewComment, setIsRequiredNewComment] = useState(false)
  const [isRequiredEdittedComment, setIsRequiredEdittedComment] = useState(false)

  const username = localStorage.getItem('username')
  // const note = notes[0]

  const commentButtonRef = useRef(null)
  const saveButtonRef = useRef(null)

  const commentsSortedByDate = (comments) => {
    let temp = comments.slice().sort(function (a, b) {
      return new Date(a.createdAt) - new Date(b.createdAt)
    })
    return temp
  }

  useEffect(() => {
    collaborators.forEach((collaborator) => {
      if (listOfNamesForMention.length !== collaborators.length - 1 && collaborator.label !== username) {
        listOfNamesForMention.push({ id: collaborator.value, display: collaborator.name })
        setListOfNamesForMention(listOfNamesForMention.sort((a, b) => a.display.localeCompare(b.display)))
      }
    })
  })

  const getMentionedUserIds = (comment) => {
    return Array.from([...comment.matchAll(/@\[(.*?)]\((\d*?)\)/g)], m => m[2]).join(',')
  }

  const handleCheckNewComment = (comment) => {
    if (comment.trim().length === 0) {
      setIsRequiredNewComment(true)
    } else {
      addNote()
    }
  }

  const handleCheckEdittedComment = (comment) => {
    if (comment.trim().length === 0) {
      setIsRequiredEdittedComment(true)
    } else {
      editNote()
    }
  }

  useEffect(() => {
    setIsRequiredNewComment(false)
  }, [newComment])

  useEffect(() => {
    setIsRequiredEdittedComment(false)
  }, [edittedComment])



  const [addNote] = useMutation(ADD_NOTE, {
    variables: {
      investorUuid,
      listUuid,
      note: newComment,
      mentionedUserIds: getMentionedUserIds(newComment),
    },
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      setUserFeedback({ success: true, text: 'Comment added' })
      setNewComment('')
      updateDataAfterAddComment(data.addCustomListInvestorNote.customListInvestorNote, rowIndex)
    }
  })

  const [editNote] = useMutation(EDIT_NOTE, {
    variables: {
      noteUuid: editModeUuid,
      note: edittedComment,
      mentionedUserIds: getMentionedUserIds(edittedComment),
    },
    // refetchQueries: [
    //   { query: GET_CUSTOM_LIST_DETAIL_PAGINATED, variables: { uuid: listUuid, page: 1, pageSize: 25 } },
    //   { query: GET_CUSTOM_LIST_DETAIL_PAGINATED, variables: { uuid: listUuid, page: 1, pageSize: 9999 } }
    // ],
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
      console.error(error)
    },
    onCompleted: (data) => {
      setUserFeedback({ success: true, text: 'Comment editted' })
      updateDataAfterEditComment(rowIndex, data.editCustomListInvestorNote.customListInvestorNote)
      setEditModeUuid('')
    }
  })

  return (
    // <Div>
    //   <ClickAwayListener
    //     onClickAway={() => {
    //       setEditMode(false)
    //       setDisplayedNote(note.note)
    //     }}
    //   >
    //     <Span>
    //       <MentionsTextarea
    //         onClick={() => setEditMode(true)}
    //         onKeyPress={(event) => {
    //           const { key } = event;
    //           if (key === "Enter") {
    //             saveButtonRef.current.click();
    //             event.target.blur()
    //           }
    //         }}
    //         inWhichPage={'list'}
    //       >
    //         <MentionsInput
    //           onChange={(e) => setDisplayedNote(e.target.value)}
    //           value={displayedNote}
    //           className='mentions'
    //         >
    //           <Mention
    //             className="mentions__mention"
    //             markup="@[__display__](__id__)"
    //             trigger="@"
    //             data={listOfNamesForMention}
    //             displayTransform={(id, display) => `@${display} `}
    //           />
    //         </MentionsInput>
    //       </MentionsTextarea>
    //       {editMode &&
    //         <CancelAndSaveButtons>
    //           <button
    //             onClick={() => {
    //               setEditMode(false)
    //               setDisplayedNote(note.note)
    //             }}>cancel</button>
    //           <button
    //             onClick={() => addNote()}
    //             ref={saveButtonRef}
    //           >save</button>
    //         </CancelAndSaveButtons>
    //       }
    //     </Span>
    //   </ClickAwayListener>
    //   {
    //     note.note !== '' && note.noteAddedBy &&
    //     <Header4 css={{ fontSize: '$11', mt: '0px', ml: '$6' }} >
    //       by {note.noteAddedBy.firstName} {note.noteAddedBy.lastName}
    //       &nbsp;on {moment(note.noteCreatedAt).format('lll')}
    //     </Header4>
    //   }
    // </Div>
    <Div>
      {commentsSortedByDate(comments).map((comment) => {
        return <Div key={comment.uuid} css={{ mb: '$6' }}>
          {editModeUuid === comment.uuid
            ?
            <>
              <MentionsTextarea
                onKeyPress={(event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    saveButtonRef.current.click();
                    event.target.blur()
                  }
                }}
                isRequired={isRequiredEdittedComment}
                css={{ mr: '$2', '&>div': { padding: '$2', }, '& textarea': { padding: '$2' } }}
              >
                <MentionsInput
                  onChange={(e) => setEdittedComment(e.target.value)}
                  value={edittedComment}
                  className='mentions'
                >
                  <Mention
                    className="mentions__mention"
                    markup="@[__display__](__id__)"
                    trigger="@"
                    data={listOfNamesForMention}
                    displayTransform={(id, display) => `@${display} `}
                  />
                </MentionsInput>
              </MentionsTextarea>
              {isRequiredEdittedComment && <RequiredText>Required</RequiredText>}
            </>
            : <Paragraph css={{ fontWeight: '$400' }}>
              {reactStringReplace(
                comment.note,
                /@\[(.*?)]\(\d*?\)/g,
                (match) => <Span css={{ color: '$primary' }} key={match}>@{match}&nbsp;</Span>
              )}
            </Paragraph>
          }

          <Flex css={{ position: 'relative' }}>
            <Header4 css={{ my: '$0', fontSize: '$11' }}>
              by&nbsp;
              {comment.addedBy.name}
              &nbsp;on&nbsp;
              {moment(comment.updatedAt).format('lll')}
              {/* &nbsp;|&nbsp;{comment.uuid} */}
            </Header4>

            {username === comment.addedBy.email &&
              <Modal
                triggerButtonStyle={'DeleteComment'}
                Component={DeleteNote}
                noteUuid={comment.uuid}
                listUuid={listUuid}
                setUserFeedback={setUserFeedback}
                updateDataAfterDeleteComment={updateDataAfterDeleteComment}
                rowIndex={rowIndex}
              />
            }
            {(username === comment.addedBy.email && editModeUuid === comment.uuid)
              ? <Div css={isRequiredEdittedComment 
                ? { w: 'fit-content', position: 'absolute', right: '0', mt: '-36px' }
                : { w: 'fit-content', position: 'absolute', right: '0' }
              }>
                <SaveAndCancelButton
                  onClick={() => setEditModeUuid('')}
                >
                  cancel
                </SaveAndCancelButton>
                <SaveAndCancelButton
                  // onClick={() => editNote()}
                  onClick={() => handleCheckEdittedComment(edittedComment)}
                  ref={saveButtonRef}
                // disabled={edittedComment.trim().length === 0}
                >
                  save
                </SaveAndCancelButton>
              </Div>
              : username === comment.addedBy.email
              && <SmallIcon
                css={{ color: '$grey300', width: '14px' }}
                onClick={() => {
                  setEditModeUuid(comment.uuid)
                  setEdittedComment(comment.note)
                }}
              >
                <EditIcon />
              </SmallIcon>
            }
          </Flex>
        </Div>
      })}

      <Flex css={{ alignItems: 'flex-end' }}>
        <MentionsTextarea
          onKeyPress={(event) => {
            const { key } = event;
            if (key === "Enter") {
              commentButtonRef.current.click();
              event.target.blur()
            }
          }}
          isRequired={isRequiredNewComment}
          css={{ mr: '$2', '&>div': { padding: '$2', }, '& textarea': { padding: '$2' } }}
        >
          <MentionsInput
            onChange={(e) => setNewComment(e.target.value)}
            value={newComment}
            className='mentions'
          >
            <Mention
              className="mentions__mention"
              markup="@[__display__](__id__)"
              trigger="@"
              data={listOfNamesForMention}
              displayTransform={(id, display) => `@${display} `}
            />
          </MentionsInput>
        </MentionsTextarea>
        <SmallSecondaryButton
          // onClick={() => addNote()}
          onClick={() => handleCheckNewComment(newComment)}
          ref={commentButtonRef}
        >comment</SmallSecondaryButton>
      </Flex>
      {isRequiredNewComment && <RequiredText>Required</RequiredText>}

    </Div>
  )
}

const SaveAndCancelButton = styled('button', {
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  '&:hover': {
    color: '$secondary'
  }
})

