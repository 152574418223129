import { useState, useEffect, useRef } from 'react'
import { Header2, Header4, Header5, Paragraph, RequiredText } from '../../components/primitives/Text'
import { Box } from '../../components/primitives/Box'
import { Div } from '../../components/primitives/Div'
import { Input } from '../../components/primitives/Input'
import { PrimaryButton } from '../../components/primitives/Button'
import { useNavigate } from "react-router-dom";
import { styled } from '../../styles'
import { useMutation } from '@apollo/client'
import { FORGOTTEN_PASSWORD } from './mutation'
import UserFeedback from '../../components/patterns/UserFeedback'

export default function ForgotPassword() {

  const [email, setEmail] = useState('')
  const [isRequired, setIsRequired] = useState(false)
  const [userFeedback, setUserFeedback] = useState(false)
  const [emailSentDisplay, setEmailSentDisplay] = useState(false)

  const navigate = useNavigate()
  const buttonForResetRef = useRef(null)

  const handleCheck = () => {
    if (email.trim().length === 0) {
      setIsRequired(true)
    } else {
      forgottenPassword()
    }
  }

  useEffect(() => {
    setIsRequired(false)
  }, [email])

  const [forgottenPassword] = useMutation(FORGOTTEN_PASSWORD, {
    variables: {
      username: email
    },
    onError: (error) => {
      setUserFeedback({ success: false, text: 'Please enter valid email' })
    },
    onCompleted: () => {
      setEmailSentDisplay(true)
    }
  })

  return (
    <Div css={{ h: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {userFeedback && <UserFeedback userFeedback={userFeedback} setUserFeedback={setUserFeedback} />}
      <Box css={{ w: '28rem', textAlign: 'center' }}>
        {
          emailSentDisplay
            ?
            <>
              <Header5 css={{ fontSize: '$16', my: '$24', mx: '$12', textAlign: 'left' }}>
                You will get an email with instructions on resetting your password. If it doesn't arrive, be sure to check your spam folder.
              </Header5>

              <BackButton onClick={() => navigate(-1)} >
                Back to login
              </BackButton>
            </>
            : <>
              <Header2>Enter your email to reset password</Header2>
              <Input
                type='email'
                placeholder='Email'
                css={{ mt: '$32' }}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                isRequired={isRequired}
                onKeyPress={(event) => {
                  const { key } = event;
                  if (key === "Enter") {
                    buttonForResetRef.current.click();
                  }
                }}
              />
              {isRequired && <RequiredText css={{ textAlign: 'left' }}>Required</RequiredText>}
              <PrimaryButton
                css={{ w: '100%', mt: '24px' }}
                onClick={() => handleCheck()}
                ref={buttonForResetRef}
              >
                Reset password
              </PrimaryButton>

              <BackButton onClick={() => navigate(-1)}>
                Back
              </BackButton>
            </>
        }
      </Box>
    </Div>
  )
}

const BackButton = styled('button', {
  mt: '$16',
  border: 'none',
  backgroundColor: 'transparent',
  fontSize: '$13',
  textDecoration: 'underline',
  color: '$grey500'
})
