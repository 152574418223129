export const StatusOptions = [
  //value has to be ''
  { label: '-', value: '' },
  { label: 'New', value: 'New', color: '#4885ed' },
  { label: 'Email sent', value: 'Email sent', color: '#3cba54' },
  { label: 'Intro call done', value: 'Intro call done', color: '#ffde00' },
  { label: 'Declined', value: 'Declined', color: '#db3236' },
  { label: 'Due Diligence', value: 'Due Diligence', color: '#F2950D' },
  { label: 'Proof of Concept', value: 'Proof of Concept', color: '#d83ed6' },
  { label: 'Investment Committee', value: 'Investment Committee', color: '#D2F20D' },
  { label: 'Negotiation', value: 'Negotiation', color: '#32DBD7' },
]



// { label: 'New', value: 'New', color: '#4885ed' },
// { label: 'Email sent', value: 'Email sent', color: '#3cba54' },
// { label: 'Intro call done', value: 'Intro call done', color: '#f4c20d' },
// { label: 'Declined', value: 'Declined', color: '#db3236' },

// Due Diligence
// Proof of Concept
// Investment Committee
// Negotiation
