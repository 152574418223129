import { Div } from '../components/primitives/Div'
import { Header2 } from '../components/primitives/Text'
import { ButtonsWrapper, PrimaryButton, SecondaryButton } from '../components/primitives/Button'
import { useMutation } from '@apollo/client'
import { UPDATE_INTRO_REQUEST } from '../mutations/updateIntroRequest'
import { GET_INTRO_REQUESTS_RECEIVED } from '../queries/useIntroRequestsReceived'
import { GET_INTRO_REQUESTS_SENT } from '../queries/useIntroRequestsSent'
export default function UpdateIntroRequest({ close, requestUuid, setUserFeedback, done, deleted }) {

  // console.log({done})

  const [updateIntroRequest] = useMutation(UPDATE_INTRO_REQUEST, {
    variables: {
      uuid: requestUuid,
      deleted,
      done,
    },
    refetchQueries: [
      { query: GET_INTRO_REQUESTS_RECEIVED },
      { query: GET_INTRO_REQUESTS_SENT }
    ],
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      // console.log(data)
      setUserFeedback({
        success: true,
        text: `${deleted ? 'Request deleted' : done ? 'Marked as done' : 'Marked as undone'}`
      })
      close()
    }
  })

  return (
    <Div>
      <Header2 css={{ mt: '$20' }}>
        {deleted
          ? 'Do you want to delete this request?'
          : done
            ? 'Do you want to mark this request as done?'
            : 'Do you want to mark this request as undone?'
        }
      </Header2>
      <ButtonsWrapper css={{ mt: '$24' }}>
        <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
        <PrimaryButton
          type='button'
          onClick={() => updateIntroRequest()}
        >Yes</PrimaryButton>
      </ButtonsWrapper>
    </Div>
  )
}
