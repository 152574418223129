import { gql } from '@apollo/client'

export const CANCEL_INVITATION = gql`
mutation cancelInvitation(
  $uuid: String!
) {
  cancelInvitation (
    uuid: $uuid
  ) {
    invitation {
      uuid
      name
      email
      organization
    }
  }
}
`