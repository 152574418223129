import React, { useRef } from 'react'
import { DELETE_PERSONAL_NOTE } from '../mutations/deletePersonalNote'
import { useMutation } from '@apollo/client'
import { Div } from '../components/primitives/Div'
import { PrimaryButton, SecondaryButton, ButtonsWrapper } from '../components/primitives/Button'
import { Header2 } from '../components/primitives/Text'
import { GET_PERSONAL_NOTES } from '../queries/usePersonalNotes'


export default function DeletePersonalNote({ investorUuid, personalNoteUuid, setUserFeedback, close }) {

  const deleteButtonRef = useRef(null)

  const [deletePersonalNote] = useMutation(DELETE_PERSONAL_NOTE, {
    variables: {
      investorUuid,
      personalNoteUuid,
    },
    refetchQueries: [
      {query: GET_PERSONAL_NOTES, variables: {investorUuid}}
    ],
    onError: (error) => {
      setUserFeedback({success: false, text: `${error}`})
    },
    onCompleted: () => {
      close()
      setUserFeedback({success: true, text: 'Note deleted'})
    }
  })



  return (
    <Div
      onKeyPress={(event) => {
        const { key } = event;
        if (key === "Enter") {
          deleteButtonRef.current.click();
        }
      }}>
      <form>
        <Header2 css={{ mt: '$16' }}>Are you sure you want to delete this note?</Header2>
        <ButtonsWrapper css={{ mt: '$32' }}>
          <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
          <PrimaryButton
            type='button'
            onClick={() => deletePersonalNote()}
            ref={deleteButtonRef}
          >Delete</PrimaryButton>
        </ButtonsWrapper>
      </form>
    </Div>
  )
}
