import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { StatusOptions as statusOptions } from '../../../options/StatusOptions';
import { Flex } from '../../primitives/Flex';
import { Span } from '../../primitives/Span';

export default function SelectColumnFilter({
  column: { filterValue, setFilter },
  gotoPage,
}) {
  return (
    <DropdownButton
      size='sm'
      id='dropdown-basic-button'
      title={filterValue === undefined
        ? 'All'
        : filterValue === ''
          ? '-'
          : filterValue}
    >
      <Dropdown.Item
        onClick={() => {
          setFilter(undefined)
          gotoPage(0)
        }}
        data-testid='tableheader-status-filter'
      >
        All
      </Dropdown.Item>
      {statusOptions.map((option) => {
        return (
          <Dropdown.Item
            onClick={() => {
              setFilter(option.value === '' ? '' : option.value)
              gotoPage(0)
            }}
            key={option.value}
            data-testid='tableheader-status-filter'
          >
            <Flex css={{ alignItems: 'center' }}>
              {option.value !== '' &&
                <Span css={{ color: option.color, mr: '12px', w: 'fit-content' }}>●</Span>
              }
              {option.value === '' ? '-' : option.value}
            </Flex>
          </Dropdown.Item>
        )
      })}
    </DropdownButton>
  )
}
