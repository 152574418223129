import { useState, useMemo, useEffect } from 'react'
import { useTable, usePagination, useSortBy, useFilters } from 'react-table'
import { Table, TableHeader, TableData, TableRow } from '../../components/primitives/Table';
import { Box } from '../../components/primitives/Box'
import { PagePadding } from '../../components/primitives/PagePadding'
import { Header1, Paragraph } from '../../components/primitives/Text'
import { Tab, TabWrapper } from '../../components/primitives/Tab'
import { FlexSpaceBetween } from '../../components/primitives/FlexSpaceBetween';
import { Modal } from '../../modals';
import { useInvitations } from '../../queries/useInvitations';
import { ReactComponent as AddUserIcon } from '../../assets/icons/add-user.svg'
import { Flex } from '../../components/primitives/Flex';
import { IconInsideButton } from '../../components/primitives/IconWrapper';
import InviteNewUser from '../../modals/InviteNewUser';
import UserFeedback from '../../components/patterns/UserFeedback';
import ResendInvitation from '../../modals/ResendInvitation';
import CancelInvitation from '../../modals/CancelInvitation';
import DeactivateUser from '../../modals/DeactivateUser'
import { Div } from '../../components/primitives/Div';
import { useMyFounders } from '../../queries/useMyFounders';

export default function MyFounders() {

  const [displayedTab, setDisplayedTab] = useState('active')
  const [userFeedback, setUserFeedback] = useState(false)
  const [activeCount, setActiveCount] = useState(null)
  const [invitedCount, setInvitedCount] = useState(null)
  const [deactivatedCount, setDeactivatedCount] = useState(null)

  document.title = 'My Founders'

  const { data: myFoundersData } = useMyFounders()
  // console.log(myFoundersData?.myFounders)
  const { data: invitationData } = useInvitations()
  // console.log(invitationData?.invitations)

  useEffect(() => {
    setActiveCount(myFoundersData?.myFounders.filter(founder => founder.isActive).length)
    setDeactivatedCount(myFoundersData?.myFounders.filter(founder => !founder.isActive).length)
    setInvitedCount(invitationData?.invitations.length)
  }, [myFoundersData, invitationData])

  //---------------------react table-----------------------------

  const columns = useMemo(() => [
    // { Header: 'Uuid', accessor: 'uuid' },
    // { Header: 'Id', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Organization', accessor: 'organization' },
    { Header: 'Status', accessor: 'isActive' }
  ], [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex }
  } = useTable({
    autoResetFilters: false,
    autoResetPage: false,
    autoResetSortBy: false,
    columns,
    data:
      !myFoundersData
        ? []
        : displayedTab === 'active'
          ? myFoundersData?.myFounders.filter(founder => founder.isActive)
          : displayedTab === 'deactivated'
            ? myFoundersData?.myFounders.filter(founder => !founder.isActive)
            : displayedTab === 'invited'
            && invitationData?.invitations,
    initialState: {
      pageIndex: 0,
      pageSize: 25,
    },
    getSubRows: (row) => row.subRows
  },
    useFilters,
    useSortBy,
    usePagination,
  )

  //-------------------------------------------------------------

  return (
    <>
      {userFeedback && <UserFeedback userFeedback={userFeedback} setUserFeedback={setUserFeedback} />}

      <PagePadding>
        <FlexSpaceBetween css={{ mb: '$16' }}>
          <Header1>My Founders</Header1>
          <Modal
            triggerButtonLabel={(
              <Flex css={{ ai: 'center' }}>
                Invite&nbsp;new&nbsp;user
                <IconInsideButton css={{ ml: '3px', mb: '1px' }}><AddUserIcon /></IconInsideButton>
              </Flex>
            )}
            Component={InviteNewUser}
            title={'Invite new user'}
            setUserFeedback={setUserFeedback}
          />
        </FlexSpaceBetween>

        <Box>
          <TabWrapper>
            <Tab
              onClick={() => setDisplayedTab('active')}
              displayedTab={displayedTab === 'active'}
            >
              Active
              ({activeCount})
            </Tab>
            <Tab
              onClick={() => setDisplayedTab('invited')}
              displayedTab={displayedTab === 'invited'}
            >
              Invited
              ({invitedCount})
            </Tab>
            <Tab
              onClick={() => setDisplayedTab('deactivated')}
              displayedTab={displayedTab === 'deactivated'}
            >
              Deactivated
              ({deactivatedCount})
            </Tab>
          </TabWrapper>

          {
            (displayedTab === 'active' && !activeCount >= 1) ||
              (displayedTab === 'invited' && !invitedCount >= 1) ||
              (displayedTab === 'deactivated' && !deactivatedCount >= 1)
              ?
              <Paragraph css={{ mt: '$32' }}>There are no {displayedTab} users</Paragraph>
              :
              <Div css={{ overflowX: 'scroll' }}>
                <Table {...getTableProps()} css={{ mt: '$12', minWidth: '700px' }}>
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <TableHeader {...column.getHeaderProps()}>
                            {column.render('Header')}
                          </TableHeader>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                      prepareRow(row)
                      // console.log(row)
                      return (
                        <TableRow {...row.getRowProps()}>
                          {row.cells.map(cell => {
                            // console.log(cell)
                            return (
                              <TableData {...cell.getCellProps()}>
                                {
                                  cell.column.Header === 'Status'
                                    ? cell.value === undefined
                                      ? 'INVITED'
                                      : cell.value === true
                                        ? 'ACTIVE'
                                        : 'DEACTIVATED'
                                    : cell.render('Cell')
                                }
                              </TableData>
                            )
                          })}

                          {displayedTab === 'invited'
                            ? <TableData css={{ width: '250px', '& button:nth-child(1)': { mr: '$12' } }}>
                              <Modal
                                triggerButtonStyle={'smallSecondary'}
                                triggerButtonLabel={'Resend invitation'}
                                Component={ResendInvitation}
                                invitationUuid={row.original.uuid}
                                setUserFeedback={setUserFeedback}
                              />
                              <Modal
                                triggerButtonStyle={'smallSecondary'}
                                triggerButtonLabel={'Cancel invitation'}
                                Component={CancelInvitation}
                                invitationUuid={row.original.uuid}
                                setUserFeedback={setUserFeedback}
                              />
                            </TableData>
                            : displayedTab === 'active'
                            && <TableData css={{ width: '124px' }}>
                              <Modal
                                triggerButtonStyle={'smallSecondary'}
                                triggerButtonLabel={'Deactivate user'}
                                Component={DeactivateUser}
                                userId={row.original.id}
                                setUserFeedback={setUserFeedback}
                              />
                            </TableData>
                          }
                        </TableRow>
                      )
                    })}
                  </tbody>
                </Table>
              </Div>
          }
        </Box>
      </PagePadding>
    </>
  )
}
