import { Box } from '../../../components/primitives/Box'
import { Flex } from '../../../components/primitives/Flex'
import { FlexSpaceBetween } from '../../../components/primitives/FlexSpaceBetween'
import { BigCompanyLogo } from '../../../components/primitives/CompanyLogo'
import { Div } from '../../../components/primitives/Div'
import { Header1 } from '../../../components/primitives/Text'
import { ModifiedBadge } from '../../../components/patterns/ModifiedMark'
import { Modal } from '../../../modals'
import { formatCountryCode } from '../../../utils/format/formatCountryCode'
import { ReactComponent as HomepageIcon } from '../../../assets/icons/social-links/globe.svg'
import { ReactComponent as CbIcon } from '../../../assets/icons/social-links/crunchbase.svg'
import { ReactComponent as TwitterIcon } from '../../../assets/icons/social-links/twitter.svg'
import { ReactComponent as LinkedinIcon } from '../../../assets/icons/social-links/linkedin.svg'
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import { ReactComponent as NautaIcon } from '../../../assets/icons/nauta.svg'
import { ReactComponent as AddIcon } from '../../../assets/icons/add.svg'
import { SocialLinksIcons, SmallIcon, IconInsideButton } from '../../../components/primitives/IconWrapper'
import AddSelectedInvestorsToList from '../../../modals/AddSelectedInvestorsToList'
import EditInvesterProfile from '../../../modals/EditInvesterProfile'
import SummaryDetail from './SummaryDetail'
import { BoxWrapper } from '../../../components/primitives/Box'
import { ContactLabel } from '../../../components/patterns/ContactLabel'
import { CoInvestorLabel } from '../../../components/patterns/CoInvestorLabel'
import { Span } from '../../../components/primitives/Span'


export default function Summary({ investor, extra, uuid, setUserFeedback }) {

  const userType = localStorage.getItem('usertype')

  return (
    <BoxWrapper>
      <Box>
        <Flex>
          <BigCompanyLogo 
          src={investor.logoUrlSmall ? investor.logoUrlSmall : investor.logoUrl} 
          onError={(e) => (e.currentTarget.src = require('../../../assets/images/fallback-img-company-lg.png'))}
          />
          <Div>
            <FlexSpaceBetween css={{ '@bp3': { fd: 'column', ai: 'flex-start' } }}>
              <Flex css={{ jc: 'center' , '@bp3': {fd: 'column', ai: 'flex-start'} }}>
                <Header1>{extra.name ? extra.name : investor.name}</Header1>
                {extra && extra.name !== investor.name &&
                  <ModifiedBadge updatedByName={extra.updated_by_name} updatedAt={extra.updated_at} />
                }

                <Span css={{w: 'fit-content', '@bp3': {}}}>
                  {(investor.contacts && investor.contacts.length > 0) &&
                    <ContactLabel uuid={uuid} />
                  }
                  {(investor.nautaCoInvestor && investor.nautaCoInvestor !== '' && investor.nautaCoInvestor !== null) &&
                    <CoInvestorLabel nautaCoInvestor={investor.nautaCoInvestor} />
                  }
                </Span>

              </Flex>

              <Flex css={{ '&>button:nth-child(1)': { mr: '$6' }, '@bp3': { my: '$10' } }}>
                <Modal
                  triggerButtonLabel={(
                    <>
                      <Flex css={{ ai: 'center', '@bp1': { display: 'none' } }}>
                        Add to list
                        <IconInsideButton><AddIcon /></IconInsideButton>
                      </Flex>
                      <IconInsideButton css={{ display: 'none', '@bp1': { display: 'block', margin: '0px' } }}><AddIcon /></IconInsideButton>
                    </>
                  )}
                  triggerButtonStyle={'secondary'}
                  title={'Select list'}
                  Component={AddSelectedInvestorsToList}
                  selectedInvestors={uuid}
                  setUserFeedback={setUserFeedback}
                />

                {(userType === '3' || userType === '4') &&
                  <Modal
                    triggerButtonLabel={(
                      <>
                        <Flex css={{ ai: 'center', '@bp1': { display: 'none' } }}>
                          Edit
                          <IconInsideButton><EditIcon /></IconInsideButton>
                        </Flex>
                        <IconInsideButton css={{ display: 'none', '@bp1': { display: 'block', margin: '0px' } }}><EditIcon /></IconInsideButton>
                      </>
                    )}
                    title={'Edit Investor Information'}
                    triggerButtonStyle={'secondary'}
                    Component={EditInvesterProfile}
                    setUserFeedback={setUserFeedback}
                  />
                }

              </Flex>
            </FlexSpaceBetween>

            {investor.countryCode || extra.country_code
              ? <Flex>
                <SmallIcon css={{ mr: '$4', mt: '-2px' }}>
                  <LocationIcon />
                </SmallIcon>
                <Div css={{ mt: '$4', fontSize: '$13', color: '$grey500', fontWeight: '$300' }}>
                  {extra.city ? extra.city : investor.city}
                  {extra && extra.city !== investor.city ? <ModifiedBadge updatedByName={extra.updated_by_name} updatedAt={extra.updated_at} /> : ', '}
                  {extra.country_code ? formatCountryCode(extra.country_code) : formatCountryCode(investor.countryCode)}
                  {extra && extra.country_code !== investor.countryCode && <ModifiedBadge updatedByName={extra.updated_by_name} updatedAt={extra.updated_at} />}
                </Div>
              </Flex>
              : <></>
            }

            <Div css={{ mt: '$4', fontSize: '$13', color: '$grey500', fontWeight: '$300' }}>
              {extra.short_description ? extra.short_description : investor.shortDescription}
              {extra
                && extra.short_description !== investor.shortDescription
                && extra.short_description !== undefined
                && investor.shortDescription !== ''
                && <ModifiedBadge updatedByName={extra.updated_by_name} updatedAt={extra.updated_at} />
              }
              {/* show modified badge when empty at first, but added info on edit */}
              {investor.shortDescription === '' && extra.short_description !== undefined
                && <ModifiedBadge updatedByName={extra.updated_by_name} updatedAt={extra.updated_at} />}
            </Div>

            <SocialLinksIcons>
              {(userType === '3' || userType === '4') && <a href={`https://www.nautadb.com/companies/${investor.permalink}?uuid=${investor.uuid}`} target="_blank" rel="noreferrer"><NautaIcon /></a>}

              {investor.homepageUrl && <a href={extra.homepage_url ? extra.homepage_url : investor.homepageUrl} target="_blank" rel="noreferrer"><HomepageIcon /></a>}
              {extra
                && extra.homepage_url !== investor.homepageUrl
                && extra.homepage_url !== undefined
                && investor.homepageUrl !== ''
                && <ModifiedBadge updatedByName={extra.updated_by_name} updatedAt={extra.updated_at} />
              }

              {investor.cbUrl && <a href={investor.cbUrl} target="_blank" rel="noreferrer"><CbIcon /></a>}

              {extra.linkedin_url
                ? <a href={extra.linkedin_url} target="_blank" rel="noreferrer"><LinkedinIcon /></a>
                : investor.linkedinUrl
                && <a href={investor.linkedinUrl} target="_blank" rel="noreferrer"><LinkedinIcon /></a>
              }
              {extra
                && extra.linkedin_url !== investor.linkedinUrl
                && extra.linkedin_url !== undefined
                && investor.linkedinUrl !== ''
                && <ModifiedBadge updatedByName={extra.updated_by_name} updatedAt={extra.updated_at} />
              }
              {/* show modified badge when empty at first, but added info on edit */}
              {investor.linkedinUrl === '' && extra.linkedin_url !== undefined
                && <ModifiedBadge updatedByName={extra.updated_by_name} updatedAt={extra.updated_at} />}

              {extra.twitter_url
                ? <a href={extra.twitter_url} target="_blank" rel="noreferrer"><TwitterIcon /></a>
                : investor.twitterUrl
                && <a href={investor.twitterUrl} target="_blank" rel="noreferrer"><TwitterIcon /></a>
              }
              {extra
                && extra.twitter_url !== investor.twitterUrl
                && extra.twitter_url !== undefined
                && investor.twitterUrl !== ''
                && <ModifiedBadge updatedByName={extra.updated_by_name} updatedAt={extra.updated_at} />
              }
              {/* show modified badge when empty at first, but added info on edit */}
              {investor.twitterUrl === '' && extra.twitter_url !== undefined
                && <ModifiedBadge updatedByName={extra.updated_by_name} updatedAt={extra.updated_at} />}
            </SocialLinksIcons>
          </Div>
        </Flex>

        <SummaryDetail investor={investor} extra={extra} />

      </Box>
    </BoxWrapper>
  )
}
