import { useRef } from 'react'
import { PrimaryButton, SecondaryButton, ButtonsWrapper } from '../../components/primitives/Button'
import { Header2 } from '../../components/primitives/Text'
import { useMutation } from '@apollo/client'
import { GET_CUSTOM_LISTS_PAGINATED } from '../../queries/useCustomListsPaginated'
import { DELETE_CUSTOM_LISTS } from '../../mutations/deleteCustomList'
import { useNavigate } from 'react-router-dom';
import { Div } from '../../components/primitives/Div'

export default function DeleteList({ close, listName, listUuid, setAllChecked, setListUuid, setUserFeedback, hasCollaborator }) {

  const navigate = useNavigate();

  const deleteButtonRef = useRef(null)

  const [deleteList] = useMutation(DELETE_CUSTOM_LISTS, {
    variables: {
      uuids: listUuid.toString()
    },
    refetchQueries: [
      { query: GET_CUSTOM_LISTS_PAGINATED }
      // { query: GET_CUSTOM_LISTS_PAGINATED, variables: { page: 1, pageSize: 25 } },
      // { query: GET_CUSTOM_LISTS_PAGINATED, variables: { page: 1, pageSize: 9999 } },
    ],
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: () => {
      close()
      setUserFeedback({ success: true, text: 'List deleted' })
      setAllChecked && setAllChecked(false)
      { typeof (listUuid) === 'object' && setListUuid([]) }
      setTimeout(() => {
        { new URLSearchParams(window.location.search).get('uuid') !== null && navigate('/my-lists') }
      }, 1500)
    }
  })

  return (
    <Div
      data-testid="deletelist-modal"
      onKeyPress={(event) => {
        const { key } = event;
        if (key === "Enter") {
          deleteButtonRef.current.click();
        }
      }}>


      <Header2 css={{ my: '$24' }} >
        {hasCollaborator &&
          <>
            <span>
              This list would be deleted for all collaborators.
            </span>
            <br />
          </>
        }
        Are you sure you want to delete
        {listName
          ? <b>&nbsp;{listName}?</b>
          : '\u00A0the selected lists?'
        }
      </Header2>


      <ButtonsWrapper>
        <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
        <PrimaryButton
          type='button'
          onClick={() => deleteList()}
          ref={deleteButtonRef}
        >
          Delete
        </PrimaryButton>
      </ButtonsWrapper>
    </Div>
  )
}


