export const RoundSizeOptions = [
  { label: "-", value: null},
  { label: "$0K", value: 0 },
  { label: "$50K", value: 50000 },
  { label: "$100K", value: 100000 },
  { label: "$250K", value: 250000 },
  { label: "$500K", value: 500000 },
  { label: "$1M", value: 1000000 },
  { label: "$2M", value: 2000000 },
  { label: "$5M", value: 5000000 },
  { label: "$10M", value: 10000000 },
  { label: "$15M", value: 15000000 },
  { label: "$20M", value: 20000000 },
  { label: "$30M", value: 30000000 },
  { label: "$40M", value: 40000000 },
  { label: "$50M", value: 50000000 },
  { label: "+$50M", value: 50000001 },
]

export const RoundSizeWithoutBlankOption = [
  { label: "$0K", value: 0 },
  { label: "$50K", value: 50000 },
  { label: "$100K", value: 100000 },
  { label: "$250K", value: 250000 },
  { label: "$500K", value: 500000 },
  { label: "$1M", value: 1000000 },
  { label: "$2M", value: 2000000 },
  { label: "$5M", value: 5000000 },
  { label: "$10M", value: 10000000 },
  { label: "$15M", value: 15000000 },
  { label: "$20M", value: 20000000 },
  { label: "$30M", value: 30000000 },
  { label: "$40M", value: 40000000 },
  { label: "$50M", value: 50000000 },
  { label: "+$50M", value: 50000001 },
]