import { useState } from 'react'
import { Header4 } from '../primitives/Text'
import { styled } from '../../styles'
import { Div } from '../primitives/Div'
import { Span } from '../primitives/Span'
import { SecondaryButton } from '../primitives/Button'
import { SmallCompanyLogo } from '../primitives/CompanyLogo'
// import { ReactComponent as CommonIcon } from '../../assets/icons/common.svg'
// import { SmallIcon } from '../primitives/IconWrapper'
// import { Flex } from '../primitives/Flex'
// import { GET_COMMON_INVESTMENTS } from '../../queries/useCommonInvestments'
// import { useLazyQuery } from '@apollo/client'
// import ClickAwayListener from 'react-click-away-listener';
// import { Loader } from '../primitives/Loader'
// import { SmallCompanyLogo } from '../primitives/CompanyLogo'

export default function Mosaic({ data, pageSize, setPageSize, label, investorUuid }) {

  // const [openBoxUuid, setOpenBoxUuid] = useState('')
  // console.log({ openBoxUuid })
  // const [getCommonInvestments, { data: commonInvestmentsData, loading: commonInvestmentsLoading, error: commonInvestmentsError }] = useLazyQuery(GET_COMMON_INVESTMENTS)
  // console.log(commonInvestmentsData)

  return (
    <>
      <CompanyNamesAndLogos >
        {data?.objects.slice(0, pageSize).map((obj, index) => {
          return (
            <Span
              css={{ position: 'relative', display: 'flex' }}
              key={`${obj.uuid}_${index}`}
            >
              <Span>
                <Header4 css={{ color: '$grey300', fontWeight: '600' }}>
                  {obj.name}
                  {/* {label === 'co-investors' &&
                    <SmallIcon
                      onClick={() => {
                        getCommonInvestments({
                          variables: {
                            investorUuid1: investorUuid,
                            investorUuid2: obj.uuid
                          }
                        })
                        setOpenBoxUuid(obj.uuid)
                      }}
                      css={{ display: 'inline-block', w: '12px', ml: '$4', mr: '0px', '&:hover': { color: '$grey600' } }}
                    >
                      <CommonIcon />
                    </SmallIcon>
                  } */}
                </Header4>
                <Div>
                  <a href={obj.homepageUrl} target='_blank' rel="noreferrer">
                    <SmallCompanyLogo
                      src={obj.logoUrlSmall ? obj.logoUrlSmall : obj.logoUrl}
                      alt={obj.name}
                      css={{w: '46px', h: '46px', mr: '$0'}}
                      onError={(e) => (e.currentTarget.src = require('../../assets/images/fallback-img-company-lg.png'))}
                    />
                  </a>
                </Div>
              </Span>

              {/* {openBoxUuid === obj.uuid &&
                <Div
                  css={{ position: 'absolute', zIndex: '500', left: 135 }}
                  className='box-display'
                >
                  <ClickAwayListener onClickAway={() => setOpenBoxUuid('')}>
                    <CommonInvestorsBox>
                      <Header4
                        css={{ whiteSpace: 'nowrap', mt: '0', fontWeight: '500', fontSize: '$14', textAlign: 'left', color: '$grey500' }}
                      >
                        Co-investors in common with {obj.name}
                      </Header4>
                      {commonInvestmentsLoading && <Loader size='20' />}
                      {commonInvestmentsData?.commonInvestments.map((inv) => {
                        return (
                          <a href={inv.homepageUrl} target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}>
                            <Flex css={{ mb: '$8' }}>
                              <img src={inv.logoUrl} alt={inv.name} />
                              <Paragraph css={{ whiteSpace: 'nowrap' }}>{inv.name}</Paragraph>
                            </Flex>
                          </a>
                        )
                      })}
                    </CommonInvestorsBox>
                  </ClickAwayListener>
                </Div>
              } */}
            </Span>
          )
        })}
      </CompanyNamesAndLogos>

      {data?.totalCount > pageSize &&
        <Div css={{ display: 'flex', justifyContent: 'center' }}>
          <SecondaryButton onClick={() => setPageSize(pageSize + 30)}>
            See more {label}
          </SecondaryButton>
        </Div>
      }

    </>
  )
}


const CompanyNamesAndLogos = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
  mb: '$24',
  '@bp2': {
    gridTemplateColumns: 'repeat(auto-fit, 90px)',
  },
  '&>span': {
    textAlign: 'center',
    '&>a': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  }
})

// const CommonInvestorsBox = styled('div', {
//   border: '1px solid $grey100',
//   bgc: 'white',
//   width: 'fit-content',
//   boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
//   padding: '24px',
//   paddingBottom: '$16',
//   '& img': {
//     width: '24px',
//     height: '24px',
//     objectFit: 'contain',
//     mr: '$8'
//   }
// })

