import { useState } from 'react'
import { styled } from '../../../../../../styles'
import { Input } from '../../../../../../components/primitives/Input'
import { PrimaryButton } from '../../../../../../components/primitives/Button'
import { Loader } from '../../../../../../components/primitives/Loader'
import { Flex } from '../../../../../../components/primitives/Flex'
import { SmallCompanyLogo } from '../../../../../../components/primitives/CompanyLogo'
import { IconInsideButton } from '../../../../../../components/primitives/IconWrapper'
import { Paragraph } from '../../../../../../components/primitives/Text'
import { ReactComponent as AddIcon } from '../../../../../../assets/icons/add-filled.svg'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_INVESTORS } from '../../../../../../queries/useInvestors'
import { ADD_INVESTORS_TO_CUSTOM_LIST } from '../../../../../../mutations/addInvestorsToCustomList'

export default function AddInvestor({ uuid, setUserFeedback, getCustomListInvestor }) {

  const [name, setName] = useState('')
  const [investorUuidToAdd, setInvestorUuidToAdd] = useState(null)
  const [addInvestorSearchResultBox, setAddInvestorSearchResultBox] = useState(false)

  const [getInvestors, { error: getInvestorsError, data: getInvestorsData, loading: getInvestorsLoading }] = useLazyQuery(GET_INVESTORS, {
    variables: {
      searchbox: name,
      pageSize: 5,
    }
  })

  const [addInvestorsToCustomList] = useMutation(ADD_INVESTORS_TO_CUSTOM_LIST, {
    variables: {
      listUuid: uuid,
      investorUuids: investorUuidToAdd,
    },
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
      
    },
    onCompleted: () => {
      getCustomListInvestor({variables: {investorUuid: investorUuidToAdd, listUuid: uuid}})
      setUserFeedback({ success: true, text: 'Investor added to list' })
    }
  })

  return (
    <InputAndButtonWrapper css={{ p: 'relative', w: '400px', '@bp1': { w: '300px' } }}>
      <Input
        css={{ mr: '$4', py: '$6' }}
        value={name}
        placeholder='Search investor...'
        onChange={
          (e) => {
            setName(e.target.value);
            getInvestors();
            setAddInvestorSearchResultBox(true)
          }
        }
      />
      <PrimaryButton onClick={() => addInvestorsToCustomList()} disabled={name === ''}>
        <Flex css={{ ai: 'center' }}>
          Add&nbsp;investor
          <IconInsideButton><AddIcon /></IconInsideButton>
        </Flex>
      </PrimaryButton>
      {getInvestorsLoading &&
        <AddInvestorSearchResult>
          <div css={{ width: '14px' }}>
            <Loader size="20" css={{ d: 'inline-block', mt: '$8', ml: '$8' }} data-testid='loader'/>
          </div>
        </AddInvestorSearchResult>
      }
      {getInvestorsError &&
        <AddInvestorSearchResult>
          <Paragraph>
            An error occurred. Please try again or report this bug.
          </Paragraph>
        </AddInvestorSearchResult>
      }
      {name !== '' && getInvestorsData && addInvestorSearchResultBox &&
        <AddInvestorSearchResult>
          {getInvestorsData.search.objects.map((investor) => {
            return (
              <Flex
              data-testid='addinvestor-result'
                key={investor.uuid}
                onClick={() => {
                  setName(
                    investor.extra !== null
                      && investor.extra !== ''
                      && JSON.parse(investor.extra).name !== undefined
                      ? JSON.parse(investor.extra).name
                      : investor.name
                  );
                  setInvestorUuidToAdd(investor.uuid)
                  setAddInvestorSearchResultBox(false)
                }}
                css={{borderBottom: '1px solid $grey100'}}
              >
                <SmallCompanyLogo 
                src={investor.logoUrlSmall ? investor.logoUrlSmall : investor.logoUrl}
                 onError={(e) => (e.currentTarget.src = require('../../../../../../assets/images/fallback-img-company-lg.png'))}
                 />
                <Paragraph>
                  {investor.extra !== null
                    && investor.extra !== ''
                    && JSON.parse(investor.extra).name !== undefined
                    ? JSON.parse(investor.extra).name
                    : investor.name}
                </Paragraph>
              </Flex>
            )
          })}
        </AddInvestorSearchResult>
      }
    </InputAndButtonWrapper>

  )
}


const InputAndButtonWrapper = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '12px',
})

const AddInvestorSearchResult = styled('div', {
  zIndex: '100',
  position: 'absolute',
  background: '$white',
  width: '266px',
  top: '110px',
  right: '530px',
  boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
  borderRadius: '4px',
  border: '1px solid $grey100',
  '&>div': {
    padding: '8px 18px',
    '&:hover': {
      backgroundColor: '$grey100',
      cursor: 'pointer'
    }
  },
  '@bp3': {
    background: 'white',
    top: '150px',
    left: '24px',
    zIndex: '100'
  }
})