import { gql } from '@apollo/client'

export const GET_ASSIGNED_CONTACT = gql`
query assigned_contacts (
  $page: Int
  $pageSize: Int
) {
  assignedContacts (
    page: $page
    pageSize: $pageSize
  ) {
    page
    pages
    hasNext
    hasPrev
    count
    totalCount
    objects{
      uuid
      name
      email
      comment
      investor{
        uuid
        name
        logoUrl
        logoUrlSmall
        twitterUrl
        linkedinUrl
        cbUrl
        homepageUrl
        permalink
        extra
      }
    }
  }
}
`