import { styled } from "../../styles";

export const EditableInput = styled('span', {
  '&>textarea, &>input': {
    backgroundColor: '$grey100',
    height: 'min-content',
    // lineHeight: '18px',
    width: '100%',
    outline: 'none',
    border: 'none',
    borderRadius: '4px',
    resize: 'none',
    color: '$grey600',
    '&:focus': {
      outline: '2px solid #eaeaea',
      background: 'white',
    },
    '&:hover': {
      background: '$grey200',
      cursor: 'pointer',
    }
  }
})