import { gql } from '@apollo/client'

export const CREATE_INVESTOR = gql`
mutation createInvestor(
  $city: String,
  $countryCode: String,
  $homepageUrl: String!,
  $linkedinUrl: String,
  $name: String!,
  $shortDescription: String,
) {
  createInvestor (
    city: $city,
    countryCode: $countryCode,
    homepageUrl: $homepageUrl,
    linkedinUrl: $linkedinUrl,
    name: $name,
    shortDescription: $shortDescription,
  ) {
    investor {
      uuid
      name
      permalink
      error
    }
  }
}
`