import { useState } from 'react'
import { Box } from '../../components/primitives/Box'
import { PagePadding } from '../../components/primitives/PagePadding'
import { Header1, Paragraph } from '../../components/primitives/Text'
import { Tab, TabWrapper } from '../../components/primitives/Tab'
import RequestRow from './components/RequestRow'
import { Div } from '../../components/primitives/Div'
import { Table, TableHeader } from '../../components/primitives/Table'
import { Loader } from '../../components/primitives/Loader'
import { ReactComponent as SentIcon } from '../../assets/icons/sent.svg'
import { ReactComponent as ReceivedIcon } from '../../assets/icons/received.svg'
import { Flex } from '../../components/primitives/Flex'
import { SmallIcon } from '../../components/primitives/IconWrapper'
import UserFeedback from '../../components/patterns/UserFeedback'
import { GET_INTRO_REQUESTS_SENT } from '../../queries/useIntroRequestsSent'
import { GET_INTRO_REQUESTS_RECEIVED } from '../../queries/useIntroRequestsReceived'
import { useQuery } from '@apollo/client'

export default function Intros() {

  const [displayedTab, setDisplayedTab] = useState('sent')
  const [userFeedback, setUserFeedback] = useState(false)

  document.title = 'Intros'

  const { error: receivedError, data: receivedData, loading: receivedLoading } = useQuery(GET_INTRO_REQUESTS_RECEIVED)
  const { error: sentError, data: sentData, loading: sentLoading } = useQuery(GET_INTRO_REQUESTS_SENT)
  // console.log(sentData?.introRequestsSent, receivedData?.introRequestsReceived)

  return (
    <PagePadding>
      {userFeedback && <UserFeedback userFeedback={userFeedback} setUserFeedback={setUserFeedback} />}

      <Header1 css={{ mb: '$16' }}>Intros</Header1>

      <Box>
        <TabWrapper>
          <Tab
            onClick={() => setDisplayedTab('sent')}
            displayedTab={displayedTab === 'sent'}
          >
            <Flex>
              Requests sent
              <SmallIcon css={{ ml: '$6', mb: '$' }}>
                <SentIcon />
              </SmallIcon>
            </Flex>
          </Tab>
          <Tab
            onClick={() => setDisplayedTab('received')}
            displayedTab={displayedTab === 'received'}
          >
            <Flex>
              Requests received
              <SmallIcon css={{ ml: '$6' }}>
                <ReceivedIcon />
              </SmallIcon>
            </Flex>
          </Tab>
        </TabWrapper>

        <Div css={{ overflowX: 'scroll' }}>

          {displayedTab === 'sent'
            ? sentLoading && <Loader size="20" css={{ d: 'inline-block', ml: '$8', mt: '$16' }} />
            : receivedLoading && <Loader size="20" css={{ d: 'inline-block', ml: '$8', mt: '$16' }} />
          }
          {displayedTab === 'sent'
            ? sentError && <Paragraph css={{ mt: '$20' }}>An error occurred. Please try again or report this bug.</Paragraph>
            : receivedError && <Paragraph css={{ mt: '$20' }}>An error occurred. Please try again or report this bug.</Paragraph>
          }
          {
            ((displayedTab === 'sent' && sentData?.introRequestsSent.length >= 1)
              || (displayedTab === 'received' && receivedData?.introRequestsReceived.length >= 1))
              ? <Table css={{ minWidth: '1050px', mt: '$20' }}>
                <thead>
                  <tr>
                    {/* <TableHeader>uuid</TableHeader> */}
                    <TableHeader>Investor</TableHeader>
                    <TableHeader>Contact</TableHeader>
                    {displayedTab === 'sent'
                      ? <TableHeader>Requested to</TableHeader>
                      : <TableHeader>Requested from</TableHeader>
                    }
                    <TableHeader>Requested at</TableHeader>
                    <TableHeader>Comment</TableHeader>
                    {displayedTab === 'received'
                      && <TableHeader>Status</TableHeader>
                    }
                  </tr>
                </thead>

                <tbody>
                  {displayedTab === 'sent'
                    ? sentData?.introRequestsSent.map((req) => {
                      return <RequestRow req={req} displayedTab={displayedTab} setUserFeedback={setUserFeedback} key={req.uuid} />
                    })
                    : displayedTab === 'received'
                    && receivedData?.introRequestsReceived.map((req) => {
                      return <RequestRow req={req} displayedTab={displayedTab} setUserFeedback={setUserFeedback} key={req.uuid} />
                    })
                  }
                </tbody>
              </Table>

              : (displayedTab === 'sent' && sentError) || (displayedTab === 'received' && receivedError)
                ? <></>
                : <Paragraph css={{ mt: '30px' }}>There are no intro requests {displayedTab}</Paragraph>
          }
        </Div>
      </Box>
    </PagePadding >
  )
}

