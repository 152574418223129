// import {styled} from '../../styles'
// export const RequiredMark = styled('span', {
//   color: '$red',
//   fontSize: '$12',
//   marginLeft: '$6',
// })

import {styled} from '../../styles'

export const RequiredMark =()=> {
  return (
    <Required>*</Required>
  )
}

const Required = styled('span', {
  color: '$red',
  fontSize: '$12',
  marginLeft: '$6',
})
