import { useState } from 'react'
import { Header2 } from '../../../../components/primitives/Text'
import { Box, BoxWrapper } from '../../../../components/primitives/Box'
import { Tab, TabWrapper } from '../../../../components/primitives/Tab'
import Companies from './components/Companies'
import FundingRounds from './components/FundingRounds'
import Categories from './components/Categories'

export default function Portfolio({investorUuid}) {

  const [displayedTab, setDisplayedTab] = useState('companies')

  return (
    <BoxWrapper>
      <Box>
        <Header2 css={{ mb: '$24' }}>Portfolio</Header2>
        <TabWrapper css={{mb: '$24'}}>
          <Tab
            onClick={() => setDisplayedTab('companies')}
            displayedTab={displayedTab === 'companies'}
          >
            Companies
          </Tab>
          <Tab
            onClick={() => setDisplayedTab('fundingRounds')}
            displayedTab={displayedTab === 'fundingRounds'}
          >
            Funding rounds
          </Tab>
          <Tab
            onClick={() => setDisplayedTab('categories')}
            displayedTab={displayedTab === 'categories'}
          >
            Categories
          </Tab>
        </TabWrapper>

        {
          displayedTab === 'companies'
            ? <Companies investorUuid={investorUuid}/>
            : displayedTab === 'fundingRounds'
              ? <FundingRounds investorUuid={investorUuid}/>
              : displayedTab === 'categories'
              && <Categories investorUuid={investorUuid}/>
        }

      </Box>
    </BoxWrapper>
  )
}
