import { gql } from '@apollo/client'

export const GET_CUSTOM_LISTS_PAGINATED = gql`
query custom_list_Paginated (
  # query customListPaginated (
  $page: Int
  $pageSize: Int
) {
  customListPaginated (
    page: $page
    pageSize: $pageSize
  ) {
    page
    pages
    hasNext
    hasPrev
    count
    totalCount
    objects {
      uuid
      name
      description
      creator {
        username
        name
      }
      createdAt
      updatedAt
      collaborators {
        collaborator {
          email
        }
      }
      investorsCount
    }
  }
}
`