import iso from 'iso-3166-1'

export const formatCountryCode = countryCode =>
  ((countryCode === 'GBR' || countryCode === 'GB') && 'United Kingdom') ||
  ((countryCode === 'USA' || countryCode === 'US') && 'United States') ||
  ((countryCode === 'ROM' || countryCode === 'RO') && 'Romania') ||
  ((countryCode === 'TAN' || countryCode === 'TZ') && 'Tanzania') ||
  ((countryCode === 'BAH' || countryCode === 'BS') && 'Bahamas') ||
  (countryCode &&
    countryCode.length === 3 &&
    iso.whereAlpha3(countryCode) !== undefined &&
    iso.whereAlpha3(countryCode).country) ||
  (countryCode &&
    countryCode.length === 2 &&
    iso.whereAlpha2(countryCode) !== undefined &&
    iso.whereAlpha2(countryCode).country) ||
  ''

// export const formatCountryToAlpha3 = countryString =>
//   (countryString === 'United States' && 'USA') ||
//   (countryString === 'United Kingdom' && 'GBR') ||
//   (countryString.length > 3 &&
//     iso.whereCountry(countryString) !== undefined &&
//     iso.whereCountry(countryString).alpha3) ||
//   'UNK'
