import { gql } from '@apollo/client'

export const GET_ORGANIZATIONS = gql`
query search_organization (
  $searchbox: String
  $page: Int
  $pageSize: Int
) {
  searchOrganization (
    searchbox: $searchbox
    page: $page
    pageSize: $pageSize
  ) {
    page
    pages
    totalCount
    objects {
      uuid
      name
      logoUrl
      logoUrlSmall
    }
  }
}
`