import {styled} from '../../styles'

export const IconInsideButton = styled('span', {
  width: '$16',
  margin: '-$2 $0 $2 $6',
})

export const LargeIcon = styled('div', {
  width: '37px',
  '&:hover': {
    cursor: 'pointer'
  }
})

export const MediumIcon = styled('div', {
  width: '$24',
  '&:hover': {
    cursor: 'pointer'
  }
})

export const SmallIcon = styled('div', {
  width: '$16',
  marginRight: '$8',
  '&:hover': {
    cursor: 'pointer'
  }
})

export const SocialLinksIcons = styled('div', {
  paddingTop: '8px',
  '& a': {    
    color: '$grey300',
    backgroundColor: '$grey100',
    borderRadius: '4px',
    padding: '1px 0px 3px',
    margin: '2px',
    '&:hover': {
      color: '$white',
      backgroundColor: '$secondary',
    },
    '& svg': {
      width: '22px',
      position: 'relative',
    },
  }
})

export const SortIcon = styled('div', {
  width: '10px',
  marginLeft: '$6',
  marginBottom: '2px',
  '& svg': {
    height: '14px'
  },
  '&:hover': {
    cursor: 'pointer'
  }
})