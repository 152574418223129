import { SmallCompanyLogo } from '../../primitives/CompanyLogo'
import { Flex } from '../../primitives/Flex'
import { Div } from '../../primitives/Div'
import { ContactLabel } from '../ContactLabel'
import { CoInvestorLabel } from '../CoInvestorLabel'
import { SmallLink } from '../../primitives/Link'
import { SocialLinksIcons } from '../../primitives/IconWrapper'
import { ReactComponent as HomepageIcon } from '../../../assets/icons/social-links/globe.svg'
import { ReactComponent as CbIcon } from '../../../assets/icons/social-links/crunchbase.svg'
import { ReactComponent as TwitterIcon } from '../../../assets/icons/social-links/twitter.svg'
import { ReactComponent as LinkedinIcon } from '../../../assets/icons/social-links/linkedin.svg'
import { ReactComponent as NautaIcon } from '../../../assets/icons/nauta.svg'
import { Header5 } from '../../primitives/Text'
import { Span } from '../../primitives/Span'
export default function LogoNameUrlSection({ investor, isPortfolioOrg }) {

  const userType = localStorage.getItem('usertype')

  return (
    <Flex /*css={{width: '236px'}}*/>
      <SmallCompanyLogo
        src={investor.logoUrlSmall ? investor.logoUrlSmall : investor.logoUrl}
        onError={(e) => (e.currentTarget.src = require('../../../assets/images/fallback-img-company-lg.png'))}
      />
      <Div>
        <Span css={{ position: 'relative', wordWrap: 'break-word' }}>
          {isPortfolioOrg
            ?
            <Header5>{investor.name}</Header5>
            :
            <SmallLink
              href={`/search-investors/${investor.permalink}?uuid=${investor.uuid}`}
              target="_blank"
              rel="noreferrer"
              css={{ whiteSpace: 'nowrap' }}
            >
              {
                investor.extra !== null
                  && investor.extra !== ''
                  && JSON.parse(investor.extra).name !== undefined
                  ? JSON.parse(investor.extra).name
                  : investor.name
              }
            </SmallLink>
          }
          {
            (investor.contacts && investor.contacts.length > 0) &&
            <ContactLabel uuid={investor.uuid} />
          }
          {
            (investor.nautaCoInvestor && investor.nautaCoInvestor !== '' && investor.nautaCoInvestor !== null) &&
            <CoInvestorLabel nautaCoInvestor={investor.nautaCoInvestor} />
          }
        </Span>

        <SocialLinksIcons>
          {!isPortfolioOrg && (userType === '3' || userType === '4') &&
            <a href={`https://www.nautadb.com/companies/${investor.permalink}?uuid=${investor.uuid}`}
              target="_blank"
              rel="noreferrer"
            >
              <NautaIcon />
            </a>
          }

          {(investor.extra !== null
            && investor.extra !== ''
            && investor.extra !== undefined
            && JSON.parse(investor.extra).homepage_url !== undefined)
            ? <a href={JSON.parse(investor.extra).homepage_url} target="_blank" rel="noreferrer"><HomepageIcon /></a>
            : investor.homepageUrl
            && <a href={investor.homepageUrl} target="_blank" rel="noreferrer"><HomepageIcon /></a>}

          {investor.cbUrl && <a href={investor.cbUrl} target="_blank" rel="noreferrer"><CbIcon /></a>}

          {(investor.extra !== null
            && investor.extra !== ''
            && investor.extra !== undefined
            && JSON.parse(investor.extra).linkedin_url !== undefined)
            ? <a href={JSON.parse(investor.extra).linkedin_url} target="_blank" rel="noreferrer"><LinkedinIcon /></a>
            : investor.linkedinUrl
            && <a href={investor.linkedinUrl} target="_blank" rel="noreferrer"><LinkedinIcon /></a>}

          {(investor.extra !== null
            && investor.extra !== ''
            && investor.extra !== undefined
            && JSON.parse(investor.extra).twitter_url !== undefined)
            ? <a href={JSON.parse(investor.extra).twitter_url} target="_blank" rel="noreferrer"><TwitterIcon /></a>
            : investor.twitterUrl
            && <a href={investor.twitterUrl} target="_blank" rel="noreferrer"><TwitterIcon /></a>}
        </SocialLinksIcons>
      </Div>
    </Flex>
  )
}
