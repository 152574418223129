import { gql } from "@apollo/client";

export const GET_INVESTOR_PORTFOLIO_FUNDING_ROUNDS = gql`
query (
  $investorUuid: String
  $page: Int
  $pageSize: Int
) {
  investorPortfolioFundingRounds (
    investorUuid: $investorUuid
    page: $page
    pageSize: $pageSize
  ) {
    totalCount
    hasNext
    objects {
      announcedOn
      cbUrl
      city
      countryCode
      name
      orgName
      raisedAmountUsd
      investmentType
      uuid
      orgUuid {
        uuid
        name
        categoryList
        homepageUrl
        cbUrl
        linkedinUrl
        twitterUrl
        logoUrlSmall
        logoUrl
      }
    }
  }
}
`