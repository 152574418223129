import { gql } from '@apollo/client'

export const ADD_SEARCH_RESULTS_TO_CUSTOM_LIST = gql`
mutation addSearchResultsToCustomList (
  $countryCodes: String
  $fundingRound: String
  $hasContact: String
  $investmentCount: Int
  $investmentUuids: String
  $investorType: String
  $listUuid: String!
  $lowerBound: Int
  $portfolioCategories: String
  $portfolioCountryCodes: String
  $searchbox: String
  $upperBound: Int
) {
  addSearchResultsToCustomList (
    countryCodes: $countryCodes
    fundingRound: $fundingRound
    hasContact: $hasContact
    investmentCount: $investmentCount
    investmentUuids: $investmentUuids
    investorType: $investorType
    listUuid: $listUuid
    lowerBound: $lowerBound
    portfolioCategories: $portfolioCategories
    portfolioCountryCodes: $portfolioCountryCodes
    searchbox: $searchbox
    upperBound: $upperBound
  ) {
    customList{
      uuid
      name

      investorsCount
      investors {
        investorUuid {
          name
        }
      }
    }
  }
}
`