//↓↓react-router-dom v17, works but shows warning message↓↓
// import React from 'react';
// import './App.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { ApolloProvider } from '@apollo/client';
// import { BrowserRouter } from 'react-router-dom';
// import { globalStyles } from './styles';
// import { render } from 'react-dom'
// import client from './client';

// const Index = () => {
//   globalStyles()
//   return (
//     <React.StrictMode>
//       <BrowserRouter>
//         <ApolloProvider client={client}>
//           <App />
//         </ApolloProvider>
//       </BrowserRouter>
//     </React.StrictMode>
//   )
// }
// render(<Index />, document.getElementById('root'))
// reportWebVitals();


//↓↓v18↓↓
import React from 'react';
import './App.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { globalStyles } from './styles';
import client from './client';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'


const Index = () => {
  globalStyles()
  return (
    <React.StrictMode>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </BrowserRouter>
    </React.StrictMode>
  )
}
const rootElement = document.getElementById('root')
const root = createRoot(rootElement);
root.render(
  <Index />
);
reportWebVitals();

