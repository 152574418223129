import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

export const DropdownBox = ({ options, item, setItem, setListUuid }) => {

  return (
    <>
      <DropdownButton
        id="dropdown-basic-button"
        autoClose={true}
        title={
          item === 'add new customlist'
            ? '[Create new list]'
            : item === null || item === [] || item === ''
              ? '-'
              : options?.map((option) => {
                return item === option.value && option.label
              })}
        size='sm'
        data-testid={'filter-dropdown'}
      >
        {options?.map((option, index) => {
          return (
            <Dropdown.Item
              onClick={() => {
                setItem(option.value)
                setListUuid && setListUuid(option.value)
              }}
              key={index}
              value={option.value}
            >
              {option.label}
            </Dropdown.Item>
          )
        })}

        {setListUuid
          && <Dropdown.Item
            onClick={() => setItem('add new customlist')}
          >[Create new list]</Dropdown.Item>
        }

      </DropdownButton>
    </>
  )
}


