import { gql } from '@apollo/client'

export const UPDATE_INTRO_REQUEST = gql`
mutation updateIntroRequest (
  $deleted: Boolean
  $done: Boolean
  $uuid: String!
) {
  updateIntroRequest (
    deleted: $deleted
    done: $done
    uuid: $uuid
  ) {
    introRequest {
      uuid
      requestedByDone
      requestedByDeleted
      requestedToDone
      requestedToDeleted
    }
  }
}
`