import { gql } from '@apollo/client'

export const SEND_INVITATION = gql`
mutation sendInvitation (
  $email: String!
  $name: String!
  $organization: String!
) {
  sendInvitation (
    email: $email
    name: $name
    organization: $organization
  ) {
    invitation {
      name
      email
      organization
    }
  }
}
`