export const CountryOptions = [
  { label: "Europe", value: "eur" },
  { label: "Aruba", value: "abw" },
  { label: "Afghanistan", value: "afg" },
  { label: "Angola", value: "ago" },
  { label: "Anguilla", value: "aia" },
  { label: "Åland Islands", value: "ala" },
  { label: "Albania", value: "alb" },
  { label: "Andorra", value: "and" },
  { label: "United Arab Emirates", value: "are" },
  { label: "Argentina", value: "arg" },
  { label: "Armenia", value: "arm" },
  { label: "American Samoa", value: "asm" },
  { label: "Antarctica", value: "ata" },
  { label: "French Southern Territories", value: "atf" },
  { label: "Antigua and Barbuda", value: "atg" },
  { label: "Australia", value: "aus" },
  { label: "Austria", value: "aut" },
  { label: "Azerbaijan", value: "aze" },
  { label: "Burundi", value: "bdi" },
  { label: "Belgium", value: "bel" },
  { label: "Benin", value: "ben" },
  { label: "Bonaire, Sint Eustatius and Saba", value: "bes" },
  { label: "Burkina Faso", value: "bfa" },
  { label: "Bangladesh", value: "bgd" },
  { label: "Bulgaria", value: "bgr" },
  { label: "Bahrain", value: "bhr" },
  { label: "Bahamas", value: "bhs" },
  { label: "Bosnia and Herzegovina", value: "bih" },
  { label: "Saint Barthélemy", value: "blm" },
  { label: "Belarus", value: "blr" },
  { label: "Belize", value: "blz" },
  { label: "Bermuda", value: "bmu" },
  { label: "Bolivia, Plurinational State of", value: "bol" },
  { label: "Brazil", value: "bra" },
  { label: "Barbados", value: "brb" },
  { label: "Brunei Darussalam", value: "brn" },
  { label: "Bhutan", value: "btn" },
  { label: "Bouvet Island", value: "bvt" },
  { label: "Botswana", value: "bwa" },
  { label: "Central African Republic", value: "caf" },
  { label: "Canada", value: "can" },
  { label: "Cocos (Keeling) Islands", value: "cck" },
  { label: "Switzerland", value: "che" },
  { label: "Chile", value: "chl" },
  { label: "China", value: "chn" },
  { label: "Côte d'Ivoire", value: "civ" },
  { label: "Cameroon", value: "cmr" },
  { label: "Congo, The Democratic Republic of the", value: "cod" },
  { label: "Congo", value: "cog" },
  { label: "Cook Islands", value: "cok" },
  { label: "Colombia", value: "col" },
  { label: "Comoros", value: "com" },
  { label: "Cabo Verde", value: "cpv" },
  { label: "Costa Rica", value: "cri" },
  { label: "Cuba", value: "cub" },
  { label: "Curaçao", value: "cuw" },
  { label: "Christmas Island", value: "cxr" },
  { label: "Cayman Islands", value: "cym" },
  { label: "Cyprus", value: "cyp" },
  { label: "Czechia", value: "cze" },
  { label: "Germany", value: "deu" },
  { label: "Djibouti", value: "dji" },
  { label: "Dominica", value: "dma" },
  { label: "Denmark", value: "dnk" },
  { label: "Dominican Republic", value: "dom" },
  { label: "Algeria", value: "dza" },
  { label: "Ecuador", value: "ecu" },
  { label: "Egypt", value: "egy" },
  { label: "Eritrea", value: "eri" },
  { label: "Western Sahara", value: "esh" },
  { label: "Spain", value: "esp" },
  { label: "Estonia", value: "est" },
  { label: "Ethiopia", value: "eth" },
  { label: "Finland", value: "fin" },
  { label: "Fiji", value: "fji" },
  { label: "Falkland Islands (Malvinas)", value: "flk" },
  { label: "France", value: "fra" },
  { label: "Faroe Islands", value: "fro" },
  { label: "Micronesia, Federated States of", value: "fsm" },
  { label: "Gabon", value: "gab" },
  { label: "United Kingdom", value: "gbr" },
  { label: "Georgia", value: "geo" },
  { label: "Guernsey", value: "ggy" },
  { label: "Ghana", value: "gha" },
  { label: "Gibraltar", value: "gib" },
  { label: "Guinea", value: "gin" },
  { label: "Guadeloupe", value: "glp" },
  { label: "Gambia", value: "gmb" },
  { label: "Guinea-Bissau", value: "gnb" },
  { label: "Equatorial Guinea", value: "gnq" },
  { label: "Greece", value: "grc" },
  { label: "Grenada", value: "grd" },
  { label: "Greenland", value: "grl" },
  { label: "Guatemala", value: "gtm" },
  { label: "French Guiana", value: "guf" },
  { label: "Guam", value: "gum" },
  { label: "Guyana", value: "guy" },
  { label: "Hong Kong", value: "hkg" },
  { label: "Heard Island and McDonald Islands", value: "hmd" },
  { label: "Honduras", value: "hnd" },
  { label: "Croatia", value: "hrv" },
  { label: "Haiti", value: "hti" },
  { label: "Hungary", value: "hun" },
  { label: "Indonesia", value: "idn" },
  { label: "Isle of Man", value: "imn" },
  { label: "India", value: "ind" },
  { label: "British Indian Ocean Territory", value: "iot" },
  { label: "Ireland", value: "irl" },
  { label: "Iran, Islamic Republic of", value: "irn" },
  { label: "Iraq", value: "irq" },
  { label: "Iceland", value: "isl" },
  { label: "Israel", value: "isr" },
  { label: "Italy", value: "ita" },
  { label: "Jamaica", value: "jam" },
  { label: "Jersey", value: "jey" },
  { label: "Jordan", value: "jor" },
  { label: "Japan", value: "jpn" },
  { label: "Kazakhstan", value: "kaz" },
  { label: "Kenya", value: "ken" },
  { label: "Kyrgyzstan", value: "kgz" },
  { label: "Cambodia", value: "khm" },
  { label: "Kiribati", value: "kir" },
  { label: "Saint Kitts and Nevis", value: "kna" },
  { label: "Korea, Republic of", value: "kor" },
  { label: "Kuwait", value: "kwt" },
  { label: "Lao People's Democratic Republic", value: "lao" },
  { label: "Lebanon", value: "lbn" },
  { label: "Liberia", value: "lbr" },
  { label: "Libya", value: "lby" },
  { label: "Saint Lucia", value: "lca" },
  { label: "Liechtenstein", value: "lie" },
  { label: "Sri Lanka", value: "lka" },
  { label: "Lesotho", value: "lso" },
  { label: "Lithuania", value: "ltu" },
  { label: "Luxembourg", value: "lux" },
  { label: "Latvia", value: "lva" },
  { label: "Macao", value: "mac" },
  { label: "Saint Martin (French part)", value: "maf" },
  { label: "Morocco", value: "mar" },
  { label: "Monaco", value: "mco" },
  { label: "Moldova, Republic of", value: "mda" },
  { label: "Madagascar", value: "mdg" },
  { label: "Maldives", value: "mdv" },
  { label: "Mexico", value: "mex" },
  { label: "Marshall Islands", value: "mhl" },
  { label: "North Macedonia", value: "mkd" },
  { label: "Mali", value: "mli" },
  { label: "Malta", value: "mlt" },
  { label: "Myanmar", value: "mmr" },
  { label: "Montenegro", value: "mne" },
  { label: "Mongolia", value: "mng" },
  { label: "Northern Mariana Islands", value: "mnp" },
  { label: "Mozambique", value: "moz" },
  { label: "Mauritania", value: "mrt" },
  { label: "Montserrat", value: "msr" },
  { label: "Martinique", value: "mtq" },
  { label: "Mauritius", value: "mus" },
  { label: "Malawi", value: "mwi" },
  { label: "Malaysia", value: "mys" },
  { label: "Mayotte", value: "myt" },
  { label: "Namibia", value: "nam" },
  { label: "New Caledonia", value: "ncl" },
  { label: "Niger", value: "ner" },
  { label: "Norfolk Island", value: "nfk" },
  { label: "Nigeria", value: "nga" },
  { label: "Nicaragua", value: "nic" },
  { label: "Niue", value: "niu" },
  { label: "Netherlands", value: "nld" },
  { label: "Norway", value: "nor" },
  { label: "Nepal", value: "npl" },
  { label: "Nauru", value: "nru" },
  { label: "New Zealand", value: "nzl" },
  { label: "Oman", value: "omn" },
  { label: "Pakistan", value: "pak" },
  { label: "Panama", value: "pan" },
  { label: "Pitcairn", value: "pcn" },
  { label: "Peru", value: "per" },
  { label: "Philippines", value: "phl" },
  { label: "Palau", value: "plw" },
  { label: "Papua New Guinea", value: "png" },
  { label: "Poland", value: "pol" },
  { label: "Puerto Rico", value: "pri" },
  { label: "Korea, Democratic People's Republic of", value: "prk" },
  { label: "Portugal", value: "prt" },
  { label: "Paraguay", value: "pry" },
  { label: "Palestine, State of", value: "pse" },
  { label: "French Polynesia", value: "pyf" },
  { label: "Qatar", value: "qat" },
  { label: "Réunion", value: "reu" },
  { label: "Romania", value: "rou" },
  { label: "Russian Federation", value: "rus" },
  { label: "Rwanda", value: "rwa" },
  { label: "Saudi Arabia", value: "sau" },
  { label: "Sudan", value: "sdn" },
  { label: "Senegal", value: "sen" },
  { label: "Singapore", value: "sgp" },
  { label: "South Georgia and the South Sandwich Islands", value: "sgs" },
  { label: "Saint Helena, Ascension and Tristan da Cunha", value: "shn" },
  { label: "Svalbard and Jan Mayen", value: "sjm" },
  { label: "Solomon Islands", value: "slb" },
  { label: "Sierra Leone", value: "sle" },
  { label: "El Salvador", value: "slv" },
  { label: "San Marino", value: "smr" },
  { label: "Somalia", value: "som" },
  { label: "Saint Pierre and Miquelon", value: "spm" },
  { label: "Serbia", value: "srb" },
  { label: "South Sudan", value: "ssd" },
  { label: "Sao Tome and Principe", value: "stp" },
  { label: "Surilabel", value: "sur" },
  { label: "Slovakia", value: "svk" },
  { label: "Slovenia", value: "svn" },
  { label: "Sweden", value: "swe" },
  { label: "Eswatini", value: "swz" },
  { label: "Sint Maarten (Dutch part)", value: "sxm" },
  { label: "Seychelles", value: "syc" },
  { label: "Syrian Arab Republic", value: "syr" },
  { label: "Turks and Caicos Islands", value: "tca" },
  { label: "Chad", value: "tcd" },
  { label: "Togo", value: "tgo" },
  { label: "Thailand", value: "tha" },
  { label: "Tajikistan", value: "tjk" },
  { label: "Tokelau", value: "tkl" },
  { label: "Turkmenistan", value: "tkm" },
  { label: "Timor-Leste", value: "tls" },
  { label: "Tonga", value: "ton" },
  { label: "Trinidad and Tobago", value: "tto" },
  { label: "Tunisia", value: "tun" },
  { label: "Turkey", value: "tur" },
  { label: "Tuvalu", value: "tuv" },
  { label: "Taiwan, Province of China", value: "twn" },
  { label: "Tanzania, United Republic of", value: "tza" },
  { label: "Uganda", value: "uga" },
  { label: "Ukraine", value: "ukr" },
  { label: "United States Minor Outlying Islands", value: "umi" },
  { label: "Uruguay", value: "ury" },
  { label: "United States", value: "usa" },
  { label: "Uzbekistan", value: "uzb" },
  { label: "Holy See (Vatican City State)", value: "vat" },
  { label: "Saint Vincent and the Grenadines", value: "vct" },
  { label: "Venezuela, Bolivarian Republic of", value: "ven" },
  { label: "Virgin Islands, British", value: "vgb" },
  { label: "Virgin Islands, U.S.", value: "vir" },
  { label: "Viet Nam", value: "vnm" },
  { label: "Vanuatu", value: "vut" },
  { label: "Wallis and Futuna", value: "wlf" },
  { label: "Samoa", value: "wsm" },
  { label: "Yemen", value: "yem" },
  { label: "South Africa", value: "zaf" },
  { label: "Zambia", value: "zmb" },
  { label: "Zimbabwe", value: "zwe" }
]


export const CountryOptionsWithBlankOption = [
  { label: "-", value: null },
  { label: "Europe", value: "eur" },
  { label: "Aruba", value: "abw" },
  { label: "Afghanistan", value: "afg" },
  { label: "Angola", value: "ago" },
  { label: "Anguilla", value: "aia" },
  { label: "Åland Islands", value: "ala" },
  { label: "Albania", value: "alb" },
  { label: "Andorra", value: "and" },
  { label: "United Arab Emirates", value: "are" },
  { label: "Argentina", value: "arg" },
  { label: "Armenia", value: "arm" },
  { label: "American Samoa", value: "asm" },
  { label: "Antarctica", value: "ata" },
  { label: "French Southern Territories", value: "atf" },
  { label: "Antigua and Barbuda", value: "atg" },
  { label: "Australia", value: "aus" },
  { label: "Austria", value: "aut" },
  { label: "Azerbaijan", value: "aze" },
  { label: "Burundi", value: "bdi" },
  { label: "Belgium", value: "bel" },
  { label: "Benin", value: "ben" },
  { label: "Bonaire, Sint Eustatius and Saba", value: "bes" },
  { label: "Burkina Faso", value: "bfa" },
  { label: "Bangladesh", value: "bgd" },
  { label: "Bulgaria", value: "bgr" },
  { label: "Bahrain", value: "bhr" },
  { label: "Bahamas", value: "bhs" },
  { label: "Bosnia and Herzegovina", value: "bih" },
  { label: "Saint Barthélemy", value: "blm" },
  { label: "Belarus", value: "blr" },
  { label: "Belize", value: "blz" },
  { label: "Bermuda", value: "bmu" },
  { label: "Bolivia, Plurinational State of", value: "bol" },
  { label: "Brazil", value: "bra" },
  { label: "Barbados", value: "brb" },
  { label: "Brunei Darussalam", value: "brn" },
  { label: "Bhutan", value: "btn" },
  { label: "Bouvet Island", value: "bvt" },
  { label: "Botswana", value: "bwa" },
  { label: "Central African Republic", value: "caf" },
  { label: "Canada", value: "can" },
  { label: "Cocos (Keeling) Islands", value: "cck" },
  { label: "Switzerland", value: "che" },
  { label: "Chile", value: "chl" },
  { label: "China", value: "chn" },
  { label: "Côte d'Ivoire", value: "civ" },
  { label: "Cameroon", value: "cmr" },
  { label: "Congo, The Democratic Republic of the", value: "cod" },
  { label: "Congo", value: "cog" },
  { label: "Cook Islands", value: "cok" },
  { label: "Colombia", value: "col" },
  { label: "Comoros", value: "com" },
  { label: "Cabo Verde", value: "cpv" },
  { label: "Costa Rica", value: "cri" },
  { label: "Cuba", value: "cub" },
  { label: "Curaçao", value: "cuw" },
  { label: "Christmas Island", value: "cxr" },
  { label: "Cayman Islands", value: "cym" },
  { label: "Cyprus", value: "cyp" },
  { label: "Czechia", value: "cze" },
  { label: "Germany", value: "deu" },
  { label: "Djibouti", value: "dji" },
  { label: "Dominica", value: "dma" },
  { label: "Denmark", value: "dnk" },
  { label: "Dominican Republic", value: "dom" },
  { label: "Algeria", value: "dza" },
  { label: "Ecuador", value: "ecu" },
  { label: "Egypt", value: "egy" },
  { label: "Eritrea", value: "eri" },
  { label: "Western Sahara", value: "esh" },
  { label: "Spain", value: "esp" },
  { label: "Estonia", value: "est" },
  { label: "Ethiopia", value: "eth" },
  { label: "Finland", value: "fin" },
  { label: "Fiji", value: "fji" },
  { label: "Falkland Islands (Malvinas)", value: "flk" },
  { label: "France", value: "fra" },
  { label: "Faroe Islands", value: "fro" },
  { label: "Micronesia, Federated States of", value: "fsm" },
  { label: "Gabon", value: "gab" },
  { label: "United Kingdom", value: "gbr" },
  { label: "Georgia", value: "geo" },
  { label: "Guernsey", value: "ggy" },
  { label: "Ghana", value: "gha" },
  { label: "Gibraltar", value: "gib" },
  { label: "Guinea", value: "gin" },
  { label: "Guadeloupe", value: "glp" },
  { label: "Gambia", value: "gmb" },
  { label: "Guinea-Bissau", value: "gnb" },
  { label: "Equatorial Guinea", value: "gnq" },
  { label: "Greece", value: "grc" },
  { label: "Grenada", value: "grd" },
  { label: "Greenland", value: "grl" },
  { label: "Guatemala", value: "gtm" },
  { label: "French Guiana", value: "guf" },
  { label: "Guam", value: "gum" },
  { label: "Guyana", value: "guy" },
  { label: "Hong Kong", value: "hkg" },
  { label: "Heard Island and McDonald Islands", value: "hmd" },
  { label: "Honduras", value: "hnd" },
  { label: "Croatia", value: "hrv" },
  { label: "Haiti", value: "hti" },
  { label: "Hungary", value: "hun" },
  { label: "Indonesia", value: "idn" },
  { label: "Isle of Man", value: "imn" },
  { label: "India", value: "ind" },
  { label: "British Indian Ocean Territory", value: "iot" },
  { label: "Ireland", value: "irl" },
  { label: "Iran, Islamic Republic of", value: "irn" },
  { label: "Iraq", value: "irq" },
  { label: "Iceland", value: "isl" },
  { label: "Israel", value: "isr" },
  { label: "Italy", value: "ita" },
  { label: "Jamaica", value: "jam" },
  { label: "Jersey", value: "jey" },
  { label: "Jordan", value: "jor" },
  { label: "Japan", value: "jpn" },
  { label: "Kazakhstan", value: "kaz" },
  { label: "Kenya", value: "ken" },
  { label: "Kyrgyzstan", value: "kgz" },
  { label: "Cambodia", value: "khm" },
  { label: "Kiribati", value: "kir" },
  { label: "Saint Kitts and Nevis", value: "kna" },
  { label: "Korea, Republic of", value: "kor" },
  { label: "Kuwait", value: "kwt" },
  { label: "Lao People's Democratic Republic", value: "lao" },
  { label: "Lebanon", value: "lbn" },
  { label: "Liberia", value: "lbr" },
  { label: "Libya", value: "lby" },
  { label: "Saint Lucia", value: "lca" },
  { label: "Liechtenstein", value: "lie" },
  { label: "Sri Lanka", value: "lka" },
  { label: "Lesotho", value: "lso" },
  { label: "Lithuania", value: "ltu" },
  { label: "Luxembourg", value: "lux" },
  { label: "Latvia", value: "lva" },
  { label: "Macao", value: "mac" },
  { label: "Saint Martin (French part)", value: "maf" },
  { label: "Morocco", value: "mar" },
  { label: "Monaco", value: "mco" },
  { label: "Moldova, Republic of", value: "mda" },
  { label: "Madagascar", value: "mdg" },
  { label: "Maldives", value: "mdv" },
  { label: "Mexico", value: "mex" },
  { label: "Marshall Islands", value: "mhl" },
  { label: "North Macedonia", value: "mkd" },
  { label: "Mali", value: "mli" },
  { label: "Malta", value: "mlt" },
  { label: "Myanmar", value: "mmr" },
  { label: "Montenegro", value: "mne" },
  { label: "Mongolia", value: "mng" },
  { label: "Northern Mariana Islands", value: "mnp" },
  { label: "Mozambique", value: "moz" },
  { label: "Mauritania", value: "mrt" },
  { label: "Montserrat", value: "msr" },
  { label: "Martinique", value: "mtq" },
  { label: "Mauritius", value: "mus" },
  { label: "Malawi", value: "mwi" },
  { label: "Malaysia", value: "mys" },
  { label: "Mayotte", value: "myt" },
  { label: "Namibia", value: "nam" },
  { label: "New Caledonia", value: "ncl" },
  { label: "Niger", value: "ner" },
  { label: "Norfolk Island", value: "nfk" },
  { label: "Nigeria", value: "nga" },
  { label: "Nicaragua", value: "nic" },
  { label: "Niue", value: "niu" },
  { label: "Netherlands", value: "nld" },
  { label: "Norway", value: "nor" },
  { label: "Nepal", value: "npl" },
  { label: "Nauru", value: "nru" },
  { label: "New Zealand", value: "nzl" },
  { label: "Oman", value: "omn" },
  { label: "Pakistan", value: "pak" },
  { label: "Panama", value: "pan" },
  { label: "Pitcairn", value: "pcn" },
  { label: "Peru", value: "per" },
  { label: "Philippines", value: "phl" },
  { label: "Palau", value: "plw" },
  { label: "Papua New Guinea", value: "png" },
  { label: "Poland", value: "pol" },
  { label: "Puerto Rico", value: "pri" },
  { label: "Korea, Democratic People's Republic of", value: "prk" },
  { label: "Portugal", value: "prt" },
  { label: "Paraguay", value: "pry" },
  { label: "Palestine, State of", value: "pse" },
  { label: "French Polynesia", value: "pyf" },
  { label: "Qatar", value: "qat" },
  { label: "Réunion", value: "reu" },
  { label: "Romania", value: "rou" },
  { label: "Russian Federation", value: "rus" },
  { label: "Rwanda", value: "rwa" },
  { label: "Saudi Arabia", value: "sau" },
  { label: "Sudan", value: "sdn" },
  { label: "Senegal", value: "sen" },
  { label: "Singapore", value: "sgp" },
  { label: "South Georgia and the South Sandwich Islands", value: "sgs" },
  { label: "Saint Helena, Ascension and Tristan da Cunha", value: "shn" },
  { label: "Svalbard and Jan Mayen", value: "sjm" },
  { label: "Solomon Islands", value: "slb" },
  { label: "Sierra Leone", value: "sle" },
  { label: "El Salvador", value: "slv" },
  { label: "San Marino", value: "smr" },
  { label: "Somalia", value: "som" },
  { label: "Saint Pierre and Miquelon", value: "spm" },
  { label: "Serbia", value: "srb" },
  { label: "South Sudan", value: "ssd" },
  { label: "Sao Tome and Principe", value: "stp" },
  { label: "Surilabel", value: "sur" },
  { label: "Slovakia", value: "svk" },
  { label: "Slovenia", value: "svn" },
  { label: "Sweden", value: "swe" },
  { label: "Eswatini", value: "swz" },
  { label: "Sint Maarten (Dutch part)", value: "sxm" },
  { label: "Seychelles", value: "syc" },
  { label: "Syrian Arab Republic", value: "syr" },
  { label: "Turks and Caicos Islands", value: "tca" },
  { label: "Chad", value: "tcd" },
  { label: "Togo", value: "tgo" },
  { label: "Thailand", value: "tha" },
  { label: "Tajikistan", value: "tjk" },
  { label: "Tokelau", value: "tkl" },
  { label: "Turkmenistan", value: "tkm" },
  { label: "Timor-Leste", value: "tls" },
  { label: "Tonga", value: "ton" },
  { label: "Trinidad and Tobago", value: "tto" },
  { label: "Tunisia", value: "tun" },
  { label: "Turkey", value: "tur" },
  { label: "Tuvalu", value: "tuv" },
  { label: "Taiwan, Province of China", value: "twn" },
  { label: "Tanzania, United Republic of", value: "tza" },
  { label: "Uganda", value: "uga" },
  { label: "Ukraine", value: "ukr" },
  { label: "United States Minor Outlying Islands", value: "umi" },
  { label: "Uruguay", value: "ury" },
  { label: "United States", value: "usa" },
  { label: "Uzbekistan", value: "uzb" },
  { label: "Holy See (Vatican City State)", value: "vat" },
  { label: "Saint Vincent and the Grenadines", value: "vct" },
  { label: "Venezuela, Bolivarian Republic of", value: "ven" },
  { label: "Virgin Islands, British", value: "vgb" },
  { label: "Virgin Islands, U.S.", value: "vir" },
  { label: "Viet Nam", value: "vnm" },
  { label: "Vanuatu", value: "vut" },
  { label: "Wallis and Futuna", value: "wlf" },
  { label: "Samoa", value: "wsm" },
  { label: "Yemen", value: "yem" },
  { label: "South Africa", value: "zaf" },
  { label: "Zambia", value: "zmb" },
  { label: "Zimbabwe", value: "zwe" }
]