import { gql } from '@apollo/client'

export const REQUEST_INTRO = gql`
mutation requestIntro (
  $commentText: String!, 
  $contactUuid: String!,
  $investorUuid: String!,
  ) {
    requestIntro (
      commentText: $commentText,
      contactUuid: $contactUuid,
      investorUuid: $investorUuid,
    ) {
      introRequest {
        uuid
      }
    }
  }
`