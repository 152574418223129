import { gql, useQuery } from "@apollo/client"

export const GET_LIST = gql`
query custom_list(
  $uuid: String
  ){ 
    customLists (
    uuid: $uuid
  ) {
    uuid
    name
    description
    creator {
      username
    }
    collaborators {
      collaborator {
        id
        email
        name
      }
    }
  }
}
`

export const useList = (uuid) => {
  const { data, error, loading } = useQuery(GET_LIST, {
    variables: {
      uuid: uuid
    }
  })

  return {
    data,
    error,
    loading
  }
}