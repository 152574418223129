import React from 'react'
import { PrimaryButton } from '../primitives/Button'
import { styled } from '../../styles'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { SmallIcon } from '../primitives/IconWrapper'
import { Div } from '../primitives/Div'

export default function DownloadList({ downloadLink, setDownloadBox, listOrContact }) {

  return (
    <DownloadBox>
      <Div>
        <a href={downloadLink}>
          <PrimaryButton>
            Download&nbsp;{listOrContact}
          </PrimaryButton>
        </a>
      </Div>
      <SmallIcon
        css={{ position: 'absolute', top: '0', right: '0', '&:hover': { color: '$primary' } }}
        onClick={() => setDownloadBox(false)}
      >
        <CloseIcon />
      </SmallIcon>
    </DownloadBox>
  )
}

const DownloadBox = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'fit-content',
  height: 'fit-content',
  position: 'absolute',
  zIndex: '300',
  backgroundColor: 'white',
  boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
  borderRadius: '4px',
  minWidth: 'max-content',
  padding: '22px 24px',
  border: '1px solid $grey100',
  marginTop: '4px',
  marginLeft: '-48px'
})
