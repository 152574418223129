import { gql } from "@apollo/client";

export const GET_INTRO_REQUESTS_RECEIVED = gql`
query introRequestsReceived{
  introRequestsReceived {
    uuid
    investorUuid {
      uuid
      name
      logoUrl
      logoUrlSmall
      permalink
      cbUrl
      homepageUrl
      linkedinUrl
      twitterUrl
      extra
    }
    contactUuid {
      uuid
      name
      email
      comment
    	investor {
      	uuid
      	name
    	}
    }
    requestedBy {
      id
      username
      firstName
      lastName
    }
    createdAt
    commentText
    requestedToDone
    requestedToDeleted
  }
}
`
