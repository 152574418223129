import { gql } from '@apollo/client'

export const GET_NOT_ASSIGNED_CONTACT = gql`
query not_assigned_contacts (
  $page: Int
  $pageSize: Int
) {
  notAssignedContacts (
    page: $page
    pageSize: $pageSize
  ) {
    page
    pages
    hasNext
    hasPrev
    count
    totalCount
    objects{
      uuid
      name
      email
      comment
    }
  }
}
`


