import { styled } from '../../styles'

export const AskForIntroButton = styled('button', {
  margin: '0px 0px 12px 8px',
  backgroundColor: 'white',
  color: '$grey600',
  borderRadius: '4px',
  border: '1px solid $grey100',
  padding: '3px 10px',
  cursor: 'pointer',
  fontFamily: 'inherit',
  fontSize: '13px',
  '&:hover': {
    color: '$primary',
    border: '1px solid $primary'
  }
})

export const ClearButton = styled('button', {
  backgroundColor: 'transparent',
  border: 'none',
  color: '$grey300',
  borderRadius: '900px',
  fontSize: '14px',
  '&:hover': {
    color: '$primary',
  }
})

export const PrimaryButton = styled('button', {
  backgroundColor: '$primary',
  color: 'white',
  borderRadius: '4px',
  border: '1px solid $primary',
  padding: '$6 $12',
  cursor: 'pointer',
  fontFamily: 'inherit',
  fontSize: '$14',
  '&:hover': {
    backgroundColor: 'white',
    color: '$primary',
  },
  '&:disabled': {
    opacity: '0.6',
    pointerEvents: 'none'
  }
})

export const SecondaryButton = styled('button', {
  backgroundColor: 'white',
  color: '$grey600',
  borderRadius: '4px',
  border: '1px solid $grey100',
  padding: '6px 12px',
  cursor: 'pointer',
  fontFamily: 'inherit',
  fontSize: '14px',
  '&:hover': {
    color: '$primary',
    border: '1px solid $primary'
  },
  '&:focus': {
    outline: 'none'
  },
  '&:disabled': {
    color: '$grey200',
    cursor: 'auto',
    '&:hover': {
      border: '1px solid $grey100'
    }
  },
})

export const SmallPrimaryButton = styled('button', {
  backgroundColor: '$primary',
  color: 'white',
  borderRadius: '4px',
  border: '1px solid $primary',
  padding: '3px 6px',
  cursor: 'pointer',
  fontFamily: 'inherit',
  fontSize: '$11',
  '&:hover': {
    backgroundColor: 'white',
    color: '$primary',
  },
  '&:disabled': {
    opacity: '0.6',
    pointerEvents: 'none'
  }
})


export const SmallSecondaryButton = styled('button', {
  backgroundColor: 'white',
  color: '$grey600',
  borderRadius: '4px',
  border: '1px solid $grey100',
  padding: '3px 6px',
  cursor: 'pointer',
  fontFamily: 'inherit',
  fontSize: '$11',
  '&:hover': {
    color: '$primary',
    border: '1px solid $primary'
  },
  '&:focus': {
    outline: 'none'
  },
  '&:disabled': {
    color: '$grey200',
    cursor: 'auto',
    '&:hover': {
      border: '1px solid $grey100'
    }
  },
})

export const ShowAndHideButton = styled('button', {
  position: 'absolute',
  right: '10px',
  cursor: 'pointer',
  fontSize: '$11',
  border: 'none',
  backgroundColor: 'transparent',
})


export const ButtonsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  '& > button:nth-child(1), & > button:nth-child(2), & >span': {
    marginRight: '12px',
    '@bp0': {
      marginRight: '3px'
    }
  }
})