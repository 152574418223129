import { styled } from "../../styles"

export const Header1 = styled('h1', {
  fontSize: '24px',
  fontWeight: '300',
  color: '#8c003d',
  margin: '0px'
})

export const Header2 = styled('h2', {
  fontSize: '20px',
  fontWeight: '300',
  color: '#8c003d',
  margin: '0px'
})

export const Header4 = styled('h4', {
  fontSize: '12px',
  fontWeight: '300',
  color: '#9d9d9d',
  marginBottom: '8px',
  marginTop: '16px',
  variants: {
    isRequired: {
      true: {
        color: '$red'
      },
    }
  }
})

export const Header5 = styled('p', {
  fontSize: '12px',
  color: '$grey500',
  margin: '$0',
})

export const Paragraph = styled('p', {
  fontSize: '$13',
  fontWeight: '300',
  color: '$grey500',
  margin: '0px'
})

export const FilterTitle = styled('h3', {
  fontSize: '12px',
  fontWeight: '300',
  color: '#9d9d9d',
  marginBottom: '4px',
  marginTop: '8px',
})

export const RequiredText = styled('p', {
  fontSize: '$11',
  fontWeight: '300',
  color: '$red',
  marginTop: '$4',
  marginBottom: '$0'
})

