import { useState } from 'react'
import { useInvestor } from '../../queries/useInvestor'
import { Paragraph } from '../../components/primitives/Text'
import { BoxWrapper } from '../../components/primitives/Box'
import { FlexSpaceBetween } from '../../components/primitives/FlexSpaceBetween'
import { Loader } from '../../components/primitives/Loader'
import RelatedLists from './components/RelatedLists'
import UserFeedback from '../../components/patterns/UserFeedback'
import Comments from './components/Comments'
import PortfolioCompanies from './components/PortfolioCompanies'
import Connections from './components/Connections'
import Summary from './components/Summary'
import Notes from './components/Notes'
import { Div } from '../../components/primitives/Div'
import CoInvestors from './components/CoInvestors'
import Portfolio from './components/Portfolio'

export default function InvestorProfile() {

  const [userFeedback, setUserFeedback] = useState(false)

  const urlParams = new URLSearchParams(window.location.search)
  const uuid = urlParams.get('uuid')

  const { data, loading, error } = useInvestor(uuid)

  const investor = data?.investor
  const extra =
    (investor?.extra === null || investor?.extra === "" || investor?.extra === undefined)
      ? false
      : JSON.parse(investor?.extra)
  // console.log(JSON.parse(data?.investor.extra))
  // console.log(investor.investments)
  // console.log(extra)

  document.title = extra?.name ? extra?.name : data?.investor.name

  return (
    <Div data-testid='investor-profile-page'>
      {userFeedback && <UserFeedback userFeedback={userFeedback} setUserFeedback={setUserFeedback} />}

      {error && <Paragraph css={{ m: '$16' }}>An error occurred. Please try again or report this bug</Paragraph>}
      {loading && <Loader size="20" css={{ d: 'inline-block', ml: '$8', mt: '$16' }} data-testid="loader" />}
      {data &&
        <>
          <Summary investor={investor} extra={extra} uuid={uuid} setUserFeedback={setUserFeedback} />

          <BoxWrapper >
            <FlexSpaceBetween css={{ ai: 'stretch', '@bp3': { display: 'block' } }}>
              <Connections setUserFeedback={setUserFeedback} uuid={uuid} />
              <Notes uuid={uuid} setUserFeedback={setUserFeedback} />
            </FlexSpaceBetween>
          </BoxWrapper>

          <Portfolio investorUuid={uuid}/>
          {/* <PortfolioCompanies investorUuid={uuid} /> */}
          <CoInvestors investorUuid={uuid} />
          <Comments investor={investor} investorUuid={uuid} setUserFeedback={setUserFeedback} />
          <RelatedLists investorUuid={uuid}/>
        </>
      }
    </Div>

  )
}












