import { useState } from 'react';
import { styled } from '../../styles';
import { Header1 } from '../../components/primitives/Text'
import { Box } from '../../components/primitives/Box'
import { FlexSpaceBetween } from '../../components/primitives/FlexSpaceBetween';
import { Flex } from '../../components/primitives/Flex';
import { Modal } from '../../modals';
import AddNewContact from '../../modals/AddNewContact';
import UploadContacts from '../../modals/UploadContacts';
import { ButtonsWrapper } from '../../components/primitives/Button';
import { PagePadding } from '../../components/primitives/PagePadding';
import Tabs from './components/Tabs'
import { useContact } from '../../queries/useContact';
import { ReactComponent as AddIcon } from '../../assets/icons/add-filled.svg'
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg'
import { ReactComponent as ExportIcon } from '../../assets/icons/download.svg'
import { ReactComponent as CopyIcon } from '../../assets/icons/copy.svg'
import { IconInsideButton, SmallIcon } from '../../components/primitives/IconWrapper';
import UserFeedback from '../../components/patterns/UserFeedback';
import ClickAwayListener from 'react-click-away-listener'
import { PrimaryButton } from '../../components/primitives/Button';
import Download from '../../components/patterns/DownloadBox';
import { GET_EXPORT_CONTACTS_URL } from '../../mutations/exportContacts';
import { useMutation } from '@apollo/client';

export default function MyContacts() {

  const [userFeedback, setUserFeedback] = useState(false)
  const [downloadBox, setDownloadBox] = useState(false)

  const { data, /*error, loading*/ } = useContact()
  const [exportContacts, { data: exportContactsData, error: exportContactsError }] = useMutation(GET_EXPORT_CONTACTS_URL, {
    onError: () => {
      console.error(exportContactsError)
      setUserFeedback({success: false, text: `${exportContactsError}`})
    },
    onCompleted: ()=> {
      setDownloadBox(true)
    }
  })

  document.title = 'My Contacts'

  return (
    <PagePadding>
      {userFeedback && <UserFeedback userFeedback={userFeedback} setUserFeedback={setUserFeedback} />}

      <FlexSpaceBetween css={{ mb: '$16', '@bp2': { display: 'block' } }}>
        <Header1>My Contacts ({data?.contacts.totalCount})</Header1>
        <AddContactEmailAddress>
          add contacts by forwarding email to:&nbsp;
          <Flex
            css={{ textDecoration: 'underline', '&:hover': { color: '$secondary' } }}
            title='copy to clipboard'
            onClick={() => {
              navigator.clipboard.writeText('addcontact@vcrm.vc')
              setUserFeedback({ success: true, text: 'copied to clipboard' })
              setTimeout(() => {
                setUserFeedback(false)
              }, [2500])
            }}
          >
            addcontact@vcrm.vc
            <SmallIcon css={{ ml: '4px' }}>
              <CopyIcon />
            </SmallIcon>
          </Flex>
        </AddContactEmailAddress>

        <ButtonsWrapper css={{ '@bp2': { justifyContent: 'flex-start', mt: '6px' } }}>
          <Modal
            triggerButtonLabel={(
              <Flex css={{ ai: 'center' }}>
                Add new contact
                <IconInsideButton><AddIcon /></IconInsideButton>
              </Flex>
            )}
            Component={AddNewContact}
            title={'Add new contact'}
            setUserFeedback={setUserFeedback}
          />

          <Modal
            triggerButtonLabel={(
              <Flex>
                Upload contacts
                <IconInsideButton><UploadIcon /></IconInsideButton>
              </Flex>
            )}
            Component={UploadContacts}
            title={'Upload contacts'}
            setUserFeedback={setUserFeedback}
          />

          <ClickAwayListener onClickAway={() => setDownloadBox(false)}>
            <span>
              <PrimaryButton
                onClick={() => exportContacts()}
                css={{ position: 'relative' }}
              >
                <Flex css={{ ai: 'center' }}>
                  Export contacts
                  <IconInsideButton><ExportIcon /></IconInsideButton>
                </Flex>
              </PrimaryButton>
              {downloadBox &&
                <Download
                  downloadLink={exportContactsData?.getExportContactsUrl.exportContacts.url}
                  setDownloadBox={setDownloadBox}
                  listOrContact={'contacts'}
                />
              }
            </span>
          </ClickAwayListener>
        </ButtonsWrapper>
      </FlexSpaceBetween>

      <Box>
        <Tabs setUserFeedback={setUserFeedback} />
      </Box>
    </PagePadding>
  )
}

const AddContactEmailAddress = styled('div', {
  backgroundColor: '$primaryLight',
  py: '$4',
  px: '$8',
  cursor: 'pointer',
  display: 'flex',
  fontSize: '$13',
  fontWeight: '300',
  color: '$grey500',
})


