import {createStitches} from '@stitches/react'

const theme = {
  colors: {
    primary: '#8c003d',
    primaryLight: '#f9f2f5',
    secondary: '#f04e29',
    green: '#27ae60',
    yellow100: '#f1af1b',
    yellow200: '#f2994a',
    red: '#c50000',
    black: '#000',
    white: '#fff',
    grey50: '#fafafa',
    grey75: '#f8f8f8',
    grey100: '#eaeaea',
    grey200: '#bcbec0',
    grey300: '#9d9d9d',
    grey400: '#8c8c8c',
    grey500: '#58595b',
    grey600: '#3e3e3e',
    active: 'rgba(0, 0, 0, 0.05)',
    modalBackground: 'rgba(0, 0, 0, 0.6)',
    searchBackground: 'rgba(0, 0, 0, 0.25)',
  },
  space: {
    0: 0,
    2: '0.125rem',
    4: '0.25rem',
    6: '0.375rem',
    8: '0.5rem',
    10: '0.625rem',
    12: '0.75rem',
    14: '0.875rem',
    16: '1rem',
    20: '1.25rem',
    24: '1.5rem',
    32: '2rem',
    36: '2.25rem',
    40: '2.5rem',
    48: '3rem',
    64: '4rem',
    96: '6rem',
    128: '8rem',
  },
  fonts: {
    base: `Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif`,
  },
  fontSizes: {
    9: '0.5625rem', // 9px
    11: '0.6875rem', // 11px
    12: '0.75rem', // 12px
    13: '0.8125rem', // 13px
    14: '0.875rem', // 14px
    16: '1rem', // 16px
    18: '1.125rem', // 18px
    20: '1.25rem', // 20px
    24: '1.5rem', // 24px
    26: '1.625rem', // 26px
    28: '1.75rem', // 28px
    32: '2rem', //32px
    36: '2.25rem', //3
  },
  fontWeights: {
    300: '300', // light
    400: '400', // regular
    500: '500', // medium
    600: '600', // semi-bold
  },
  lineHeights: {
    solid: '1',
    body: '1.25',
    title: '1.5',
  },
  radii: {
    none: 0,
    small: '0.25rem',
    large: '1rem',
    round: '50%',
    pill: '9999rem',
  },
  sizes: {
    7: '7px',
    10: '10px',
    12: '12px',
    13: '13px',
    14: '14px',
    16: '16px',
    17: '17px',
    18: '18px',
    19: '19px',
    20: '20px', 
    22: '22px',
    24: '24px',
    26: '26px',
    28: '28px',
    32: '32px',
    36: '36px',
    40: '40px',
    64: '64px',
    100: '100px',
    128: '128px',
    200: '200px',
    300: '300px',
    464: '464px',
    full: '100%',
  },
  shadows: {
    1: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    2: '0 2px 8px rgba(0, 0, 0, 0.1)',
    3: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 2px rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
  },
  zIndices: {
    z100: '100',
    z200: '200',
    z300: '300',
    z400: '400',
    z500: '500',
    z600: '600',
    zMax: '999',
  },
}

export const {css, globalCss, keyframes, styled} = createStitches({
  theme,
  media: {
    // bp0: `(min-width: 414px)`,
    // bp1: `(min-width: 640px)`,
    // bp2: `(min-width: 768px)`,
    // bp3: `(min-width: 1024px)`,
    // bp4: `(min-width: 1280px)`,
    // bp5: `(min-width: 1440px)`,
    bp0: `(max-width: 414px)`,
    bp1: `(max-width: 640px)`,
    bp2: `(max-width: 768px)`,
    bp3: `(max-width: 1024px)`,
    bp4: `(max-width: 1280px)`,
    bp5: `(max-width: 1440px)`,
    landscapeX: `only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)`,
    landscape678: `only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2)`,
    landscape678plus: `only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3)`,
  },
  utils: {
    p: value => ({
      paddingTop: value,
      paddingBottom: value,
      paddingLeft: value,
      paddingRight: value,
    }),
    pt: value => ({paddingTop: value}),
    pr: value => ({paddingRight: value}),
    pb: value => ({paddingBottom: value}),
    pl: value => ({paddingLeft: value}),
    px: value => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: value => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    m: value => ({
      marginTop: value,
      marginBottom: value,
      marginLeft: value,
      marginRight: value,
    }),
    mt: value => ({marginTop: value}),
    mr: value => ({marginRight: value}),
    mb: value => ({marginBottom: value}),
    ml: value => ({marginLeft: value}),
    mx: value => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: value => ({
      marginTop: value,
      marginBottom: value,
    }),

    w: value => ({width: value}),
    mw: value => ({minWidth: value}),
    h: value => ({height: value}),
    wh: value => ({width: value, height: value}),

    ta: value => ({textAlign: value}),

    d: value => ({display: value}),

    fd: value => ({flexDirection: value}),
    fw: value => ({flexWrap: value}),

    ai: value => ({alignItems: value}),
    jc: value => ({justifyContent: value}),
    as: value => ({alignSelf: value}),
    fg: value => ({flexGrow: value}),
    fs: value => ({flexShrink: value}),
    fb: value => ({flexBasis: value}),

    gtc: value => ({gridTemplateColumns: value}),
    gtr: value => ({gridTemplateRows: value}),
    grg: value => ({gridRowGap: value}),
    gcg: value => ({gridColumnGap: value}),

    bgc: value => ({backgroundColor: value}),

    bs: value => ({boxShadow: value}),

    b: value => ({border: value}),
    br: value => ({borderRadius: value}),
    boc: value => ({borderColor: value}),

    lh: value => ({lineHeight: value}),

    ox: value => ({overflowX: value}),
    oy: value => ({overflowY: value}),

    txtd: value => ({textDecoration: value}),

    pe: value => ({pointerEvents: value}),
    us: value => ({userSelect: value}),
  },
})
