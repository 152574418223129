import { gql } from "@apollo/client";

export const GET_INVESTOR_PORTFOLIO_ORGANIZATIONS = gql`
query investorPortfolioOrganizations(
  $investorUuid: String!
  $page: Int
  $pageSize: Int
) {
  investorPortfolioOrganizations (
    investorUuid: $investorUuid
    page: $page
    pageSize: $pageSize
  ) {
    totalCount
    hasNext
    objects {
      uuid
      name
      countryCode
      categoryList
      homepageUrl
      logoUrl
      logoUrlSmall
      homepageUrl
      cbUrl
      city
      shortDescription
      linkedinUrl
      twitterUrl
      foundedOn
      employeeCount
    }
  }
}
`
