import { useState } from 'react'
import { Box } from '../primitives/Box'
import { Paragraph } from '../primitives/Text'
import { Badge } from '../primitives/Badge'
import { useInvestorContacts } from '../../queries/useInvestorContacts'
import { Span } from '../primitives/Span'

export const ContactLabel = ({ uuid }) => {

  const [boxDisplayed, setBoxDisplayed] = useState(false)
  // const [boxDisplayed, setBoxDisplayed] = useState(true)

  const { data: contactsData } = useInvestorContacts(uuid)

  const inInvestorProfilePage = document.URL.includes('uuid') && document.URL.includes('search-investors')

  //filtering to avoid the duplication of contactOwnerOrganization
  // function onlyUnique(contactsData) {
  //   const contactOwnerOrganization = []
  //   const filteredArray = []
  //   contactsData?.investorContacts.map((item) => {
  //     if (!contactOwnerOrganization.includes(item.contactOwnerOrganization)) {
  //       contactOwnerOrganization.push(item.contactOwnerOrganization)
  //       filteredArray.push(item)
  //     }
  //   })
  //   return filteredArray
  // }
  // const filteredArray = onlyUnique(contactsData)

  const displayed = []

  return (
    <Span css={inInvestorProfilePage
      ? { position: 'relative', w: 'fit-content', zIndex: '100' }
      : { position: 'relative', w: 'fit-content' }
    }>
      <Badge
        onMouseEnter={() => setBoxDisplayed(true)}
        onMouseLeave={() => setBoxDisplayed(false)}
        backgroundColor={'green'}
        css={inInvestorProfilePage && {ml: '$12', '@bp3': {ml: '0'}}}
      >
        Contact
      </Badge>
      {
        boxDisplayed &&
        <Box
          className='box-display'
          css={inInvestorProfilePage
            ? { position: 'absolute', background: '$grey50', color: 'black', borderRadius: '4px', left: 55, top: 0 }
            : { position: 'absolute', background: '$grey50', color: 'black', borderRadius: '4px', bottom: 24 }
          }
        >
          {contactsData?.investorContacts.slice().sort((a, b) => a.contactOwnerName.localeCompare(b.contactOwnerName)).map((contact) => {
            return (
              <Paragraph css={{ whiteSpace: 'nowrap' }} key={contact.uuid}>
                {contact.ownContact
                  ? <span>You know <b>{contact.contactName}</b></span>
                  : contact.nautaUser
                    ? contact.contactOwnerNautaUser //the user and the contact owner are both from Nauta
                      ? <span><b>{contact.contactOwnerName}</b> knows <b>{contact.contactName}</b></span>
                      : <span><b>{contact.contactOwnerName}</b> from <b>{contact.contactOwnerOrganization}</b> knows <b>{contact.contactName}</b></span>
                    : !displayed.includes(contact.contactOwnerOrganization)
                      ? (displayed.push(contact.contactOwnerOrganization),
                        <span><b>{contact.contactOwnerOrganization}</b> has contact</span>)
                      : <></>
                }
              </Paragraph>
            )
          })}
        </Box>
      }
    </Span >
  )
}
