import React, { useRef } from 'react'
import { useMutation } from '@apollo/client'
import { Div } from '../components/primitives/Div'
import { PrimaryButton, SecondaryButton, ButtonsWrapper } from '../components/primitives/Button'
import { Header2 } from '../components/primitives/Text'
// import { GET_PERSONAL_NOTES } from '../queries/usePersonalNotes'
import { DELETE_NOTE } from '../mutations/deleteNote'
import { GET_CUSTOM_LIST_DETAIL_PAGINATED } from '../queries/useCustomListDetailPaginated'

export default function DeletePersonalNote({ noteUuid, setUserFeedback, close, listUuid, updateDataAfterDeleteComment, rowIndex}) {

  const deleteButtonRef = useRef(null)

  const [deleteNote] = useMutation(DELETE_NOTE, {
    variables: {
      noteUuid
    },
    // refetchQueries: [
    //   {query: GET_CUSTOM_LIST_DETAIL_PAGINATED, variables: {uuid: listUuid, page: 1, pageSize: 25}},
    //   {query: GET_CUSTOM_LIST_DETAIL_PAGINATED, variables: {uuid: listUuid, page: 1, pageSize: 9999}}
    // ],
    onError: (error) => {
      setUserFeedback({success: false, text: `${error}`})
      console.error(error)
    },
    onCompleted: () => {
      close()
      setUserFeedback({success: true, text: 'Comment deleted'})
      updateDataAfterDeleteComment(rowIndex, noteUuid)
    }
  })


  return (
    <Div
      onKeyPress={(event) => {
        const { key } = event;
        if (key === "Enter") {
          deleteButtonRef.current.click();
        }
      }}>
      <form>
        <Header2 css={{ mt: '$16' }}>Are you sure you want to delete this comment?</Header2>
        <ButtonsWrapper css={{ mt: '$32' }}>
          <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
          <PrimaryButton
            type='button'
            onClick={() => deleteNote()}
            ref={deleteButtonRef}
          >Delete</PrimaryButton>
        </ButtonsWrapper>
      </form>
    </Div>
  )
}
