import { Header4 } from '../primitives/Text'
import { SecondaryButton } from '../primitives/Button'
import { ReactComponent as ToFirstPageArrow } from '../../assets/icons/first-page.svg'
import { ReactComponent as NextArrow } from '../../assets/icons/arrow-right.svg'
import { ReactComponent as PrevArrow } from '../../assets/icons/arrow-left.svg'
import { ReactComponent as ToLastPageArrow } from '../../assets/icons/last-page.svg'
import { styled } from '../../styles'
import { Loader } from '../primitives/Loader'
import { Div } from '../primitives/Div'

export default function Pagination({
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  pageOptions,
  allDataLoading,
}) {
  return (
    <Div css={{ textAlign: 'right' }}>
      <Header4 css={{ mr: '$6' }}>
        Page <b>{pageIndex + 1}</b> of
        {
          allDataLoading
            ? <Loader size="16" css={{ d: 'inline-block', mt: '$8', ml: '$8' }} />
            : <b> {pageOptions.length}</b>
        }
      </Header4>

      <SecondaryButton
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        css={{ mr: '$6' }}
      >
        <Icon>
          <ToFirstPageArrow />
        </Icon>
      </SecondaryButton>

      <SecondaryButton
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        css={{ mr: '$6' }}
      >
        <Icon>
          <PrevArrow />
        </Icon>
      </SecondaryButton>

      <SecondaryButton
        onClick={() => nextPage()}
        disabled={!canNextPage}
        css={{ mr: '$6' }}
      >
        <Icon>
          <NextArrow />
        </Icon>
      </SecondaryButton>

      <SecondaryButton
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        <Icon>
          <ToLastPageArrow />
        </Icon>
      </SecondaryButton>

    </Div>
  )
}


const Icon = styled('div', {
  width: '18px',
})

