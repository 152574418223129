import { gql } from "@apollo/client";

export const SEND_CODE = gql`
mutation sendCode(
  $password: String!
  $username: String!
) {
  sendCode (
    password: $password
    username: $username
  ) {
    user {
      username
    }
  }
}
`

export const TOKEN_AUTH = gql`
mutation tokenAuth2fa(
  $username: String!
  $password: String!
  $secretCode: String!
) {
  tokenAuth2fa (
    username: $username
    password: $password
    secretCode: $secretCode
  ) {
    payload
    token
    user {
      username
      firstName
      lastName
      userType
    }
    }
  }
`

