export const formatMinRoundSize = (min) => {
  if (0 <= min && min <= 49999) {
    return 0
  }
  if (50000 <= min && min <= 99999) {
    return 50000
  }
  if (100000 <= min && min <= 249999) {
    return 100000
  }
  if (250000 <= min && min <= 499999) {
    return 250000
  }
  if (500000 <= min && min <= 999999) {
    return 500000
  }
  if (1000000 <= min && min <= 1999999) {
    return 1000000
  }
  if (2000000 <= min && min <= 4999999) {
    return 2000000
  }
  if ( 5000000 <= min && min <= 9999999 ) {
    return 5000000
  }
  if ( 10000000 <= min && min <= 14999999 ) {
    return 10000000
  }
  if ( 15000000 <= min && min <= 19999999 ) {
    return 15000000
  }
  if ( 20000000 <= min && min <= 29999999 ) {
    return 20000000
  }
  if ( 30000000 <= min && min <= 39999999 ) {
    return 30000000
  }
  if ( 40000000 <= min && min <= 49999999 ) {
    return 40000000
  }
  if ( 50000000 <= min ) {
    return 50000000
  }
  // if (min <= 50000001) {
  //   return 50000001
  // }
}
