import {styled} from '../../styles'

export const SmallCompanyLogo = styled('img', {
  width: '30px',
  height: '30px',
  objectFit: 'contain',
  marginRight: '14px'
})

export const BigCompanyLogo = styled('img', {
  width: '80px',
  height: '80px',
  objectFit: 'contain',
  marginRight: '30px',
})