import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_VCRM_GRAPHQL_URI,
  credentials: 'include',
  fetchOptions: {
    credentials: 'include',
  },
  fetch
})

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
})

export default client