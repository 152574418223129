import { gql } from "@apollo/client";

export const GET_INTRO_REQUESTS_SENT = gql`
query introRequestsSent{
  introRequestsSent {
    uuid
    investorUuid {
      uuid
      name
      logoUrl
      logoUrlSmall
      permalink
      cbUrl
      homepageUrl
      linkedinUrl
      twitterUrl
      extra
    }
    contactUuid {
      uuid
      name
      email
      comment
    	investor {
      	uuid
      	name
    	}
    }
    requestedTo {
      id
      username
      firstName
      lastName
    }
    createdAt
    commentText
    requestedByDone
    requestedByDeleted
  }
}
`
