import { gql } from "@apollo/client";

export const ADD_STATUS_TO_INVESTOR = gql`
mutation addStatusToInvestorInCustomList(
  $investorUuid: String!
  $listUuid: String!
  $status: String!
) {
  addStatusToInvestorInCustomList (
    investorUuid: $investorUuid
    listUuid: $listUuid
    status: $status
  ) {
    customListInvestor {
      investorUuid {
        uuid
        name
      }
      status {
        id
        text
      }
    }
  }
}
`