import React, { useState } from 'react'
import { styled } from '../../styles'
import { Box } from '../primitives/Box'
import { Paragraph } from '../primitives/Text'
import moment from 'moment'
import { Span } from '../primitives/Span'

export const ModifiedBadge = ({ extraLog, original, updatedByName, updatedAt }) => {
  // console.log({updatedAt, updatedByName})
  // console.log('extraLog===>', extraLog.replace(/\n/g, ',') )
  // console.log('original===>', original)
  // const latestModification = JSON.parse(extraLog.split('\n')[extraLog.split('\n').length - 1])
  // console.log('latest====>', latestModification)

  const [boxDisplayed, setBoxDisplayed] = useState(false)

  return (
    <span>
      <Badge
        onMouseEnter={() => { setBoxDisplayed(true) }}
        onMouseLeave={() => { setBoxDisplayed(false) }}
        css={{ cursor: 'pointer' }}
      >
        *
      </Badge>
      {
        boxDisplayed &&
        <Span css={{ position: 'relative' }}>
          <Box
            className='box-display'
            css={{ background: '$grey50', position: 'absolute', left: '12px', top: '2px', zIndex: '100', whiteSpace: 'nowrap', py: '$4', px: '$6' }}
          >
            <Paragraph css={{ mb: '$4' }}>
              Editted by {updatedByName} on {moment(updatedAt).format('ll')}
            </Paragraph>
          </Box>
        </Span>
      }
    </span>
  )
}


const Badge = styled('span', {
  color: '$secondary',
  padding: '0px 2px 0px 1px',
  fontSize: '$16'
})
