import { gql } from '@apollo/client'

export const STEP_OUT_CUSTOM_LIST = gql`
mutation stepOutCustomList (
  $uuid: String!
) {
  stepOutCustomList (
    uuid: $uuid
  ) {
    customList {
      uuid
      name
    }
  }
}
`