import { gql } from '@apollo/client'

export const CHANGE_PASSWORD = gql`
mutation passwordChange(
  $username: String!
  $password: String!
  $newPassword1: String!
  $newPassword2: String!
) {
  passwordChange (
    username: $username
    password: $password
    newPassword1: $newPassword1
    newPassword2: $newPassword2
  ) {
    payload
  }
}
`