import { useState, useEffect } from 'react'
import { TableData, TableRow } from '../../../components/primitives/Table'
import { Modal } from '../../../modals'
// import { CheckBox } from '../../../components/primitives/Input'
import DeleteContact from '../../../modals/DeleteContact';
import { useMutation } from '@apollo/client'
import { SmallSecondaryButton } from '../../../components/primitives/Button'
import { FlexSpaceBetween } from '../../../components/primitives/FlexSpaceBetween'
import { UNASSIGN_CONTACT } from '../../../mutations/unAssignContact'
import AssignInvestor from '../../../modals/AssignInvestor'
import { Div } from '../../../components/primitives/Div'
import { EDIT_CONTACT } from '../../../mutations/editContact'
import { EditableInput } from '../../../components/primitives/EditableInput'
import TextareaAutosize from 'react-textarea-autosize';
import LogoNameUrlSection from '../../../components/patterns/LogoNameUrlSection'
import { Flex } from '../../../components/primitives/Flex';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import { ReactComponent as SaveIcon } from '../../../assets/icons/save.svg'
import { SmallIcon } from '../../../components/primitives/IconWrapper';
import { Span } from '../../../components/primitives/Span';

export default function ContactRow({ handleCheck, displayedTab, checked, row, setUserFeedback, updatePendingData, updateDataAfterUnassign, updateDataAfterAssign, updateDataAfterApprove, updateDataAfterDelete, updateDataAfterEdit }) {

  // console.log({displayedTab})

  const [rowIndex, setRowIndex] = useState(null)
  const [columnId, setColumnId] = useState(null)
  const [value, setValue] = useState({
    name: '',
    email: '',
    comment: '',
  })
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    setValue({
      name: row.original.name === undefined ? '' : row.original.name,
      email: row.original.email === undefined ? '' : row.original.email,
      comment: row.original.comment === undefined ? '' : row.original.comment
    })
  }, [row.original.name, row.original.email, row.original.comment])

  //for unassigning investor
  const [unassignContact, { error: unassignContactError }] = useMutation(UNASSIGN_CONTACT, {
    onError: (error) => {
      console.error({ unassignContactError })
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      setUserFeedback({ success: true, text: 'Investor unassigned' })
      displayedTab === 'assigned' && updateDataAfterUnassign(row.index, data.unassignContact.contact)
      displayedTab === 'pending' && updatePendingData(row.index, 'investor', undefined)
    }
  })

  //for editting contacts
  const [editContact] = useMutation(EDIT_CONTACT, {
    onError: (error) => {
      console.error({ error })
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      setUserFeedback({ success: true, text: 'Contact editted' })
      setEditMode(false)
      updateDataAfterEdit(rowIndex, data.editContact.contact)
    },
  })

  //for editting and approving pending contacts
  const [approveContact] = useMutation(EDIT_CONTACT, {
    onError: (error) => {
      console.error({ error })
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      setUserFeedback({ success: true, text: 'Contact approved' })
      displayedTab === 'pending' && updateDataAfterApprove(rowIndex, data.editContact.contact)
    },
  })

  return (
    <TableRow {...row.getRowProps()}>
      {/* {console.log(row.original.uuid)} */}
      {/* <TableData>
        <CheckBox
          type='checkbox'
          onChange={handleCheck}
          value={row.original.uuid}
          checked={checked.includes(row.original.uuid)}
        />
      </TableData> */}
      {row.cells.map((cell) => {
        return (
          <TableData {...cell.getCellProps()}>
            {/* {
              displayedTab === 'pending' && (cell.column.Header === 'Name' || cell.column.Header === 'Email')
                ? (
                  <EditableInput>
                    <input
                      value={value[cell.column.id]}
                      onChange={(e) => setValue({ ...value, [cell.column.id]: e.target.value })}
                    />
                  </EditableInput>
                )
                //when in pending tab, the comment would be rendered in TextareaAutosize
                : displayedTab === 'pending' && cell.column.Header === 'Comment'
                  ? !cell.render('Cell')
                  //otherwise render the cell unless it's Investor is not undefined
                  : (cell.column.Header !== 'Investor' && cell.value !== undefined)
                  && cell.render('Cell')
            } */}

            {/* {cell.column.Header === 'uuid' && cell.render('Cell')} */}

            {editMode && (cell.column.Header === 'Name' || cell.column.Header === 'Email')
              ? <EditableInput>
                <input
                  value={value[cell.column.id]}
                  onChange={(e) => setValue({ ...value, [cell.column.id]: e.target.value })}
                />
              </EditableInput>
              : !editMode && (cell.column.Header === 'Name' || cell.column.Header === 'Email' || cell.column.Header === 'Comment')
              && cell.render('Cell')
            }

            {!(cell.row.original.investor === undefined || cell.row.original.investor === null) && cell.column.Header === 'Investor'
              ? <FlexSpaceBetween>

                <LogoNameUrlSection investor={cell.row.original.investor} />
                <SmallSecondaryButton
                  onClick={() => {
                    unassignContact({ variables: { contactUuid: row.original.uuid } })
                    setColumnId(cell.column.id)
                  }}
                >
                  Unassign
                </SmallSecondaryButton>
              </FlexSpaceBetween>
              : cell.column.Header === 'Investor' && (cell.row.original.investor === undefined || cell.row.original.investor === null)
              &&
              <Div css={{ textAlign: 'center' }}>
                <Modal
                  triggerButtonStyle={'smallSecondary'}
                  triggerButtonLabel={'Assign investor'}
                  title={'Assign investor'}
                  Component={AssignInvestor}
                  setUserFeedback={setUserFeedback}
                  contactUuid={row.original.uuid}
                  updatePendingData={updatePendingData}
                  rowIndex={cell.row.index}
                  columnId={cell.column.id}
                  displayedTab={displayedTab}
                  updateDataAfterAssign={updateDataAfterAssign}
                />
              </Div>
            }

            {(editMode && cell.column.Header === 'Comment')
              &&
              <EditableInput>
                <TextareaAutosize
                  value={value[cell.column.id]}
                  onChange={(e) => setValue({ ...value, comment: e.target.value })}
                />
              </EditableInput>
            }

          </TableData>
        )
      })}

      {/* ------------------------------------ */}

      <TableData css={{width: '120px'}}>
          {displayedTab === 'pending' && !editMode
            ?
            <Flex css={{w: '164px'}}>
              <SmallIcon css={{mr: '$16'}}>
                <EditIcon
                  onClick={() => setEditMode(true)} />
              </SmallIcon>
              <SmallSecondaryButton
                onClick={() => {
                  setRowIndex(row.index)
                  approveContact({
                    variables: {
                      approved: true,
                      uuid: row.original.uuid,
                    }
                  })
                }}
                css={{ mr: '$12' }}
              >Approve</SmallSecondaryButton>
              <Modal
                triggerButtonLabel={'Remove'}
                triggerButtonStyle={'smallSecondary'}
                Component={DeleteContact}
                contactUuid={row.original.uuid}
                setUserFeedback={setUserFeedback}
                updateDataAfterDelete={updateDataAfterDelete}
              />
            </Flex>
            : editMode
              ?
              <Flex >
                <SmallSecondaryButton
                  css={{ mr: '$12' }}
                  onClick={() => setEditMode(false)}
                >
                  cancel
                </SmallSecondaryButton>
                <SmallSecondaryButton
                  onClick={() => {
                    setRowIndex(row.index)
                    editContact({
                      variables: {
                        comment: value.comment,
                        email: value.email,
                        name: value.name,
                        uuid: row.original.uuid,
                      }
                    })
                  }}>
                  save
                </SmallSecondaryButton>
              </Flex>
              :
              <Flex >
                <SmallIcon css={{ mr: '$32' }}>
                  <EditIcon
                    onClick={() => setEditMode(true)} />
                </SmallIcon>
                <Modal
                  triggerButtonStyle={'Delete'}
                  Component={DeleteContact}
                  contactUuid={row.original.uuid}
                  setUserFeedback={setUserFeedback}
                  rowIndex={row.index}
                  updateDataAfterDelete={updateDataAfterDelete}
                />
              </Flex>
          }
      </TableData>


    </TableRow >
  )

}


