import { gql } from '@apollo/client'

export const GET_PENDING_CONTACT = gql`
query notApprovedContacts (
  $page: Int
  $pageSize: Int
) {
  notApprovedContacts (
    page: $page
    pageSize: $pageSize
  ) {
    page
    pages
    hasNext
    hasPrev
    count
    totalCount
    objects {
      approved
      uuid
      name
      email
      comment
      investor {
       uuid
        name
        logoUrl
        logoUrlSmall
        twitterUrl
        linkedinUrl
        cbUrl
        homepageUrl
        permalink
        extra   
      }
      approved
    }
  }
}
`