import React from 'react'

export default function DefaultColumnFilter({
  column: { filterValue, setFilter },
  gotoPage
}) {

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined)
        gotoPage(0)
      }}
      placeholder={'Search...'}
    />
  )
}