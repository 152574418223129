import { useState, useEffect, useMemo } from 'react'
import CommonTable from '../../../../../components/patterns/CommonTable'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_INVESTOR_PORTFOLIO_FUNDING_ROUNDS } from '../../../../../queries/useInvestorPortfolioFundingRounds'
import { formatCountryCode } from '../../../../../utils/format/formatCountryCode'
import moment from 'moment'
import formatInvestmentType from '../../../../../utils/format/formatInvestmentType'
import LogoNameUrlSection from '../../../../../components/patterns/LogoNameUrlSection'
import { Div } from '../../../../../components/primitives/Div'

export default function FundingRounds({ investorUuid }) {

  const [tableData, setTableData] = useState([])

  const { data: first25Data, loading: first25Loading, error: first25Error } = useQuery(GET_INVESTOR_PORTFOLIO_FUNDING_ROUNDS, {
    variables: { investorUuid, page: 1, pageSize: 25}
  })

  const [getAllData, { data: allData, loading: allLoading, error: allError }] = useLazyQuery(GET_INVESTOR_PORTFOLIO_FUNDING_ROUNDS, {
    variables: { investorUuid, page: 1, pageSize: 9999}
  })

  useEffect(() => {
    if (first25Data?.investorPortfolioFundingRounds.objects) {
      setTableData(first25Data?.investorPortfolioFundingRounds.objects.map((obj) => {
        return dataWithAccessor(obj)
      }))
      if (first25Data?.investorPortfolioFundingRounds.totalCount > 25) getAllData()
    }
    if (allData?.investorPortfolioFundingRounds.objects) {
      setTableData(allData?.investorPortfolioFundingRounds.objects.map((obj) => {
        return dataWithAccessor(obj)
      }))
    }
  }, [first25Data, allData, getAllData])

  const dataWithAccessor = (obj) => ({
    name: obj.orgUuid.name,
    location: `${obj.city} ${formatCountryCode(obj.countryCode)}`,
    locationDisplay: <>{obj.city}<br />{formatCountryCode(obj.countryCode)}</>,
    categories: obj.orgUuid.categoryList,
    announcedDate: obj.announcedOn,
    type: formatInvestmentType(obj.investmentType),
    amountRaised: obj.raisedAmountUsd,
    obj: obj.orgUuid,
  })

  const columns = useMemo(() => [
    { Header: 'Company', accessor: 'name', Cell: ({ row }) => { return <Div css={{w: '160px'}}><LogoNameUrlSection investor={row.original.obj} isPortfolioOrg={true} /></Div> } },
    { Header: 'Location', accessor: 'location', Cell: ({ row }) => { return <>{row.original.locationDisplay}</> } },
    { Header: 'Categories', accessor: 'categories' },
    { Header: 'Announced\u00A0date', accessor: 'announcedDate', Cell: ({ row }) => { return <>{moment(row.original.announcedDate).format('ll')}</> } },
    { Header: 'Type', accessor: 'type' },
    { Header: 'Amount\u00A0raised\u00A0(USD)', accessor: 'amountRaised', Cell: ({ row }) => { return <>${new Intl.NumberFormat().format(row.original.amountRaised)}</> } },
  ], [])

  return (
    <CommonTable
      data={tableData}
      columns={columns}
      loading={allLoading}
      error={first25Data?.investorPortfolioFundingRounds.hasNext ? allError : first25Error}
      columnsWithNoSort={['Categories']}
    />
  )
}
