import { gql } from '@apollo/client'

export const EDIT_CUSTOM_LIST = gql`
mutation editCustomList(
  $uuid: String!, 
  $name: String, 
  $description:String, 
  $collaboratorIds:String,
  ){
  editCustomList(
    uuid: $uuid,
    name: $name,
    description: $description,
    collaboratorIds: $collaboratorIds,
       ){
      customList{
      name
    }
  }
}
`