import React, { useRef } from 'react'
import { ButtonsWrapper, SecondaryButton, PrimaryButton } from '../components/primitives/Button'
import { Header1 } from '../components/primitives/Text'
import { CANCEL_INVITATION } from '../mutations/cancelInvitation'
import { GET_INVITATIONS } from '../queries/useInvitations'
import { useMutation } from '@apollo/client'
import { Div } from '../components/primitives/Div'

export default function CancelInvitation({ close, invitationUuid, setUserFeedback }) {

  // console.log({invitationUuid})

  const cancelButtonRef = useRef(null)

  const [cancelInvitation] = useMutation(CANCEL_INVITATION, {
    variables: {
      uuid: invitationUuid
    },
    refetchQueries: [
      { query: GET_INVITATIONS }
    ],
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      close()
      // console.log(data)
      setUserFeedback({ success: true, text: 'Invitation cancelled' })
    }
  })

  return (
    <Div
      onKeyPress={(event) => {
        const { key } = event;
        if (key === "Enter") {
          cancelButtonRef.current.click();
        }
      }}
    >
      <Header1 css={{ my: '$20', fontSize: '$20' }}>Do you want to cancel the invitation?</Header1>
      <ButtonsWrapper css={{ mt: '$24' }}>
        <SecondaryButton onClick={() => close()}>No</SecondaryButton>
        <PrimaryButton
          type='button'
          onClick={() => cancelInvitation()}
          ref={cancelButtonRef}
        >
          Yes
        </PrimaryButton>
      </ButtonsWrapper>
    </Div>
  )
}
