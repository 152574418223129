import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Box, BoxWrapper } from '../../../components/primitives/Box'
import { Header2 } from '../../../components/primitives/Text'
import { GET_CO_INVESTORS } from '../../../queries/useCoInvestors'
import Mosaic from '../../../components/patterns/Mosaic'
import { Loader } from '../../../components/primitives/Loader'

export default function CoInvestors({ investorUuid }) {

  const [pageSize, setPageSize] = useState(30)

  const { data, loading } = useQuery(GET_CO_INVESTORS, {
    variables: {
      investorUuid,
      pageSize: 99999,
    }
  })

  return (
      <BoxWrapper >
        {loading && <Loader size='20' />}
        {data?.investorCoInvestors.totalCount >= 1 &&
          <Box>
            <Header2 css={{ mb: '$12' }}>
              Co-investors
              {/* ({data?.investorCoInvestors.totalCount}) */}
            </Header2>
            {data &&
              <Mosaic
                data={data?.investorCoInvestors}
                investorUuid={investorUuid}
                pageSize={pageSize}
                setPageSize={setPageSize}
                label={'co-investors'}
              />
            }
          </Box>
        }
      </BoxWrapper>
  )
}
