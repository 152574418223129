import { gql } from '@apollo/client'

export const GET_RELATED_LISTS = gql`
query relatedLists ($uuid: String!) {
  relatedLists (uuid: $uuid) {
    uuid
    name
    description
    private
    creator {
      username
      firstName
      lastName
    }
    collaborators {
      collaborator {
        firstName
        lastName
        username
      }
    }
    investorsCount
    createdAt
    updatedAt
  }
}
`