import {keyframes, styled} from '../../styles'

const spin = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
})

export const Loader = styled('div', {
  border: '2px solid transparent',
  borderTop: '2px solid $grey100',
  borderRadius: '$round',
  animation: `${spin} 1.1s linear infinite`,
  variants: {
    size: {
      12: {
        wh: '$12',
      },
      14: {
        wh: '$14',
      },
      16: {
        wh: '$16',
      },
      20: {
        wh: '$20',
      },
    },
  },
})

