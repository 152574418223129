import { gql } from '@apollo/client'

export const ADD_COMMENT = gql`
mutation addComment (
  $commentText: String,
  $investorUuid: String!
  $mentionedUserIds: String
) {
  addComment (
    commentText: $commentText,
    investorUuid: $investorUuid,
    mentionedUserIds: $mentionedUserIds
  ) {
    investorComment {
      uuid
    }
  }
}
`