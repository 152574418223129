import { useState } from 'react';
import { PrimaryButton, SecondaryButton, ButtonsWrapper } from '../components/primitives/Button';
import { Header4 } from '../components/primitives/Text';
import { TextArea } from '../components/primitives/Input';
import { REQUEST_INTRO } from '../mutations/requestIntro';
import { useMutation } from '@apollo/client';

export default function AskForIntro({ close, contactUuid, investorUuid, setUserFeedback }) {

  const [commentText, setCommentText] = useState('introduce me please')

  const [requestIntro, { error }] = useMutation(REQUEST_INTRO, {
    variables: {
      contactUuid: contactUuid,
      investorUuid: investorUuid,
      commentText,
      // contactPointId
    },
    onError: () => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: () => {
      close()
      setUserFeedback({ success: true, text: 'Intro request sent' })
    }
  })

  return (
    <>
      <form>
        <Header4>Please provide context about your interest in contacting this person</Header4>
        <TextArea
          rows='5'
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
        />
        <ButtonsWrapper css={{ mt: '$24' }}>
          <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
          <PrimaryButton
            type='button'
            onClick={() => requestIntro()}
          >Send</PrimaryButton>
        </ButtonsWrapper>
      </form>
    </>
  )
}
