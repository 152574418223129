import { styled } from '../../styles'

export const Box = styled('div', {
  border: '1px solid $grey100',
  boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);',
  padding: '22px',
  backgroundColor: 'white'
})

export const BoxWrapper = styled('div', {
  margin: '20px',
})


