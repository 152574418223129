import { useState, useEffect, useRef } from 'react'
import { styled } from '../../../styles'
import { Box } from '../../../components/primitives/Box'
import { Header2, Header5, Header4, RequiredText, Paragraph } from '../../../components/primitives/Text'
import TextareaAutosize from 'react-textarea-autosize'
import { Flex } from '../../../components/primitives/Flex'
import { usePersonalNotes } from '../../../queries/usePersonalNotes'
import { GET_PERSONAL_NOTES } from '../../../queries/usePersonalNotes'
import { ADD_PERSONAL_NOTE } from '../../../mutations/addPersonalNote'
import { useMutation } from '@apollo/client'
import { PrimaryButton } from '../../../components/primitives/Button'
import { Div } from '../../../components/primitives/Div'
import moment from 'moment'
import { Modal } from '../../../modals'
import DeletePersonalNote from '../../../modals/DeletePersonalNote'
import { Loader } from '../../../components/primitives/Loader'

export default function Notes({ uuid, setUserFeedback }) {

  const [noteText, setNoteText] = useState('')
  const [noteFieldIsEmpty, setNoteFieldIsEmpty] = useState(false)

  const { data, loading, error } = usePersonalNotes(uuid)

  const addNoteButtonRef = useRef(null)

  const [addPersonalNote] = useMutation(ADD_PERSONAL_NOTE, {
    variables: {
      investorUuid: uuid,
      noteText,
    },
    refetchQueries: [
      { query: GET_PERSONAL_NOTES, variables: { investorUuid: uuid } }
    ],
    onError: (error) => {
      console.error(error)
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: () => {
      setUserFeedback({ success: true, text: 'Note added' })
      setNoteText('')
    }
  })

  const handleCheck = () => {
    if (noteText.trim().length === 0) {
      setNoteFieldIsEmpty(true)
    } else {
      addPersonalNote()
    }
  }

  useEffect(() => {
    setNoteFieldIsEmpty(false)
  }, [noteText])

  return (
    <Box css={{ fb: '49.5%', overflowX: 'scroll' }}>
      <Flex css={{ mb: '12px', alignItems: 'baseline' }}>
        <Header2>Notes</Header2>
        <Header5 css={{ color: '$primary', fontWeight: '100' }}>&nbsp;&nbsp;&nbsp;These notes are only visible to you</Header5>
      </Flex>

      {loading &&
        <Div css={{mb: '$12'}}>
          <Loader size='20' />
        </Div>
      }
      {error &&
        <Paragraph css={{mb: '$12'}}>An error occurred. Please try again or report this bug.</Paragraph>
      }
      {data?.investorPersonalNotes.map((note) => {
        return <Div key={note.uuid} css={{ mb: '$6' }}>
          <Flex>
            <Header4 css={{ my: '$0', fontSize: '$11' }}>{moment(note.createdAt).format('lll')}</Header4>
            <Modal
              triggerButtonStyle={'DeleteComment'}
              Component={DeletePersonalNote}
              investorUuid={note.investorUuid.uuid}
              personalNoteUuid={note.uuid}
              setUserFeedback={setUserFeedback}
            />
          </Flex>
          <Paragraph css={{ fontWeight: '$400' }}>{note.noteText}</Paragraph>
        </Div>
      })}

      <Flex>
        <TextAreaWrapper isRequired={noteFieldIsEmpty}>
          <TextareaAutosize
            value={noteText}
            onChange={(e) => setNoteText(e.target.value)}
            onKeyPress={(event) => {
              const { key } = event;
              if (key === "Enter") {
                addNoteButtonRef.current.click();
                event.target.blur()
              }
            }}
          />
        </TextAreaWrapper>
        <PrimaryButton
          onClick={() => handleCheck()}
          ref={addNoteButtonRef}
        >Add&nbsp;note</PrimaryButton>
      </Flex>
      {noteFieldIsEmpty && <RequiredText>This field cannot be empty</RequiredText>}

    </Box>
  )
}

export const TextAreaWrapper = styled('div', {
  maxHight: 'fit-content',
  width: '100%',
  mr: '$12',
  '& > textarea': {
    resize: 'none',
    color: '$grey600',
    border: '1px solid $grey100',
    borderRadius: '4px',
    padding: '8px',
    outlineColor: '$grey100',
    fontSize: '$13',
    width: '100%',
    '&::placeholder': {
      color: '$grey100',
    },
  },
  variants: {
    isRequired: {
      true: {
        '& > textarea': {
          border: '1px solid $red'
        }
      }
    }
  }
})


