import { useState } from 'react'
import { styled } from '../../../styles'
import moment from 'moment'
import { Table } from '../../../components/primitives/Table'
import { ModifiedBadge } from '../../../components/patterns/ModifiedMark'
import { Span } from '../../../components/primitives/Span'

export default function SummaryDetail({ investor, extra }) {

  const [descriptionLength, setDescriptionLength] = useState(300)
  const [fullDescriptionShown, setFullDescriptionShown] = useState(false)

  return (
    <Table css={{
      mt: '$8', ml: '110px', w: 'calc(100% - 110px)',
      '@bp1': {
        ml: '0px',
        w: '100%',
      }
    }}>
      <tbody>
        <TableRow>
          <InvestmentTableKey>Lead&nbsp;investments&nbsp;median</InvestmentTableKey>
          <InvestmentTableValue>
            ${new Intl.NumberFormat().format(investor.leadInvestmentsMedian)}
          </InvestmentTableValue>
        </TableRow>

        <TableRow>
          <InvestmentTableKey>Round types</InvestmentTableKey>
          <InvestmentTableValue>
            {extra.funding_round_pre_seed === undefined && investor.fundingRoundPreSeed
              ? 'PreSeed\u00A0\u00A0\u00A0'
              : extra.funding_round_pre_seed
              && 'PreSeed\u00A0\u00A0\u00A0'}

            {extra.funding_round_seed === undefined && investor.fundingRoundSeed
              ? 'Seed\u00A0\u00A0\u00A0'
              : extra.funding_round_seed
              && 'Seed\u00A0\u00A0\u00A0'}
            {extra.funding_round_series_a === undefined && investor.fundingRoundSeriesA
              ? 'Series\u00A0A\u00A0\u00A0\u00A0'
              : extra.funding_round_series_a
              && 'Series\u00A0A\u00A0\u00A0\u00A0'}
            {extra.funding_round_series_b === undefined && investor.fundingRoundSeriesB
              ? 'Series\u00A0B\u00A0\u00A0\u00A0'
              : extra.funding_round_series_b
              && 'Series\u00A0B\u00A0\u00A0\u00A0'}
            {extra.funding_round_series_c === undefined && investor.fundingRoundSeriesC
              ? 'Series\u00A0C'
              : extra.funding_round_series_c
              && 'Series\u00A0C'}
            {
              (extra && investor.fundingRoundPreSeed !== extra.funding_round_pre_seed && investor.fundingRoundPreSeed !== '' && extra.funding_round_pre_seed !== undefined) ||
                (extra && investor.fundingRoundSeed !== extra.funding_round_seed && investor.fundingRoundSeed !== '' && extra.funding_round_seed !== undefined) ||
                (extra && investor.fundingRoundSeriesA !== extra.funding_round_series_a && investor.fundingRoundSeriesA !== '' && extra.funding_round_series_a !== undefined) ||
                (extra && investor.fundingRoundSeriesB !== extra.funding_round_series_b && investor.fundingRoundSeriesB !== '' && extra.funding_round_series_b !== undefined) ||
                (extra && investor.fundingRoundSeriesC !== extra.funding_round_series_c && investor.fundingRoundSeriesC !== '' && extra.funding_round_series_c !== undefined)
                ? <ModifiedBadge updatedByName={extra.updated_by_name} updatedAt={extra.updated_at} />
                : <></>
            }
          </InvestmentTableValue>
        </TableRow>

        <TableRow>
          <InvestmentTableKey>Address</InvestmentTableKey>
          <InvestmentTableValue>{investor.address}</InvestmentTableValue>
        </TableRow>

        {investor.foundedOn || extra.founded_on ?
          <TableRow>
            <InvestmentTableKey>Founded on</InvestmentTableKey>
            <InvestmentTableValue>
              {extra.founded_on ? moment(extra.founded_on).format('ll') : moment(investor.foundedOn).format('ll')}
              {extra && extra.founded_on !== investor.foundedOn && <ModifiedBadge updatedByName={extra.updated_by_name} updatedAt={extra.updated_at} />}
            </InvestmentTableValue>
          </TableRow>
          : <></>
        }

        <TableRow>
          <InvestmentTableKey>Description</InvestmentTableKey>
          <InvestmentTableValue>
            {investor.description !== 'NaN' && investor.description.substring(0, descriptionLength)}
            {investor.description.length > 320 &&
              <>
                {fullDescriptionShown ? <Span>&nbsp;&nbsp;</Span> : <Span>...&nbsp;&nbsp;</Span>}
                <Span
                  css={{ textDecoration: 'underline', cursor: 'pointer', '&:hover': { color: '$secondary' } }}
                  onClick={() => fullDescriptionShown
                    ? (setDescriptionLength(300),
                      setFullDescriptionShown(false))
                    : (setDescriptionLength(investor.description.length),
                      setFullDescriptionShown(true))
                  }
                >
                  {fullDescriptionShown ? 'Read less' : 'Read more'}
                </Span>
              </>
            }
          </InvestmentTableValue>
        </TableRow>
      </tbody>
    </Table >
  )
}


const TableRow = styled('tr', {
  '@bp1': {
    display: 'flex',
    fd: 'column',
  }
})

const InvestmentTableKey = styled('td', {
  width: '195px',
  paddingTop: '$6',
  fontSize: '13px',
  color: '#9d9d9d',
  '@bp1': {
    paddingTop: '$6'
  }
})

const InvestmentTableValue = styled('td', {
  fontSize: '13px',
  color: '#58595B',
  '@bp1': {
    paddingLeft: '$10'
  }
})
