import { useState } from 'react'
import { Flex } from '../../../../components/primitives/Flex'
import { FlexSpaceBetween } from '../../../../components/primitives/FlexSpaceBetween'
import { Paragraph } from '../../../../components/primitives/Text'
import { SmallLink } from '../../../../components/primitives/Link'
import { Modal } from '../../../../modals'
import { ButtonsWrapper, PrimaryButton } from '../../../../components/primitives/Button'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg'
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg'
import { ReactComponent as LeaveIcon } from '../../../../assets/icons/leave.svg'
import { ReactComponent as ExportIcon } from '../../../../assets/icons/download.svg'
import { IconInsideButton } from '../../../../components/primitives/IconWrapper'
import AddInvestor from './components/AddInvestor'
import EditList from '../../../../modals/EditList'
import DeleteList from '../../../../modals/DeleteList'
import { GET_EXPORT_LIST_URL } from '../../../../mutations/exportList'
import Download from '../../../../components/patterns/DownloadBox'
import { useMutation } from '@apollo/client'
import ClickAwayListener from 'react-click-away-listener'
import StepOutCustomList from '../../../../modals/StepOutCustomList'

export default function TopSection({ uuid, setUserFeedback, listName, listDescription, setListDescription, collaborators, getCustomListInvestor, creator }) {

  const localStorageUsername = localStorage.getItem('username')

  const [downloadBox, setDownloadBox] = useState(false)

  const [exportList, { data, error }] = useMutation(GET_EXPORT_LIST_URL, {
    variables: {
      listUuid: uuid
    },
    onError: () => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: () => {
      setDownloadBox(true)
    }
  })

  return (
    <FlexSpaceBetween css={{ '@bp3': { display: 'block' } }}>

      <Flex css={{ ml: '$24', mb: '$24', '@bp3': { ml: '0px' } }}>
        <Paragraph>
          <SmallLink href='/search-investors'>Home</SmallLink>
        </Paragraph>
        <Paragraph>
          &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
        </Paragraph>
        <Paragraph>
          <SmallLink href='/my-lists'>My Lists</SmallLink>
        </Paragraph>
        <Paragraph>
          &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;
        </Paragraph>
        <Paragraph css={{ fontWeight: '500' }}>{listName}</Paragraph>
      </Flex>

      <Flex css={{ mb: '$24', '@bp2': { display: 'block' } }}>

        <AddInvestor
          uuid={uuid}
          setUserFeedback={setUserFeedback}
          getCustomListInvestor={getCustomListInvestor}
        />

        <ButtonsWrapper css={{ '@bp2': { justifyContent: 'flex-start', mt: '12px' } }}>

          {localStorageUsername === creator
            ?
            <>
              <Modal
                triggerButtonLabel={(
                  <Flex css={{ ai: 'center' }}>
                    Edit&nbsp;list
                    <IconInsideButton><EditIcon /></IconInsideButton>
                  </Flex>
                )}
                title={'Edit list'}
                listName={listName}
                listDescription={listDescription}
                collaborators={collaborators}
                setListDescription={setListDescription}
                Component={EditList}
                setUserFeedback={setUserFeedback}
              />

              <Modal
                triggerButtonLabel={(
                  <Flex css={{ ai: 'center' }}>
                    Delete&nbsp;list
                    <IconInsideButton><DeleteIcon /></IconInsideButton>
                  </Flex>
                )}
                title={''}
                listName={listName}
                listUuid={uuid}
                Component={DeleteList}
                setUserFeedback={setUserFeedback}
                hasCollaborator={collaborators?.length >= 2}
              />
            </>
            :
            <Modal
              triggerButtonLabel={(
                <Flex css={{ ai: 'center' }}>
                  Step out from list
                  <IconInsideButton css={{ mb: '$0' }}><LeaveIcon /></IconInsideButton>
                </Flex>
              )}
              listName={listName}
              listUuid={uuid}
              Component={StepOutCustomList}
              setUserFeedback={setUserFeedback}
            />
          }

          <ClickAwayListener onClickAway={() => setDownloadBox(false)}>
            <span>
              <PrimaryButton
                onClick={() => exportList()}
                css={{ position: 'relative' }}
              >
                <Flex css={{ ai: 'center' }}>
                  Export list
                  <IconInsideButton><ExportIcon /></IconInsideButton>
                </Flex>
              </PrimaryButton>
              {downloadBox &&
                <Download
                  listUuid={uuid}
                  downloadLink={data?.getExportListUrl.exportList.url}
                  setDownloadBox={setDownloadBox}
                  listOrContact={'list'}
                />
              }
            </span>
          </ClickAwayListener>

        </ButtonsWrapper>
      </Flex>
    </FlexSpaceBetween>
  )
}
