import { gql, useQuery, useLazyQuery } from '@apollo/client'

export const GET_CUSTOM_LIST_INVESTOR = gql`
query customListInvestor (
  $investorUuid: String
  $listUuid: String
) {
  customListInvestor (
    investorUuid: $investorUuid
    listUuid: $listUuid
  ) {
      investorUuid {
          uuid
          name
          homepageUrl
          linkedinUrl
          cbUrl
          twitterUrl
          logoUrl
          logoUrlSmall
          investmentCount
          leadInvestmentsMin
          leadInvestmentsMax
          countryCode
          city 
          permalink
          extra
          comments {
            uuid
            addedBy {
              id
              username
              firstName
              lastName
            }
            createdAt
            commentText
          }
          investor{
            uuid
            name
            email
          }
          nautaCoInvestor
        }

        customlistinvestornoteSet {
          uuid
          addedBy{
            email
            name
          }
          createdAt
          note
        }

        note
        noteAddedBy {
          id
          username
          firstName
          lastName
        }
        noteCreatedAt
        status {
          id 
          text 
        }
  }
}
`
