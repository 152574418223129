import { gql } from '@apollo/client'

export const EDIT_NOTE = gql`
mutation editCustomListInvestorNote (
  $noteUuid: String!,
  $note: String!,
  $mentionedUserIds: String,
) {
  editCustomListInvestorNote (
    noteUuid: $noteUuid,
    note: $note,
    mentionedUserIds: $mentionedUserIds,
  ) {
    customListInvestorNote {
      uuid
      note
      updatedAt
      createdAt
      addedBy {
        email
        name
      }
    }
  }
}
`

export const ADD_NOTE = gql`
mutation addCustomListInvestorNote (
  $investorUuid: String!, 
  $listUuid: String!, 
  $mentionedUserIds: String,
  $note: String!,
) {
 addCustomListInvestorNote(
  investorUuid: $investorUuid, 
  listUuid: $listUuid, 
  mentionedUserIds: $mentionedUserIds
  note: $note
  ) {
    customListInvestorNote {
      uuid
      addedBy {
        email
        name
      }
      createdAt
      note
    }
  }
}
`