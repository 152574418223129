import { styled } from "../../styles"

export const MentionsTextarea = styled('div', {
  width: '100%',
  color: '$grey600',
  outlineColor: '$grey100',
  fontSize: '$13',
  '&::placeholder': {
    color: '$grey100',
  },
  '& > div': {
    padding: '8px',
  },
  '& textarea': {
    height: '100%',
    border: '1px solid $grey100',
    padding: '8px',
    borderRadius: '4px',
    lineHeight: 'inherit !important',
    '&:focus': {
      outline: 'none'
    },
  },
  '.mentions__mention': {
    position: 'relative',
    zIndex: '$z100',
    color: '$primary',
    pe: 'none',
  },
  '.mentions__suggestions': {
    br: '$small',
    border: '1px solid $grey100',
    marginTop: '28px !important',
    zIndex: '300 !important',
  },
  '.mentions__suggestions__list': {
    bgc: 'white',
    maxHeight: '160px',
    overflow: 'auto',
  },
  '.mentions__suggestions__item': {
    padding: '$4 $8',
    borderBottom: '1px solid $grey100',
  },
  '.mentions__suggestions__item--focused': {
    bgc: '$grey100',
  },
  '.mentions__highlighter__substring': {
    visibility: 'hidden'
  },
  variants: {
    isRequired: {
      true: {
        '.mentions__input': {
          border: '1px solid $red',
        },
      }
    },
  },
})

