import { gql } from '@apollo/client';

export const EDIT_CONTACT = gql`
mutation editContact (
  $approved: Boolean
  $comment: String
  $email: String
  $name: String
  $uuid: String!
) {
  editContact (
   approved: $approved
   comment: $comment
   email: $email
   name: $name
   uuid: $uuid
  ) {
    contact {
      uuid
      name
      email
      comment
      investor {
        uuid
        name
        logoUrl
        logoUrlSmall
        twitterUrl
        linkedinUrl
        cbUrl
        homepageUrl
        permalink
        extra
      }
      approved
    }
  }
}
`