import React, { useState } from 'react'
import { PrimaryButton, SecondaryButton, ButtonsWrapper } from '../components/primitives/Button'
import { DropdownBox } from '../components/patterns/Dropdown'
import { useLists } from '../queries/useLists'
import { ADD_INVESTORS_TO_CUSTOM_LIST } from '../mutations/addInvestorsToCustomList'
import { CREATE_CUSTOM_LIST } from '../mutations/createCustomList'
import { useMutation } from '@apollo/client';
import { Input } from '../components/primitives/Input'
import { Paragraph } from '../components/primitives/Text'
import { ADD_SEARCH_RESULTS_TO_CUSTOM_LIST } from '../mutations/addSearchResultsToCustomList'
import { styled } from '../styles'
import { GET_LISTS } from '../queries/useLists'
import { Div } from '../components/primitives/Div'

export default function AddSelectedInvestorsToList({
  close,
  selectedInvestors,
  countryCodes,
  fundingRound,
  hasContact,
  investmentCount,
  investorType,
  lowerBound,
  portfolioCategories,
  portfolioCountryCodes,
  searchbox,
  upperBound,
  setUserFeedback,
  investmentUuids,
}) {

  const [listName, setListName] = useState(null)
  const [listUuid, setListUuid] = useState(null)
  const [nameOfNewList, setNameOfNewList] = useState('')

  const { data: listsData } = useLists()

  //adding only selected investors
  const [addInvestorsToCustomList] = useMutation(ADD_INVESTORS_TO_CUSTOM_LIST, {
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      close()
      setUserFeedback({
        success: true,
        text: 'Added to list',
        link: `/my-lists/${data.addInvestorsToCustomList.customList.name}?uuid=${data.addInvestorsToCustomList.customList.uuid}`,
        linkText: 'See list'
      })
    }
  })

  //adding all investors filtered by requirements
  const [addSearchResultsToCustomList] = useMutation(ADD_SEARCH_RESULTS_TO_CUSTOM_LIST, {
    refetchQueries: [
      { query: GET_LISTS }
    ],
    onError: (error) => {
      console.error(error)
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      // console.log(data)
      close()
      setUserFeedback({
        success: true,
        text: 'Added to list',
        link: `/my-lists/${data.addSearchResultsToCustomList.customList.name}?uuid=${data.addSearchResultsToCustomList.customList.uuid}`,
        linkText: 'See list'
      })
    }
  })

  const [createList] = useMutation(CREATE_CUSTOM_LIST, {
    variables: {
      name: nameOfNewList,
      description: ''
    },
    refetchQueries: [
      { query: GET_LISTS }
    ],
    onError: (error) => {
      console.error(error)
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: (data) => {
      // console.log('data after createList===>', data)
      if (selectedInvestors !== undefined) {
        addInvestorsToCustomList({
          variables: {
            listUuid: data.createCustomList.customList.uuid,
            investorUuids: selectedInvestors?.toString()
          }
        })
      } else {
        addSearchResultsToCustomList({
          variables: {
            countryCodes,
            fundingRound,
            hasContact,
            investmentCount,
            investmentUuids,
            investorType,
            listUuid: data.createCustomList.customList.uuid,
            lowerBound,
            portfolioCategories,
            portfolioCountryCodes,
            searchbox,
            upperBound,
          },
        })
      }
    }
  })

  const handleAdd = () => {
    if (listName === 'add new customlist') {
      createList()
    } else if (selectedInvestors !== undefined) {
      addInvestorsToCustomList({
        variables: {
          listUuid,
          investorUuids: selectedInvestors?.toString()
        }
      })
    } else {
      addSearchResultsToCustomList({
        variables: {
          countryCodes,
          fundingRound,
          hasContact,
          investmentCount,
          investmentUuids,
          investorType,
          listUuid,
          lowerBound,
          portfolioCategories,
          portfolioCountryCodes,
          searchbox,
          upperBound,
        }
      })
    }
  }

  return (
    <Form>
      <DropdownBox
        options={listsData?.customLists.map((list) => {
          return { label: list.name, value: list.uuid }
        })}
        item={listName}
        setItem={setListName}
        setListUuid={setListUuid}
      />

      {listName === 'add new customlist'
        &&
        <Div css={{ marginTop: '24px', marginBottom: '12px' }}>
          <Paragraph>Name of the new list:</Paragraph>
          <Input
            value={nameOfNewList}
            onChange={(e) => setNameOfNewList(e.target.value)}
          />
        </Div>
      }

      <ButtonsWrapper css={{ mt: '$24' }}>
        <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
        <PrimaryButton
          type='button'
          onClick={() => handleAdd()}
          disabled={!listName || listName === 'add new customlist' && nameOfNewList === ''}
        >Add</PrimaryButton>
      </ButtonsWrapper>
    </Form>
  )
}


const Form = styled('div', {
  marginTop: '24px',
  width: '360px',
  '@bp0': {
    width: 'calc(100vw - 60px)'
  }
})

