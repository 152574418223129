export const CategoryOptions = [
    {
      label: "3D Printing",
      value: "3D Printing"
    },
    {
      label: "3D Technology",
      value: "3D Technology"
    },
    {
      label: "A\\/B Testing",
      value: "A\\/B Testing"
    },
    {
      label: "Accounting",
      value: "Accounting"
    },
    {
      label: "Ad Exchange",
      value: "Ad Exchange"
    },
    {
      label: "Ad Network",
      value: "Ad Network"
    },
    {
      label: "Ad Retargeting",
      value: "Ad Retargeting"
    },
    {
      label: "Ad Server",
      value: "Ad Server"
    },
    {
      label: "Ad Targeting",
      value: "Ad Targeting"
    },
    {
      label: "Adult",
      value: "Adult"
    },
    {
      label: "Advanced Materials",
      value: "Advanced Materials"
    },
    {
      label: "Adventure Travel",
      value: "Adventure Travel"
    },
    {
      label: "Advertising",
      value: "Advertising"
    },
    {
      label: "Advertising Platforms",
      value: "Advertising Platforms"
    },
    {
      label: "Advice",
      value: "Advice"
    },
    {
      label: "Aerospace",
      value: "Aerospace"
    },
    {
      label: "Affiliate Marketing",
      value: "Affiliate Marketing"
    },
    {
      label: "AgTech",
      value: "AgTech"
    },
    {
      label: "Agriculture",
      value: "Agriculture"
    },
    {
      label: "Air Transportation",
      value: "Air Transportation"
    },
    {
      label: "Alternative Medicine",
      value: "Alternative Medicine"
    },
    {
      label: "Alumni",
      value: "Alumni"
    },
    {
      label: "American Football",
      value: "American Football"
    },
    {
      label: "Amusement Park and Arcade",
      value: "Amusement Park and Arcade"
    },
    {
      label: "Analytics",
      value: "Analytics"
    },
    {
      label: "Android",
      value: "Android"
    },
    {
      label: "Angel Investment",
      value: "Angel Investment"
    },
    {
      label: "Animal Feed",
      value: "Animal Feed"
    },
    {
      label: "Animation",
      value: "Animation"
    },
    {
      label: "App Discovery",
      value: "App Discovery"
    },
    {
      label: "App Marketing",
      value: "App Marketing"
    },
    {
      label: "Application Performance Management",
      value: "Application Performance Management"
    },
    {
      label: "Application Specific Integrated Circuit (ASIC)",
      value: "Application Specific Integrated Circuit (ASIC)"
    },
    {
      label: "Apps",
      value: "Apps"
    },
    {
      label: "Aquaculture",
      value: "Aquaculture"
    },
    {
      label: "Architecture",
      value: "Architecture"
    },
    {
      label: "Archiving Service",
      value: "Archiving Service"
    },
    {
      label: "Art",
      value: "Art"
    },
    {
      label: "Artificial Intelligence",
      value: "Artificial Intelligence"
    },
    {
      label: "Asset Management",
      value: "Asset Management"
    },
    {
      label: "Assisted Living",
      value: "Assisted Living"
    },
    {
      label: "Assistive Technology",
      value: "Assistive Technology"
    },
    {
      label: "Association",
      value: "Association"
    },
    {
      label: "Auctions",
      value: "Auctions"
    },
    {
      label: "Audio",
      value: "Audio"
    },
    {
      label: "Audiobooks",
      value: "Audiobooks"
    },
    {
      label: "Augmented Reality",
      value: "Augmented Reality"
    },
    {
      label: "Auto Insurance",
      value: "Auto Insurance"
    },
    {
      label: "Automotive",
      value: "Automotive"
    },
    {
      label: "Autonomous Vehicles",
      value: "Autonomous Vehicles"
    },
    {
      label: "B2B",
      value: "B2B"
    },
    {
      label: "B2C",
      value: "B2C"
    },
    {
      label: "Baby",
      value: "Baby"
    },
    {
      label: "Bakery",
      value: "Bakery"
    },
    {
      label: "Banking",
      value: "Banking"
    },
    {
      label: "Baseball",
      value: "Baseball"
    },
    {
      label: "Basketball",
      value: "Basketball"
    },
    {
      label: "Battery",
      value: "Battery"
    },
    {
      label: "Beauty",
      value: "Beauty"
    },
    {
      label: "Big Data",
      value: "Big Data"
    },
    {
      label: "Billing",
      value: "Billing"
    },
    {
      label: "Biofuel",
      value: "Biofuel"
    },
    {
      label: "Bioinformatics",
      value: "Bioinformatics"
    },
    {
      label: "Biomass Energy",
      value: "Biomass Energy"
    },
    {
      label: "Biometrics",
      value: "Biometrics"
    },
    {
      label: "Biopharma",
      value: "Biopharma"
    },
    {
      label: "Biotechnology",
      value: "Biotechnology"
    },
    {
      label: "Bitcoin",
      value: "Bitcoin"
    },
    {
      label: "Blockchain",
      value: "Blockchain"
    },
    {
      label: "Blogging Platforms",
      value: "Blogging Platforms"
    },
    {
      label: "Boating",
      value: "Boating"
    },
    {
      label: "Brand Marketing",
      value: "Brand Marketing"
    },
    {
      label: "Brewing",
      value: "Brewing"
    },
    {
      label: "Broadcasting",
      value: "Broadcasting"
    },
    {
      label: "Browser Extensions",
      value: "Browser Extensions"
    },
    {
      label: "Building Maintenance",
      value: "Building Maintenance"
    },
    {
      label: "Building Material",
      value: "Building Material"
    },
    {
      label: "Business Development",
      value: "Business Development"
    },
    {
      label: "Business Information Systems",
      value: "Business Information Systems"
    },
    {
      label: "Business Intelligence",
      value: "Business Intelligence"
    },
    {
      label: "Business Travel",
      value: "Business Travel"
    },
    {
      label: "CAD",
      value: "CAD"
    },
    {
      label: "CMS",
      value: "CMS"
    },
    {
      label: "CRM",
      value: "CRM"
    },
    {
      label: "Call Center",
      value: "Call Center"
    },
    {
      label: "Cannabis",
      value: "Cannabis"
    },
    {
      label: "Car Sharing",
      value: "Car Sharing"
    },
    {
      label: "Career Planning",
      value: "Career Planning"
    },
    {
      label: "Casino",
      value: "Casino"
    },
    {
      label: "Casual Games",
      value: "Casual Games"
    },
    {
      label: "Catering",
      value: "Catering"
    },
    {
      label: "Cause Marketing",
      value: "Cause Marketing"
    },
    {
      label: "Celebrity",
      value: "Celebrity"
    },
    {
      label: "Charity",
      value: "Charity"
    },
    {
      label: "Charter Schools",
      value: "Charter Schools"
    },
    {
      label: "Chemical",
      value: "Chemical"
    },
    {
      label: "Chemical Engineering",
      value: "Chemical Engineering"
    },
    {
      label: "Child Care",
      value: "Child Care"
    },
    {
      label: "Children",
      value: "Children"
    },
    {
      label: "CivicTech",
      value: "CivicTech"
    },
    {
      label: "Civil Engineering",
      value: "Civil Engineering"
    },
    {
      label: "Classifieds",
      value: "Classifieds"
    },
    {
      label: "Clean Energy",
      value: "Clean Energy"
    },
    {
      label: "CleanTech",
      value: "CleanTech"
    },
    {
      label: "Clinical Trials",
      value: "Clinical Trials"
    },
    {
      label: "Cloud Computing",
      value: "Cloud Computing"
    },
    {
      label: "Cloud Data Services",
      value: "Cloud Data Services"
    },
    {
      label: "Cloud Infrastructure",
      value: "Cloud Infrastructure"
    },
    {
      label: "Cloud Management",
      value: "Cloud Management"
    },
    {
      label: "Cloud Security",
      value: "Cloud Security"
    },
    {
      label: "Cloud Storage",
      value: "Cloud Storage"
    },
    {
      label: "Coffee",
      value: "Coffee"
    },
    {
      label: "Collaboration",
      value: "Collaboration"
    },
    {
      label: "Collaborative Consumption",
      value: "Collaborative Consumption"
    },
    {
      label: "Collectibles",
      value: "Collectibles"
    },
    {
      label: "Collection Agency",
      value: "Collection Agency"
    },
    {
      label: "College Recruiting",
      value: "College Recruiting"
    },
    {
      label: "Comics",
      value: "Comics"
    },
    {
      label: "Commercial",
      value: "Commercial"
    },
    {
      label: "Commercial Insurance",
      value: "Commercial Insurance"
    },
    {
      label: "Commercial Lending",
      value: "Commercial Lending"
    },
    {
      label: "Commercial Real Estate",
      value: "Commercial Real Estate"
    },
    {
      label: "Communication Hardware",
      value: "Communication Hardware"
    },
    {
      label: "Communications Infrastructure",
      value: "Communications Infrastructure"
    },
    {
      label: "Communities",
      value: "Communities"
    },
    {
      label: "Compliance",
      value: "Compliance"
    },
    {
      label: "Computer",
      value: "Computer"
    },
    {
      label: "Computer Vision",
      value: "Computer Vision"
    },
    {
      label: "Concerts",
      value: "Concerts"
    },
    {
      label: "Confectionery",
      value: "Confectionery"
    },
    {
      label: "Console Games",
      value: "Console Games"
    },
    {
      label: "Construction",
      value: "Construction"
    },
    {
      label: "Consulting",
      value: "Consulting"
    },
    {
      label: "Consumer",
      value: "Consumer"
    },
    {
      label: "Consumer Applications",
      value: "Consumer Applications"
    },
    {
      label: "Consumer Electronics",
      value: "Consumer Electronics"
    },
    {
      label: "Consumer Goods",
      value: "Consumer Goods"
    },
    {
      label: "Consumer Lending",
      value: "Consumer Lending"
    },
    {
      label: "Consumer Research",
      value: "Consumer Research"
    },
    {
      label: "Consumer Reviews",
      value: "Consumer Reviews"
    },
    {
      label: "Consumer Software",
      value: "Consumer Software"
    },
    {
      label: "Contact Management",
      value: "Contact Management"
    },
    {
      label: "Content",
      value: "Content"
    },
    {
      label: "Content Creators",
      value: "Content Creators"
    },
    {
      label: "Content Delivery Network",
      value: "Content Delivery Network"
    },
    {
      label: "Content Discovery",
      value: "Content Discovery"
    },
    {
      label: "Content Marketing",
      value: "Content Marketing"
    },
    {
      label: "Content Syndication",
      value: "Content Syndication"
    },
    {
      label: "Contests",
      value: "Contests"
    },
    {
      label: "Continuing Education",
      value: "Continuing Education"
    },
    {
      label: "Cooking",
      value: "Cooking"
    },
    {
      label: "Corporate Training",
      value: "Corporate Training"
    },
    {
      label: "Corrections Facilities",
      value: "Corrections Facilities"
    },
    {
      label: "Cosmetic Surgery",
      value: "Cosmetic Surgery"
    },
    {
      label: "Cosmetics",
      value: "Cosmetics"
    },
    {
      label: "Coupons",
      value: "Coupons"
    },
    {
      label: "Courier Service",
      value: "Courier Service"
    },
    {
      label: "Coworking",
      value: "Coworking"
    },
    {
      label: "Craft Beer",
      value: "Craft Beer"
    },
    {
      label: "Creative Agency",
      value: "Creative Agency"
    },
    {
      label: "Credit",
      value: "Credit"
    },
    {
      label: "Credit Bureau",
      value: "Credit Bureau"
    },
    {
      label: "Credit Cards",
      value: "Credit Cards"
    },
    {
      label: "Cricket",
      value: "Cricket"
    },
    {
      label: "Crowdfunding",
      value: "Crowdfunding"
    },
    {
      label: "Crowdsourcing",
      value: "Crowdsourcing"
    },
    {
      label: "Cryptocurrency",
      value: "Cryptocurrency"
    },
    {
      label: "Customer Service",
      value: "Customer Service"
    },
    {
      label: "Cyber Security",
      value: "Cyber Security"
    },
    {
      label: "Cycling",
      value: "Cycling"
    },
    {
      label: "DIY",
      value: "DIY"
    },
    {
      label: "DRM",
      value: "DRM"
    },
    {
      label: "DSP",
      value: "DSP"
    },
    {
      label: "Darknet",
      value: "Darknet"
    },
    {
      label: "Data Center",
      value: "Data Center"
    },
    {
      label: "Data Center Automation",
      value: "Data Center Automation"
    },
    {
      label: "Data Integration",
      value: "Data Integration"
    },
    {
      label: "Data Mining",
      value: "Data Mining"
    },
    {
      label: "Data Storage",
      value: "Data Storage"
    },
    {
      label: "Data Visualization",
      value: "Data Visualization"
    },
    {
      label: "Database",
      value: "Database"
    },
    {
      label: "Dating",
      value: "Dating"
    },
    {
      label: "Debit Cards",
      value: "Debit Cards"
    },
    {
      label: "Debt Collections",
      value: "Debt Collections"
    },
    {
      label: "Delivery",
      value: "Delivery"
    },
    {
      label: "Delivery Service",
      value: "Delivery Service"
    },
    {
      label: "Dental",
      value: "Dental"
    },
    {
      label: "Desktop Apps",
      value: "Desktop Apps"
    },
    {
      label: "Developer APIs",
      value: "Developer APIs"
    },
    {
      label: "Developer Platform",
      value: "Developer Platform"
    },
    {
      label: "Developer Tools",
      value: "Developer Tools"
    },
    {
      label: "Diabetes",
      value: "Diabetes"
    },
    {
      label: "Dietary Supplements",
      value: "Dietary Supplements"
    },
    {
      label: "Digital Entertainment",
      value: "Digital Entertainment"
    },
    {
      label: "Digital Marketing",
      value: "Digital Marketing"
    },
    {
      label: "Digital Media",
      value: "Digital Media"
    },
    {
      label: "Digital Signage",
      value: "Digital Signage"
    },
    {
      label: "Direct Marketing",
      value: "Direct Marketing"
    },
    {
      label: "Direct Sales",
      value: "Direct Sales"
    },
    {
      label: "Distillery",
      value: "Distillery"
    },
    {
      label: "Diving",
      value: "Diving"
    },
    {
      label: "Document Management",
      value: "Document Management"
    },
    {
      label: "Document Preparation",
      value: "Document Preparation"
    },
    {
      label: "Domain Registrar",
      value: "Domain Registrar"
    },
    {
      label: "Drone Management",
      value: "Drone Management"
    },
    {
      label: "Drones",
      value: "Drones"
    },
    {
      label: "E-Commerce",
      value: "E-Commerce"
    },
    {
      label: "E-Commerce Platforms",
      value: "E-Commerce Platforms"
    },
    {
      label: "E-Learning",
      value: "E-Learning"
    },
    {
      label: "E-Signature",
      value: "E-Signature"
    },
    {
      label: "EBooks",
      value: "EBooks"
    },
    {
      label: "EdTech",
      value: "EdTech"
    },
    {
      label: "Ediscovery",
      value: "Ediscovery"
    },
    {
      label: "Education",
      value: "Education"
    },
    {
      label: "Edutainment",
      value: "Edutainment"
    },
    {
      label: "Elder Care",
      value: "Elder Care"
    },
    {
      label: "Elderly",
      value: "Elderly"
    },
    {
      label: "Electric Vehicle",
      value: "Electric Vehicle"
    },
    {
      label: "Electrical Distribution",
      value: "Electrical Distribution"
    },
    {
      label: "Electronic Design Automation (EDA)",
      value: "Electronic Design Automation (EDA)"
    },
    {
      label: "Electronic Health Record (EHR)",
      value: "Electronic Health Record (EHR)"
    },
    {
      label: "Electronics",
      value: "Electronics"
    },
    {
      label: "Email",
      value: "Email"
    },
    {
      label: "Email Marketing",
      value: "Email Marketing"
    },
    {
      label: "Embedded Software",
      value: "Embedded Software"
    },
    {
      label: "Embedded Systems",
      value: "Embedded Systems"
    },
    {
      label: "Emergency Medicine",
      value: "Emergency Medicine"
    },
    {
      label: "Emerging Markets",
      value: "Emerging Markets"
    },
    {
      label: "Employee Benefits",
      value: "Employee Benefits"
    },
    {
      label: "Employment",
      value: "Employment"
    },
    {
      label: "Energy",
      value: "Energy"
    },
    {
      label: "Energy Efficiency",
      value: "Energy Efficiency"
    },
    {
      label: "Energy Management",
      value: "Energy Management"
    },
    {
      label: "Energy Storage",
      value: "Energy Storage"
    },
    {
      label: "Enterprise",
      value: "Enterprise"
    },
    {
      label: "Enterprise Applications",
      value: "Enterprise Applications"
    },
    {
      label: "Enterprise Resource Planning (ERP)",
      value: "Enterprise Resource Planning (ERP)"
    },
    {
      label: "Enterprise Software",
      value: "Enterprise Software"
    },
    {
      label: "Environmental Consulting",
      value: "Environmental Consulting"
    },
    {
      label: "Environmental Engineering",
      value: "Environmental Engineering"
    },
    {
      label: "Equestrian",
      value: "Equestrian"
    },
    {
      label: "Ethereum",
      value: "Ethereum"
    },
    {
      label: "Event Management",
      value: "Event Management"
    },
    {
      label: "Event Promotion",
      value: "Event Promotion"
    },
    {
      label: "Events",
      value: "Events"
    },
    {
      label: "Extermination Service",
      value: "Extermination Service"
    },
    {
      label: "Eyewear",
      value: "Eyewear"
    },
    {
      label: "Facebook",
      value: "Facebook"
    },
    {
      label: "Facial Recognition",
      value: "Facial Recognition"
    },
    {
      label: "Facilities Support Services",
      value: "Facilities Support Services"
    },
    {
      label: "Facility Management",
      value: "Facility Management"
    },
    {
      label: "Family",
      value: "Family"
    },
    {
      label: "Fantasy Sports",
      value: "Fantasy Sports"
    },
    {
      label: "Farmers Market",
      value: "Farmers Market"
    },
    {
      label: "Farming",
      value: "Farming"
    },
    {
      label: "Fashion",
      value: "Fashion"
    },
    {
      label: "Fast-Moving Consumer Goods",
      value: "Fast-Moving Consumer Goods"
    },
    {
      label: "Ferry Service",
      value: "Ferry Service"
    },
    {
      label: "Fertility",
      value: "Fertility"
    },
    {
      label: "Field Support",
      value: "Field Support"
    },
    {
      label: "Field-Programmable Gate Array (FPGA)",
      value: "Field-Programmable Gate Array (FPGA)"
    },
    {
      label: "File Sharing",
      value: "File Sharing"
    },
    {
      label: "Film",
      value: "Film"
    },
    {
      label: "Film Distribution",
      value: "Film Distribution"
    },
    {
      label: "Film Production",
      value: "Film Production"
    },
    {
      label: "FinTech",
      value: "FinTech"
    },
    {
      label: "Finance",
      value: "Finance"
    },
    {
      label: "Financial Exchanges",
      value: "Financial Exchanges"
    },
    {
      label: "Financial Services",
      value: "Financial Services"
    },
    {
      label: "First Aid",
      value: "First Aid"
    },
    {
      label: "Fitness",
      value: "Fitness"
    },
    {
      label: "Flash Sale",
      value: "Flash Sale"
    },
    {
      label: "Flash Storage",
      value: "Flash Storage"
    },
    {
      label: "Fleet Management",
      value: "Fleet Management"
    },
    {
      label: "Flowers",
      value: "Flowers"
    },
    {
      label: "Food Delivery",
      value: "Food Delivery"
    },
    {
      label: "Food Processing",
      value: "Food Processing"
    },
    {
      label: "Food Trucks",
      value: "Food Trucks"
    },
    {
      label: "Food and Beverage",
      value: "Food and Beverage"
    },
    {
      label: "Forestry",
      value: "Forestry"
    },
    {
      label: "Fossil Fuels",
      value: "Fossil Fuels"
    },
    {
      label: "Foundries",
      value: "Foundries"
    },
    {
      label: "Franchise",
      value: "Franchise"
    },
    {
      label: "Fraud Detection",
      value: "Fraud Detection"
    },
    {
      label: "Freelance",
      value: "Freelance"
    },
    {
      label: "Freemium",
      value: "Freemium"
    },
    {
      label: "Freight Service",
      value: "Freight Service"
    },
    {
      label: "Fruit",
      value: "Fruit"
    },
    {
      label: "Fuel",
      value: "Fuel"
    },
    {
      label: "Fuel Cell",
      value: "Fuel Cell"
    },
    {
      label: "Funding Platform",
      value: "Funding Platform"
    },
    {
      label: "Funerals",
      value: "Funerals"
    },
    {
      label: "Furniture",
      value: "Furniture"
    },
    {
      label: "GPS",
      value: "GPS"
    },
    {
      label: "GPU",
      value: "GPU"
    },
    {
      label: "Gambling",
      value: "Gambling"
    },
    {
      label: "Gamification",
      value: "Gamification"
    },
    {
      label: "Gaming",
      value: "Gaming"
    },
    {
      label: "Generation Y",
      value: "Generation Y"
    },
    {
      label: "Generation Z",
      value: "Generation Z"
    },
    {
      label: "Genetics",
      value: "Genetics"
    },
    {
      label: "Geospatial",
      value: "Geospatial"
    },
    {
      label: "Gift",
      value: "Gift"
    },
    {
      label: "Gift Card",
      value: "Gift Card"
    },
    {
      label: "Gift Exchange",
      value: "Gift Exchange"
    },
    {
      label: "Gift Registry",
      value: "Gift Registry"
    },
    {
      label: "Golf",
      value: "Golf"
    },
    {
      label: "Google",
      value: "Google"
    },
    {
      label: "Google Glass",
      value: "Google Glass"
    },
    {
      label: "GovTech",
      value: "GovTech"
    },
    {
      label: "Government",
      value: "Government"
    },
    {
      label: "Graphic Design",
      value: "Graphic Design"
    },
    {
      label: "Green Building",
      value: "Green Building"
    },
    {
      label: "Green Consumer Goods",
      value: "Green Consumer Goods"
    },
    {
      label: "GreenTech",
      value: "GreenTech"
    },
    {
      label: "Grocery",
      value: "Grocery"
    },
    {
      label: "Group Buying",
      value: "Group Buying"
    },
    {
      label: "Guides",
      value: "Guides"
    },
    {
      label: "Handmade",
      value: "Handmade"
    },
    {
      label: "Hardware",
      value: "Hardware"
    },
    {
      label: "Health Care",
      value: "Health Care"
    },
    {
      label: "Health Diagnostics",
      value: "Health Diagnostics"
    },
    {
      label: "Health Insurance",
      value: "Health Insurance"
    },
    {
      label: "Hedge Funds",
      value: "Hedge Funds"
    },
    {
      label: "Higher Education",
      value: "Higher Education"
    },
    {
      label: "Hockey",
      value: "Hockey"
    },
    {
      label: "Home Decor",
      value: "Home Decor"
    },
    {
      label: "Home Health Care",
      value: "Home Health Care"
    },
    {
      label: "Home Improvement",
      value: "Home Improvement"
    },
    {
      label: "Home Renovation",
      value: "Home Renovation"
    },
    {
      label: "Home Services",
      value: "Home Services"
    },
    {
      label: "Home and Garden",
      value: "Home and Garden"
    },
    {
      label: "Homeland Security",
      value: "Homeland Security"
    },
    {
      label: "Homeless Shelter",
      value: "Homeless Shelter"
    },
    {
      label: "Horticulture",
      value: "Horticulture"
    },
    {
      label: "Hospital",
      value: "Hospital"
    },
    {
      label: "Hospitality",
      value: "Hospitality"
    },
    {
      label: "Hotel",
      value: "Hotel"
    },
    {
      label: "Housekeeping Service",
      value: "Housekeeping Service"
    },
    {
      label: "Human Computer Interaction",
      value: "Human Computer Interaction"
    },
    {
      label: "Human Resources",
      value: "Human Resources"
    },
    {
      label: "Humanitarian",
      value: "Humanitarian"
    },
    {
      label: "Hunting",
      value: "Hunting"
    },
    {
      label: "Hydroponics",
      value: "Hydroponics"
    },
    {
      label: "ISP",
      value: "ISP"
    },
    {
      label: "IT Infrastructure",
      value: "IT Infrastructure"
    },
    {
      label: "IT Management",
      value: "IT Management"
    },
    {
      label: "IaaS",
      value: "IaaS"
    },
    {
      label: "Identity Management",
      value: "Identity Management"
    },
    {
      label: "Image Recognition",
      value: "Image Recognition"
    },
    {
      label: "Impact Investing",
      value: "Impact Investing"
    },
    {
      label: "In-Flight Entertainment",
      value: "In-Flight Entertainment"
    },
    {
      label: "Incubators",
      value: "Incubators"
    },
    {
      label: "Independent Music",
      value: "Independent Music"
    },
    {
      label: "Indoor Positioning",
      value: "Indoor Positioning"
    },
    {
      label: "Industrial",
      value: "Industrial"
    },
    {
      label: "Industrial Automation",
      value: "Industrial Automation"
    },
    {
      label: "Industrial Design",
      value: "Industrial Design"
    },
    {
      label: "Industrial Engineering",
      value: "Industrial Engineering"
    },
    {
      label: "Industrial Manufacturing",
      value: "Industrial Manufacturing"
    },
    {
      label: "Information Services",
      value: "Information Services"
    },
    {
      label: "Information Technology",
      value: "Information Technology"
    },
    {
      label: "Information and Communications Technology (ICT)",
      value: "Information and Communications Technology (ICT)"
    },
    {
      label: "Infrastructure",
      value: "Infrastructure"
    },
    {
      label: "Innovation Management",
      value: "Innovation Management"
    },
    {
      label: "InsurTech",
      value: "InsurTech"
    },
    {
      label: "Insurance",
      value: "Insurance"
    },
    {
      label: "Intellectual Property",
      value: "Intellectual Property"
    },
    {
      label: "Intelligent Systems",
      value: "Intelligent Systems"
    },
    {
      label: "Interior Design",
      value: "Interior Design"
    },
    {
      label: "Internet",
      value: "Internet"
    },
    {
      label: "Internet Radio",
      value: "Internet Radio"
    },
    {
      label: "Internet of Things",
      value: "Internet of Things"
    },
    {
      label: "Intrusion Detection",
      value: "Intrusion Detection"
    },
    {
      label: "Janitorial Service",
      value: "Janitorial Service"
    },
    {
      label: "Jewelry",
      value: "Jewelry"
    },
    {
      label: "Journalism",
      value: "Journalism"
    },
    {
      label: "Knowledge Management",
      value: "Knowledge Management"
    },
    {
      label: "LGBT",
      value: "LGBT"
    },
    {
      label: "Landscaping",
      value: "Landscaping"
    },
    {
      label: "Language Learning",
      value: "Language Learning"
    },
    {
      label: "Laser",
      value: "Laser"
    },
    {
      label: "Last Mile Transportation",
      value: "Last Mile Transportation"
    },
    {
      label: "Laundry and Dry-cleaning",
      value: "Laundry and Dry-cleaning"
    },
    {
      label: "Law Enforcement",
      value: "Law Enforcement"
    },
    {
      label: "Lead Generation",
      value: "Lead Generation"
    },
    {
      label: "Lead Management",
      value: "Lead Management"
    },
    {
      label: "Leasing",
      value: "Leasing"
    },
    {
      label: "Legal",
      value: "Legal"
    },
    {
      label: "Legal Tech",
      value: "Legal Tech"
    },
    {
      label: "Leisure",
      value: "Leisure"
    },
    {
      label: "Lending",
      value: "Lending"
    },
    {
      label: "Life Insurance",
      value: "Life Insurance"
    },
    {
      label: "Life Science",
      value: "Life Science"
    },
    {
      label: "Lifestyle",
      value: "Lifestyle"
    },
    {
      label: "Lighting",
      value: "Lighting"
    },
    {
      label: "Limousine Service",
      value: "Limousine Service"
    },
    {
      label: "Lingerie",
      value: "Lingerie"
    },
    {
      label: "Linux",
      value: "Linux"
    },
    {
      label: "Livestock",
      value: "Livestock"
    },
    {
      label: "Local",
      value: "Local"
    },
    {
      label: "Local Advertising",
      value: "Local Advertising"
    },
    {
      label: "Local Business",
      value: "Local Business"
    },
    {
      label: "Local Shopping",
      value: "Local Shopping"
    },
    {
      label: "Location Based Services",
      value: "Location Based Services"
    },
    {
      label: "Logistics",
      value: "Logistics"
    },
    {
      label: "Loyalty Programs",
      value: "Loyalty Programs"
    },
    {
      label: "MMO Games",
      value: "MMO Games"
    },
    {
      label: "MOOC",
      value: "MOOC"
    },
    {
      label: "Machine Learning",
      value: "Machine Learning"
    },
    {
      label: "Machinery Manufacturing",
      value: "Machinery Manufacturing"
    },
    {
      label: "Made to Order",
      value: "Made to Order"
    },
    {
      label: "Management Consulting",
      value: "Management Consulting"
    },
    {
      label: "Management Information Systems",
      value: "Management Information Systems"
    },
    {
      label: "Manufacturing",
      value: "Manufacturing"
    },
    {
      label: "Mapping Services",
      value: "Mapping Services"
    },
    {
      label: "Marine Technology",
      value: "Marine Technology"
    },
    {
      label: "Marine Transportation",
      value: "Marine Transportation"
    },
    {
      label: "Market Research",
      value: "Market Research"
    },
    {
      label: "Marketing",
      value: "Marketing"
    },
    {
      label: "Marketing Automation",
      value: "Marketing Automation"
    },
    {
      label: "Marketplace",
      value: "Marketplace"
    },
    {
      label: "Mechanical Design",
      value: "Mechanical Design"
    },
    {
      label: "Mechanical Engineering",
      value: "Mechanical Engineering"
    },
    {
      label: "Media and Entertainment",
      value: "Media and Entertainment"
    },
    {
      label: "Medical",
      value: "Medical"
    },
    {
      label: "Medical Device",
      value: "Medical Device"
    },
    {
      label: "Meeting Software",
      value: "Meeting Software"
    },
    {
      label: "Men's",
      value: "Men's"
    },
    {
      label: "Messaging",
      value: "Messaging"
    },
    {
      label: "Micro Lending",
      value: "Micro Lending"
    },
    {
      label: "Military",
      value: "Military"
    },
    {
      label: "Millennials",
      value: "Millennials"
    },
    {
      label: "Mineral",
      value: "Mineral"
    },
    {
      label: "Mining",
      value: "Mining"
    },
    {
      label: "Mining Technology",
      value: "Mining Technology"
    },
    {
      label: "Mobile",
      value: "Mobile"
    },
    {
      label: "Mobile Advertising",
      value: "Mobile Advertising"
    },
    {
      label: "Mobile Apps",
      value: "Mobile Apps"
    },
    {
      label: "Mobile Devices",
      value: "Mobile Devices"
    },
    {
      label: "Mobile Payments",
      value: "Mobile Payments"
    },
    {
      label: "Motion Capture",
      value: "Motion Capture"
    },
    {
      label: "Multi-level Marketing",
      value: "Multi-level Marketing"
    },
    {
      label: "Museums and Historical Sites",
      value: "Museums and Historical Sites"
    },
    {
      label: "Music",
      value: "Music"
    },
    {
      label: "Music Education",
      value: "Music Education"
    },
    {
      label: "Music Label",
      value: "Music Label"
    },
    {
      label: "Music Streaming",
      value: "Music Streaming"
    },
    {
      label: "Music Venues",
      value: "Music Venues"
    },
    {
      label: "Musical Instruments",
      value: "Musical Instruments"
    },
    {
      label: "NFC",
      value: "NFC"
    },
    {
      label: "Nanotechnology",
      value: "Nanotechnology"
    },
    {
      label: "National Security",
      value: "National Security"
    },
    {
      label: "Natural Language Processing",
      value: "Natural Language Processing"
    },
    {
      label: "Natural Resources",
      value: "Natural Resources"
    },
    {
      label: "Navigation",
      value: "Navigation"
    },
    {
      label: "Network Hardware",
      value: "Network Hardware"
    },
    {
      label: "Network Security",
      value: "Network Security"
    },
    {
      label: "Neuroscience",
      value: "Neuroscience"
    },
    {
      label: "News",
      value: "News"
    },
    {
      label: "Nightclubs",
      value: "Nightclubs"
    },
    {
      label: "Nightlife",
      value: "Nightlife"
    },
    {
      label: "Nintendo",
      value: "Nintendo"
    },
    {
      label: "Non Profit",
      value: "Non Profit"
    },
    {
      label: "Nuclear",
      value: "Nuclear"
    },
    {
      label: "Nursing and Residential Care",
      value: "Nursing and Residential Care"
    },
    {
      label: "Nutraceutical",
      value: "Nutraceutical"
    },
    {
      label: "Nutrition",
      value: "Nutrition"
    },
    {
      label: "Office Administration",
      value: "Office Administration"
    },
    {
      label: "Oil and Gas",
      value: "Oil and Gas"
    },
    {
      label: "Online Auctions",
      value: "Online Auctions"
    },
    {
      label: "Online Forums",
      value: "Online Forums"
    },
    {
      label: "Online Games",
      value: "Online Games"
    },
    {
      label: "Online Portals",
      value: "Online Portals"
    },
    {
      label: "Open Source",
      value: "Open Source"
    },
    {
      label: "Operating Systems",
      value: "Operating Systems"
    },
    {
      label: "Optical Communication",
      value: "Optical Communication"
    },
    {
      label: "Organic",
      value: "Organic"
    },
    {
      label: "Organic Food",
      value: "Organic Food"
    },
    {
      label: "Outdoor Advertising",
      value: "Outdoor Advertising"
    },
    {
      label: "Outdoors",
      value: "Outdoors"
    },
    {
      label: "Outpatient Care",
      value: "Outpatient Care"
    },
    {
      label: "Outsourcing",
      value: "Outsourcing"
    },
    {
      label: "PC Games",
      value: "PC Games"
    },
    {
      label: "PaaS",
      value: "PaaS"
    },
    {
      label: "Packaging Services",
      value: "Packaging Services"
    },
    {
      label: "Paper Manufacturing",
      value: "Paper Manufacturing"
    },
    {
      label: "Parenting",
      value: "Parenting"
    },
    {
      label: "Parking",
      value: "Parking"
    },
    {
      label: "Parks",
      value: "Parks"
    },
    {
      label: "Payments",
      value: "Payments"
    },
    {
      label: "Peer to Peer",
      value: "Peer to Peer"
    },
    {
      label: "Penetration Testing",
      value: "Penetration Testing"
    },
    {
      label: "Performing Arts",
      value: "Performing Arts"
    },
    {
      label: "Personal Branding",
      value: "Personal Branding"
    },
    {
      label: "Personal Development",
      value: "Personal Development"
    },
    {
      label: "Personal Finance",
      value: "Personal Finance"
    },
    {
      label: "Personal Health",
      value: "Personal Health"
    },
    {
      label: "Personalization",
      value: "Personalization"
    },
    {
      label: "Pet",
      value: "Pet"
    },
    {
      label: "Pharmaceutical",
      value: "Pharmaceutical"
    },
    {
      label: "Photo Editing",
      value: "Photo Editing"
    },
    {
      label: "Photo Sharing",
      value: "Photo Sharing"
    },
    {
      label: "Photography",
      value: "Photography"
    },
    {
      label: "Physical Security",
      value: "Physical Security"
    },
    {
      label: "Plastics and Rubber Manufacturing",
      value: "Plastics and Rubber Manufacturing"
    },
    {
      label: "Playstation",
      value: "Playstation"
    },
    {
      label: "Podcast",
      value: "Podcast"
    },
    {
      label: "Point of Sale",
      value: "Point of Sale"
    },
    {
      label: "Politics",
      value: "Politics"
    },
    {
      label: "Pollution Control",
      value: "Pollution Control"
    },
    {
      label: "Ports and Harbors",
      value: "Ports and Harbors"
    },
    {
      label: "Power Grid",
      value: "Power Grid"
    },
    {
      label: "Precious Metals",
      value: "Precious Metals"
    },
    {
      label: "Prediction Markets",
      value: "Prediction Markets"
    },
    {
      label: "Predictive Analytics",
      value: "Predictive Analytics"
    },
    {
      label: "Presentation Software",
      value: "Presentation Software"
    },
    {
      label: "Presentations",
      value: "Presentations"
    },
    {
      label: "Price Comparison",
      value: "Price Comparison"
    },
    {
      label: "Primary Education",
      value: "Primary Education"
    },
    {
      label: "Printing",
      value: "Printing"
    },
    {
      label: "Privacy",
      value: "Privacy"
    },
    {
      label: "Private Cloud",
      value: "Private Cloud"
    },
    {
      label: "Private Social Networking",
      value: "Private Social Networking"
    },
    {
      label: "Procurement",
      value: "Procurement"
    },
    {
      label: "Product Design",
      value: "Product Design"
    },
    {
      label: "Product Management",
      value: "Product Management"
    },
    {
      label: "Product Research",
      value: "Product Research"
    },
    {
      label: "Product Search",
      value: "Product Search"
    },
    {
      label: "Productivity Tools",
      value: "Productivity Tools"
    },
    {
      label: "Professional Networking",
      value: "Professional Networking"
    },
    {
      label: "Professional Services",
      value: "Professional Services"
    },
    {
      label: "Project Management",
      value: "Project Management"
    },
    {
      label: "Property Development",
      value: "Property Development"
    },
    {
      label: "Property Insurance",
      value: "Property Insurance"
    },
    {
      label: "Property Management",
      value: "Property Management"
    },
    {
      label: "Psychology",
      value: "Psychology"
    },
    {
      label: "Public Relations",
      value: "Public Relations"
    },
    {
      label: "Public Safety",
      value: "Public Safety"
    },
    {
      label: "Public Transportation",
      value: "Public Transportation"
    },
    {
      label: "Publishing",
      value: "Publishing"
    },
    {
      label: "Q&A",
      value: "Q&A"
    },
    {
      label: "QR Codes",
      value: "QR Codes"
    },
    {
      label: "Quality Assurance",
      value: "Quality Assurance"
    },
    {
      label: "Quantified Self",
      value: "Quantified Self"
    },
    {
      label: "Quantum Computing",
      value: "Quantum Computing"
    },
    {
      label: "RFID",
      value: "RFID"
    },
    {
      label: "RISC",
      value: "RISC"
    },
    {
      label: "Racing",
      value: "Racing"
    },
    {
      label: "Railroad",
      value: "Railroad"
    },
    {
      label: "Reading Apps",
      value: "Reading Apps"
    },
    {
      label: "Real Estate",
      value: "Real Estate"
    },
    {
      label: "Real Estate Investment",
      value: "Real Estate Investment"
    },
    {
      label: "Real Time",
      value: "Real Time"
    },
    {
      label: "Recipes",
      value: "Recipes"
    },
    {
      label: "Recreation",
      value: "Recreation"
    },
    {
      label: "Recreational Vehicles",
      value: "Recreational Vehicles"
    },
    {
      label: "Recruiting",
      value: "Recruiting"
    },
    {
      label: "Recycling",
      value: "Recycling"
    },
    {
      label: "Rehabilitation",
      value: "Rehabilitation"
    },
    {
      label: "Religion",
      value: "Religion"
    },
    {
      label: "Renewable Energy",
      value: "Renewable Energy"
    },
    {
      label: "Rental",
      value: "Rental"
    },
    {
      label: "Rental Property",
      value: "Rental Property"
    },
    {
      label: "Reputation",
      value: "Reputation"
    },
    {
      label: "Reservations",
      value: "Reservations"
    },
    {
      label: "Residential",
      value: "Residential"
    },
    {
      label: "Resorts",
      value: "Resorts"
    },
    {
      label: "Restaurants",
      value: "Restaurants"
    },
    {
      label: "Retail",
      value: "Retail"
    },
    {
      label: "Retail Technology",
      value: "Retail Technology"
    },
    {
      label: "Retirement",
      value: "Retirement"
    },
    {
      label: "Ride Sharing",
      value: "Ride Sharing"
    },
    {
      label: "Risk Management",
      value: "Risk Management"
    },
    {
      label: "Robotics",
      value: "Robotics"
    },
    {
      label: "Roku",
      value: "Roku"
    },
    {
      label: "Rugby",
      value: "Rugby"
    },
    {
      label: "SEM",
      value: "SEM"
    },
    {
      label: "SEO",
      value: "SEO"
    },
    {
      label: "SMS",
      value: "SMS"
    },
    {
      label: "SNS",
      value: "SNS"
    },
    {
      label: "STEM Education",
      value: "STEM Education"
    },
    {
      label: "SaaS",
      value: "SaaS"
    },
    {
      label: "Sailing",
      value: "Sailing"
    },
    {
      label: "Sales",
      value: "Sales"
    },
    {
      label: "Sales Automation",
      value: "Sales Automation"
    },
    {
      label: "Same Day Delivery",
      value: "Same Day Delivery"
    },
    {
      label: "Satellite Communication",
      value: "Satellite Communication"
    },
    {
      label: "Scheduling",
      value: "Scheduling"
    },
    {
      label: "Seafood",
      value: "Seafood"
    },
    {
      label: "Search Engine",
      value: "Search Engine"
    },
    {
      label: "Secondary Education",
      value: "Secondary Education"
    },
    {
      label: "Security",
      value: "Security"
    },
    {
      label: "Self-Storage",
      value: "Self-Storage"
    },
    {
      label: "Semantic Search",
      value: "Semantic Search"
    },
    {
      label: "Semantic Web",
      value: "Semantic Web"
    },
    {
      label: "Semiconductor",
      value: "Semiconductor"
    },
    {
      label: "Sensor",
      value: "Sensor"
    },
    {
      label: "Serious Games",
      value: "Serious Games"
    },
    {
      label: "Service Industry",
      value: "Service Industry"
    },
    {
      label: "Sex Industry",
      value: "Sex Industry"
    },
    {
      label: "Sex Tech",
      value: "Sex Tech"
    },
    {
      label: "Sharing Economy",
      value: "Sharing Economy"
    },
    {
      label: "Shipping",
      value: "Shipping"
    },
    {
      label: "Shipping Broker",
      value: "Shipping Broker"
    },
    {
      label: "Shoes",
      value: "Shoes"
    },
    {
      label: "Shopping",
      value: "Shopping"
    },
    {
      label: "Shopping Mall",
      value: "Shopping Mall"
    },
    {
      label: "Simulation",
      value: "Simulation"
    },
    {
      label: "Skiing",
      value: "Skiing"
    },
    {
      label: "Skill Assessment",
      value: "Skill Assessment"
    },
    {
      label: "Small and Medium Businesses",
      value: "Small and Medium Businesses"
    },
    {
      label: "Smart Building",
      value: "Smart Building"
    },
    {
      label: "Smart Cities",
      value: "Smart Cities"
    },
    {
      label: "Smart Home",
      value: "Smart Home"
    },
    {
      label: "Snack Food",
      value: "Snack Food"
    },
    {
      label: "Soccer",
      value: "Soccer"
    },
    {
      label: "Social",
      value: "Social"
    },
    {
      label: "Social Assistance",
      value: "Social Assistance"
    },
    {
      label: "Social Bookmarking",
      value: "Social Bookmarking"
    },
    {
      label: "Social CRM",
      value: "Social CRM"
    },
    {
      label: "Social Entrepreneurship",
      value: "Social Entrepreneurship"
    },
    {
      label: "Social Impact",
      value: "Social Impact"
    },
    {
      label: "Social Media",
      value: "Social Media"
    },
    {
      label: "Social Media Advertising",
      value: "Social Media Advertising"
    },
    {
      label: "Social Media Management",
      value: "Social Media Management"
    },
    {
      label: "Social Media Marketing",
      value: "Social Media Marketing"
    },
    {
      label: "Social Network",
      value: "Social Network"
    },
    {
      label: "Social News",
      value: "Social News"
    },
    {
      label: "Social Recruiting",
      value: "Social Recruiting"
    },
    {
      label: "Social Shopping",
      value: "Social Shopping"
    },
    {
      label: "Software",
      value: "Software"
    },
    {
      label: "Software Engineering",
      value: "Software Engineering"
    },
    {
      label: "Solar",
      value: "Solar"
    },
    {
      label: "Space Travel",
      value: "Space Travel"
    },
    {
      label: "Spam Filtering",
      value: "Spam Filtering"
    },
    {
      label: "Speech Recognition",
      value: "Speech Recognition"
    },
    {
      label: "Sponsorship",
      value: "Sponsorship"
    },
    {
      label: "Sporting Goods",
      value: "Sporting Goods"
    },
    {
      label: "Sports",
      value: "Sports"
    },
    {
      label: "Staffing Agency",
      value: "Staffing Agency"
    },
    {
      label: "Stock Exchanges",
      value: "Stock Exchanges"
    },
    {
      label: "Subscription Service",
      value: "Subscription Service"
    },
    {
      label: "Supply Chain Management",
      value: "Supply Chain Management"
    },
    {
      label: "Surfing",
      value: "Surfing"
    },
    {
      label: "Sustainability",
      value: "Sustainability"
    },
    {
      label: "Swimming",
      value: "Swimming"
    },
    {
      label: "TV",
      value: "TV"
    },
    {
      label: "TV Production",
      value: "TV Production"
    },
    {
      label: "Table Tennis",
      value: "Table Tennis"
    },
    {
      label: "Task Management",
      value: "Task Management"
    },
    {
      label: "Taxi Service",
      value: "Taxi Service"
    },
    {
      label: "Tea",
      value: "Tea"
    },
    {
      label: "Technical Support",
      value: "Technical Support"
    },
    {
      label: "Teenagers",
      value: "Teenagers"
    },
    {
      label: "Telecommunications",
      value: "Telecommunications"
    },
    {
      label: "Tennis",
      value: "Tennis"
    },
    {
      label: "Test and Measurement",
      value: "Test and Measurement"
    },
    {
      label: "Text Analytics",
      value: "Text Analytics"
    },
    {
      label: "Textbook",
      value: "Textbook"
    },
    {
      label: "Textiles",
      value: "Textiles"
    },
    {
      label: "Theatre",
      value: "Theatre"
    },
    {
      label: "Therapeutics",
      value: "Therapeutics"
    },
    {
      label: "Ticketing",
      value: "Ticketing"
    },
    {
      label: "Timber",
      value: "Timber"
    },
    {
      label: "Timeshare",
      value: "Timeshare"
    },
    {
      label: "Tobacco",
      value: "Tobacco"
    },
    {
      label: "Tour Operator",
      value: "Tour Operator"
    },
    {
      label: "Tourism",
      value: "Tourism"
    },
    {
      label: "Toys",
      value: "Toys"
    },
    {
      label: "Trade Shows",
      value: "Trade Shows"
    },
    {
      label: "Trading Platform",
      value: "Trading Platform"
    },
    {
      label: "Training",
      value: "Training"
    },
    {
      label: "Transaction Processing",
      value: "Transaction Processing"
    },
    {
      label: "Translation Service",
      value: "Translation Service"
    },
    {
      label: "Transportation",
      value: "Transportation"
    },
    {
      label: "Travel",
      value: "Travel"
    },
    {
      label: "Travel Accommodations",
      value: "Travel Accommodations"
    },
    {
      label: "Travel Agency",
      value: "Travel Agency"
    },
    {
      label: "Tutoring",
      value: "Tutoring"
    },
    {
      label: "Twitter",
      value: "Twitter"
    },
    {
      label: "UX Design",
      value: "UX Design"
    },
    {
      label: "Ultimate Frisbee",
      value: "Ultimate Frisbee"
    },
    {
      label: "Underserved Children",
      value: "Underserved Children"
    },
    {
      label: "Unified Communications",
      value: "Unified Communications"
    },
    {
      label: "Universities",
      value: "Universities"
    },
    {
      label: "Usability Testing",
      value: "Usability Testing"
    },
    {
      label: "Vacation Rental",
      value: "Vacation Rental"
    },
    {
      label: "Vending and Concessions",
      value: "Vending and Concessions"
    },
    {
      label: "Venture Capital",
      value: "Venture Capital"
    },
    {
      label: "Vertical Search",
      value: "Vertical Search"
    },
    {
      label: "Veterinary",
      value: "Veterinary"
    },
    {
      label: "Video",
      value: "Video"
    },
    {
      label: "Video Advertising",
      value: "Video Advertising"
    },
    {
      label: "Video Chat",
      value: "Video Chat"
    },
    {
      label: "Video Conferencing",
      value: "Video Conferencing"
    },
    {
      label: "Video Editing",
      value: "Video Editing"
    },
    {
      label: "Video Games",
      value: "Video Games"
    },
    {
      label: "Video Streaming",
      value: "Video Streaming"
    },
    {
      label: "Video on Demand",
      value: "Video on Demand"
    },
    {
      label: "Virtual Assistant",
      value: "Virtual Assistant"
    },
    {
      label: "Virtual Currency",
      value: "Virtual Currency"
    },
    {
      label: "Virtual Desktop",
      value: "Virtual Desktop"
    },
    {
      label: "Virtual Goods",
      value: "Virtual Goods"
    },
    {
      label: "Virtual Reality",
      value: "Virtual Reality"
    },
    {
      label: "Virtual Workforce",
      value: "Virtual Workforce"
    },
    {
      label: "Virtual World",
      value: "Virtual World"
    },
    {
      label: "Virtualization",
      value: "Virtualization"
    },
    {
      label: "Visual Search",
      value: "Visual Search"
    },
    {
      label: "VoIP",
      value: "VoIP"
    },
    {
      label: "Vocational Education",
      value: "Vocational Education"
    },
    {
      label: "Volley Ball",
      value: "Volley Ball"
    },
    {
      label: "Warehousing",
      value: "Warehousing"
    },
    {
      label: "Waste Management",
      value: "Waste Management"
    },
    {
      label: "Water",
      value: "Water"
    },
    {
      label: "Water Purification",
      value: "Water Purification"
    },
    {
      label: "Water Transportation",
      value: "Water Transportation"
    },
    {
      label: "Wealth Management",
      value: "Wealth Management"
    },
    {
      label: "Wearables",
      value: "Wearables"
    },
    {
      label: "Web Apps",
      value: "Web Apps"
    },
    {
      label: "Web Browsers",
      value: "Web Browsers"
    },
    {
      label: "Web Design",
      value: "Web Design"
    },
    {
      label: "Web Development",
      value: "Web Development"
    },
    {
      label: "Web Hosting",
      value: "Web Hosting"
    },
    {
      label: "WebOS",
      value: "WebOS"
    },
    {
      label: "Wedding",
      value: "Wedding"
    },
    {
      label: "Wellness",
      value: "Wellness"
    },
    {
      label: "Wholesale",
      value: "Wholesale"
    },
    {
      label: "Wind Energy",
      value: "Wind Energy"
    },
    {
      label: "Windows",
      value: "Windows"
    },
    {
      label: "Windows Phone",
      value: "Windows Phone"
    },
    {
      label: "Wine And Spirits",
      value: "Wine And Spirits"
    },
    {
      label: "Winery",
      value: "Winery"
    },
    {
      label: "Wired Telecommunications",
      value: "Wired Telecommunications"
    },
    {
      label: "Wireless",
      value: "Wireless"
    },
    {
      label: "Women's",
      value: "Women's"
    },
    {
      label: "Wood Processing",
      value: "Wood Processing"
    },
    {
      label: "Xbox",
      value: "Xbox"
    },
    {
      label: "Young Adults",
      value: "Young Adults"
    },
    {
      label: "eSports",
      value: "eSports"
    },
    {
      label: "iOS",
      value: "iOS"
    },
    {
      label: "mHealth",
      value: "mHealth"
    },
    {
      label: "macOS",
      value: "macOS"
    }
  ]
