import { gql } from '@apollo/client'

export const GET_CUSTOM_LIST_DETAIL_PAGINATED = gql`
query custom_list_detail_paginated (
  $uuid: String!
  $page: Int
  $pageSize: Int
) {
  customListDetailPaginated(
    uuid: $uuid
    page: $page
    pageSize: $pageSize
  ) {
    page
    pages
    hasNext
    hasPrev
    count
    totalCount
    objects {
      investorUuid {
        uuid
        name
        homepageUrl
        linkedinUrl
        cbUrl
        twitterUrl
        logoUrl
        logoUrlSmall
        investmentCount
        leadInvestmentsMin
        leadInvestmentsMax
        countryCode
        city 
        permalink
        extra
        comments {
          uuid
          addedBy {
            id
            username
            firstName
            lastName
          }
          createdAt
          commentText
        }
        # investor{
        #   uuid
        #   name
        #   email
        # }
        contacts {
          uuid
          name
          email
        }
        nautaCoInvestor
      }

      note
      noteAddedBy {
        id
        username
        firstName
        lastName
      }
      noteCreatedAt

      customlistinvestornoteSet{
        uuid
        addedBy {
          email
          name
        }
        createdAt
        updatedAt
        note
      }

      status {
        id 
        text 
      }


    }
  }
}
`
