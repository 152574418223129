import { useEffect, useState, useRef } from 'react'
import { PrimaryButton, SecondaryButton, ButtonsWrapper } from '../../components/primitives/Button'
import { Input, TextArea } from '../../components/primitives/Input'
import { Header4, RequiredText } from '../../components/primitives/Text'
import { useMutation } from '@apollo/client'
import { useUsers } from '../../queries/useUsers'
import { MultiSelect } from "react-multi-select-component";
import { RequiredMark } from '../../components/primitives/RequiredMark'
import { EDIT_CUSTOM_LIST } from '../../mutations/editCustomList'
import { GET_LIST } from '../../queries/useList'
import { Div } from '../../components/primitives/Div'

export default function EditList({ close, listName, listDescription, collaborators, setUserFeedback }) {

  const [name, setName] = useState(listName)
  const [description, setDescription] = useState(listDescription)
  const [selected, setSelected] = useState(collaborators)
  const [options, setOptions] = useState([])
  const [nameFieldIsEmpty, setNameFieldIsEmpty] = useState(false)

  const urlParams = new URLSearchParams(window.location.search)
  const uuid = urlParams.get('uuid')

  const collaboratorIds = selected.map((s) => {
    return s.value
  })

  const handleCheck = () => {
    if (name.trim().length === 0) {
      setNameFieldIsEmpty(true)
    } else {
      editList()
    }
  }

  const [editList] = useMutation(EDIT_CUSTOM_LIST, {
    variables: {
      uuid,
      name,
      description,
      collaboratorIds: collaboratorIds.toString(),
    },
    refetchQueries: [
      { query: GET_LIST, variables: { uuid } },
    ],
    onError: (error) => {
      setUserFeedback({ success: false, text: `${error}` })
    },
    onCompleted: () => {
      close()
      setUserFeedback({ success: true, text: 'List editted' })
    }
  })

  const { data: usersData /*, loading: usersLoading, error: usersError*/ } = useUsers(false)

  useEffect(() => {
    usersData?.users.map((user) => {
      if (options.length !== usersData?.users.length) {
        options.push({ label: user.email, value: user.id })
        setOptions(options.sort((a, b) => a.label.localeCompare(b.label)))
      }
    })
  }, [usersData])

  useEffect(() => {
    setNameFieldIsEmpty(false)
  }, [name])

  const listNameInputRef = useRef(null)
  const buttonForEditRef = useRef(null)

  return (
    <Div
    // css={{w: '556px'}}
    >
      <Header4 isRequired={nameFieldIsEmpty}>
        Name
        <RequiredMark/>
      </Header4>
      <Input
        value={name}
        onChange={(e) => setName(e.target.value)}
        isRequired={nameFieldIsEmpty}
        ref={listNameInputRef}
        onKeyPress={(event) => {
          const { key } = event;
          if (key === "Enter") {
            buttonForEditRef.current.click();
          }
        }}
      />
      {nameFieldIsEmpty && <RequiredText>Required</RequiredText>}

      <Header4>Description</Header4>
      <TextArea
        rows='5'
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        css={{ w: '100%' }}
      />

      <Header4>Collaborators</Header4>
      <Div data-testid='multiselect'>
        <MultiSelect
          options={options}
          value={selected}
          onChange={setSelected}
          labelledBy='select'
          hasSelectAll={false}
          className='edit-list-multi-select'
        />
      </Div>

      <ButtonsWrapper css={{ mt: '$24' }}>
        <SecondaryButton onClick={() => close()}>Cancel</SecondaryButton>
        <PrimaryButton
          type='button'
          onClick={() => handleCheck()}
          ref={buttonForEditRef}
        >Save</PrimaryButton>
      </ButtonsWrapper>
    </Div>
  )
}
